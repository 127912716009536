import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

export default class Cell extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    focusRange: PropTypes.bool,
    range: PropTypes.string,
    value: PropTypes.string,
    highlight: PropTypes.bool,
    expanded: PropTypes.bool,
  };

  onCellClick = (e) => {
    const { onClick, onCellClick, range} = this.props;
    if (onCellClick) {
      onClick && onClick(e);
      onCellClick(e, range);
    }
  };

  render() {
    const { range, children, highlight, focusRange, expanded } = this.props;

    return (
      <td
        id={`cell-${range}`}
        key={range}
        className={cx('cell', { highlight: highlight, outline: focusRange, expanded })}
        onClick={this.onCellClick}
      >
        {children}
      </td>
    )
  }
}