import * as types from './types';
import Course from '../../../api/course';
import Assignment from '../../../api/assignment';
import Question from '../../../api/question';
import flatten from "lodash/flatten";

export const getAssignments = (page) => ({
  type: types.GET_ASSIGNMENTS,
  payload: {
    promise: new Promise(async (resolve, reject) => {
      try {
        const courses = await Course.list(page);
        const promises = courses.content.map(c => Assignment.list(c.courseId));
        const resp = await Promise.all(promises);
        const assignments = flatten(resp);
        resolve({ data: assignments, page: courses.number, pages: courses.totalPages });
      } catch (e) {
        reject(e);
      }
    })
  }
});

export const getAssignment = (courseId, assignmentId) => ({
  type: types.GET_ASSIGNMENT,
  payload: {
    promise: Assignment.get(courseId, assignmentId)
  }
});

export const updateAssignment = (courseId, assignmentId, data) => ({
  type: types.EDIT_ASSIGNMENT,
  payload: {
    promise: Assignment.update(courseId, assignmentId, data)
  }
});

export const getQuestions = (courseId, assignmentId) => ({
  type: types.LOAD_QUESTIONS,
  payload: {
    promise: Question.list(courseId, assignmentId)
  }
});

export const getQuestion = (courseId, assignmentId, questionId) => ({
  type: types.GET_QUESTION,
  payload: {
    promise: Question.get(courseId, assignmentId, questionId)
  }
});

export const updateQuestion = (courseId, assignmentId, questionId, data) => ({
  type: types.EDIT_QUESTIONS,
  payload: {
    promise: Question.update(courseId, assignmentId, questionId, data)
  }
});

export const getStudentAnswers = (courseId, assignmentId, questionId) => ({
  type: types.GET_STUDENT_ANSWERS,
  payload: {
    promise: Assignment.getStudentAnswers(courseId, assignmentId, questionId).then(
      resp => {
        const { content } = resp;
        let answers = [];
        content.forEach(r => {
          r.answers.forEach(a => {
            a.studentId = r.userId;
          });
          answers.push(r.answers);
        });
        answers = flatten(answers).filter(a => a.questionId == questionId);
        return answers;
      }
    )
  }
});