import createAction from '../actionCreator';

const action = createAction('teacher');

export const LOAD_COURSES = action('LOAD_COURSES');
export const CREATE_COURSE = action('CREATE_COURSE');
export const EDIT_COURSE = action('EDIT_COURSE');
export const DELETE_COURSE = action('DELETE_COURSE');

export const GET_ASSIGNMENTS = action('GET_ASSIGNMENTS');
export const GET_ASSIGNMENT = action('GET_ASSIGNMENT');
export const CREATE_ASSIGNMENT = action('CREATE_ASSIGNMENT');
export const EDIT_ASSIGNMENT = action('EDIT_ASSIGNMENT');
export const DELETE_ASSIGNMENT = action('DELETE_ASSIGNMENT');

export const LOAD_QUESTIONS = action('LOAD_QUESTIONS');
export const GET_QUESTION = action('GET_QUESTION');
export const EDIT_QUESTIONS = action('EDIT_QUESTIONS');
export const CREATE_QUESTIONS = action('CREATE_QUESTIONS');
export const DELETE_QUESTIONS = action('DELETE_QUESTIONS');
export const GET_STUDENT_ANSWERS = action('GET_STUDENT_ANSWERS');

