import React from "react";
import { Table } from 'antd';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

const StudentNotes = ({ downloadFile, submissions }) => {
    const rows = [];
    submissions.forEach((s, idx) => {
        rows.push({
            key: s.userID,
            name: `${s.firstName} ${s.lastName}`,
            answers: s.studentAnswers.filter(i => i.ShortAnswerText)
        })
    });

    const handleDownload = (FileID) => (e) => {
        e.preventDefault();
        downloadFile(FileID);
    };

    const expandedRowRender = (row) => {
        return (
            <>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>Submission Time</th>
                        <th>Note to Instructor</th>
                        <th>Submitted File</th>
                    </tr>
                    </thead>
                    <tbody>
                    {row.answers.map((s, idx) => (
                        <tr key={idx}>
                            <td>{moment(s.SubmissionTime).format('L')}</td>
                            <td>{s.ShortAnswerText}</td>
                            <td><a href="#" onClick={handleDownload(s.FileID)}>download</a></td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </>
        )
    }

    const columns = [
        { title: 'Sr. No.', render: (t, r, i) => i +1},
        { title: 'Student Name', dataIndex: 'name'},
    ];
    
    return (
        <Table
            rowKey="key"
            columns={columns}
            dataSource={sortBy(rows, 'name')}
            expandedRowRender={expandedRowRender}
            pagination={false}
        />
    )
};

export default StudentNotes;