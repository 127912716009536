import React from 'react';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import {GoogleLogin} from "react-google-login";
import swal from 'sweetalert';
import {COURSE_CODES} from "../../../constants";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

@autobind
export default class Register extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    loading: PropTypes.bool
  };
  constructor() {
    super();
    this.state = {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        emailConfirm: '',
        password: '',
        passwordConfirm: '',
        studentId: '',
        cohort: '',
        tnc: false,
        accessCode: '',
      }
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    const { user } = this.state;
    const error = [];
    if (!user.firstName || !user.lastName) {
      error.push('First name & Last name is required');
    }
    if (!user.email) {
      error.push('Email is required');
    }
    if (user.email !== user.emailConfirm) {
      error.push('Email do not match');
    }
    if (!user.password || (user.password !== user.passwordConfirm)) {
      error.push('Please check your password');
    }
    if (!user.studentId) {
      error.push('Student ID is required');
    }
    if(!user.accessCode) {
      error.push('Please enter access code')
    }
    if(!user.cohort) {
      error.push('Please select Course ID')
    }
    if (error.length) {
      return swal('Validation failed', error.join('\r\n'), 'error');
    }
    this.props.onSubmit(this.state.user);
  }

  onChange(e) {
    const { name, value } = e.target;
    const { user } = this.state;
    user[name] = value;
    this.setState({
      user,
    });
  }

  onGoogleResponse = (r) => {
    if (r.accessToken) {
      this.props.onRegisterWithGoogle(r.accessToken);
    }
  };

  render() {
    const { user } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="form-group col-md-6">
            <label>First Name</label>
            <input name="firstName" value={user.firstName} onChange={this.onChange} className="form-control" type="text" required />
          </div>
          <div className="form-group col-md-6">
            <label>Last Name</label>
            <input name="lastName" value={user.lastName} onChange={this.onChange} className="form-control" required />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label>Official University Email Address</label>
            <input name="email" value={user.email} onChange={this.onChange} className="form-control" type="email" required />
          </div>
          <div className="form-group col-md-6">
            <label>Re-type Official University Email Address</label>
            <input name="emailConfirm" value={user.emailConfirm} onChange={this.onChange} className="form-control" type="email" required />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label>Password</label>
            <input name="password" value={user.password} onChange={this.onChange} className="form-control" type="password" required />
          </div>
          <div className="form-group col-md-6">
            <label>Re-type Password</label>
            <input name="passwordConfirm" value={user.passwordConfirm} onChange={this.onChange} className="form-control" type="password" required />
          </div>
        </div>
        <div className="row" id="studentFields">
          <div className="form-group col-md-6">
            <label htmlFor="cohort">Class Section</label>
            <select required className="form-control" name="cohort" value={user.cohort} onChange={this.onChange}>
              <option value="" disabled>Please select</option>
              {COURSE_CODES.map(i => <option value={i} key={i}>{i}</option>)}
            </select>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="">Canvas / Blackboard User name</label>
            <input required name="studentId" value={user.studentId} onChange={this.onChange} className="form-control" type="text"/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label>Digital Access Code</label>
            <input name="accessCode" value={user.accessCode} onChange={this.onChange} className="form-control" type="text" required />
          </div>
        </div>
        <div className="checkbox">
          <label>
            <input required name="tnc" checked={user.tnc} onChange={this.onChange} type="checkbox"/>
            Your assignments are graded by Knowledge Varsity system.
            The information will be kept confidential and will only be shared with course coordinator, assigned instructor or teaching assistant
          </label>
        </div>
        <input disabled={this.props.loading || !user.tnc} className="mt-10 btn btn-lg btn-wide btn-default" type="submit" value="Register" />
      </form>
    )
  }
}
