import axios from 'axios';

const comments = {
  addComment: (data) => {
    return axios.post('comments', data)
  },

  updateComment: (id, data) => {
    return axios.patch(`comments/${id}`, data);
  },

  updateSingleStudentComment: (data) => {
    return axios.patch('comments', data)
  },

  deleteComment(id) {
    return axios.delete(`comments/${id}`);
  },

  processComments(courseID, assignmentID) {
    return axios.post(`/courses/${courseID}/assignments/${assignmentID}/applycomments`)
  }
};

export default comments;
