export const COMMENTS = [
  "Don't Hardcode values, refer cells",
  "Write Smart Formula",
  "Write formula in first cell and drag to other cells.",
  "Correct",
];

export const COURSE_CODES = [
  "ISM110-01",
  "ISM110-02",
  "ISM110-03",
  "ISM110-04",
  "ISM110-05",
  "ISM110-06",
  "ISM110-07",
  "ISM110-08",
  "ISM110-09",
  "ISM110-10",
  "ISM110-11",
  "ISM110-12",
  "ISM110-13",
  "ISM110-14",
  "FIRE623",
  "FIRE635",
  "INFO610",
  "INFO620",
  "SCMA632",
  "FIRE441",
  "VCU-EMBA",
];

export const BOOKS = [
  { id: "bcws", label: "Business Computing with Spreadsheets" },
  { id: "isfdm", label: "Information Systems for Decision Making" },
  { id: "itdm", label: "Information Systems Management" },
  { id: "dddm", label: "Data Driven Decision Making" },
  { id: "vcujt", label: "CU-VCU Joint certificate" }
];

export const UNIVERSITY = ["UNCG", "Christ","VCU","UNT","Knowledge Varsity","WMU","Roanoke College","Hull University , UK","University of KwaZulu-Natal, South Africa","FHWS, Germany", "Akal University","Demo"];

export const CLASS_SECTIONS = [
  {
    book: "bcws",
    university: "UNCG",
    sections: ["SU23-ISM110-01"],
  },
  {
    book: "bcws",
    university: "Knowledge Varsity",
    sections: ["DAPT_EXCEL_Primer","DAPT_Excel_Level1"],
  },
  {
    book: "itdm",
    university: "UNT",
    sections: ["BCIS5150"],
  },
  {
    book: "dddm",
    university: "UNT",
    sections: ["DSCI4700"],
  },
  {
    book: "isfdm",
    university: "UNCG",
    sections: ["FA23-ISM280-01","FA23-ISM280-02","FA23-ISM280-03","FA23-ISM280-04","FA23-ISM280-05"],
  },
  {
    book: "vcujt",
    university: "Christ",
    sections: ["CU-2021"],
  },
  {
    book: "bcws",
    university: "Akal University",
    sections: ["AU-MBA-Fall-2021"],
  },
  {
    book: "bcws",
    university: "VCU",
    sections: ["CU-2021-23","INFO166-01", "INFO166-02", "INFO166-03"],
  },
  {
    book: "bcws",
    university: "WMU",
    sections: ["CIS 1020"],
  },
  {
    book: "bcws",
    university: "Roanoke College",
    sections: ["BUAD 218"],
  },
  {
    book: "bcws",
    university: "Hull university , UK",
    sections: ["INFO101"],
  },
  {
    book: "bcws",
    university: "University of KwaZulu-Natal, South Africa",
    sections: ["INFO101"],
  },
  {
    book: "bcws",
    university: "FHWS, Germany",
    sections: ["INFO101"],
  },
  {
    book: "bcws",
    university: "Demo",
    sections: ["Demo"],
  }
];

export const PRODUCT_LIST_URL = 'https://www.knowledgevarsitypress.com/product-list/'
