import React from "react";
import { autobind } from "core-decorators";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import moment from "moment";
import swal from "sweetalert";
import ToggleSwitch from "@trendmicro/react-toggle-switch";
import TimeZone from "../../../components/Timezone";
import AssignmentModal from "../components/AssignmentFolderModal";

import { teacherActions } from "../../../redux/modules/teacher";

@connect(
  (state) => ({
    assignmentFolder: state.teacher.assignmentFolder,
  }),
  (dispatch) => bindActionCreators(teacherActions, dispatch)
)
@autobind
export default class MyAssignmentsFolder extends React.Component {
  constructor() {
    super();
    this.state = {
      showAssignmentModal: false,
      selectedAssignment: null,
    };
  }

  componentDidMount() {
    this.props.getAssignments(this.props.match.params.id);
  }

  showAddNewAssignment() {
    this.setState({
      showAssignmentModal: true,
      selectedAssignment: null,
    });
  }

  closeModal = () => {
    this.setState({
      showAssignmentModal: false,
      selectedAssignment: null,
    });
  };

  onStatusChange(isChecked, assignment) {
    const data = {
      state: isChecked ? "CREATED" : "UNDER_CREATION",
    };
    this.props.updateAssignment(
      assignment.courseId,
      assignment.assignmentId,
      data
    );
  }

  onCreateAssignment(data) {
    if (this.state.selectedAssignment) {
      this.props.updateAssignment(
        this.state.selectedAssignment.courseId,
        this.state.selectedAssignment.assignmentId,
        data
      );
      this.closeModal();
    } else {
      this.props
        .createAssignment(this.props.match.params.id, data)
        .then(this.closeModal)
        .catch((e) => {
          const message = e.errors.map((e) => e.error).join("\r\n");
          swal("Error", message, "error");
        });
    }
  }

  onDelete(assignment) {
    this.props.deleteAssignment(assignment.courseId, assignment.assignmentId);
  }

  renderDue(assignment) {}

  render() {
    const { showAssignmentModal, selectedAssignment } = this.state;
    const { loading, data } = this.props.assignmentFolder;

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <div className="row">
              <div className="col-sm-7">
                <Link to="/teacher/courses">
                  <i className="glyphicon glyphicon-arrow-left" /> Back to
                  Courses
                </Link>
                <h1 className="panel-caption">My Assignment Folders</h1>
              </div>
              <div className="col-sm-5 assignment-info">
                <button
                  className="btn btn-primary"
                  onClick={this.showAddNewAssignment}
                >
                  <i className="glyphicon glyphicon-plus" />
                  Add New Assignment Folder
                </button>
              </div>
            </div>

            <table className="cd-table table table-striped">
              <thead>
                <tr>
                  <th>Assignment Folder Title</th>
                  <th>Due on</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody id="coursesList">
                {data.map((d) => (
                  <tr key={d.assignmentId}>
                    <td>
                      <i className="glyphicon glyphicon-folder-open" />
                      {"  "}
                      <Link
                        to={`/teacher/courses/${d.courseId}/assignments/${d.assignmentId}`}
                      >
                        {d.title}
                      </Link>
                    </td>
                    <td>
                      <TimeZone timestamp={d.dueDateAndTime} />
                    </td>
                    <td>
                      <ToggleSwitch
                        checked={d.state === "CREATED"}
                        onChange={(event) =>
                          this.onStatusChange(!(d.state === "CREATED"), d)
                        }
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          this.setState({
                            showAssignmentModal: true,
                            selectedAssignment: d,
                          })
                        }
                      >
                        <i className="glyphicon glyphicon-pencil" />
                      </button>{" "}
                      <button
                        className="btn btn-danger"
                        onClick={() => this.onDelete(d)}
                      >
                        <i className="glyphicon glyphicon-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {showAssignmentModal ? (
          <AssignmentModal
            assignment={selectedAssignment}
            onSubmit={this.onCreateAssignment}
            onClose={this.closeModal}
            mode={selectedAssignment && "edit"}
          />
        ) : null}
      </div>
    );
  }
}
