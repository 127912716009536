import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { Spin, Alert } from 'antd';
import { push } from 'react-router-redux';
import { autobind } from 'core-decorators';

import Auth from '../../../api/auth';
import Header from "../../../components/Header";

@connect()
export default class Activation extends React.Component {
  state = {
    loading: true,
    success: false,
    error: null,
  }
  componentDidMount() {
    const { params: { code } } = this.props.match;
    const query = qs.parse(this.props.location.search.substr('1'));
    if(query.code) {
      Auth.activateAccount(query.code).then(
        resp => {
          this.setState({
            loading: false,
            success: true
          });
        },
        err => {
          this.setState({
            error: err.msg,
            loading: false,
          });
        }
      )
    } else {
      this.props.dispatch(push('/'));
    }
  }

  renderContent() {
    const { loading, error, success } = this.state;
    if (loading) {
      return <Spin spinning tip="Activating..." />
    } else if (error) {
      return (
        <Alert message="Error" description={error} type="error" />
      )
    } else if (success) {
      return (
        <div>
          <h2>Success!</h2>
          <p>
            Your account has been activated.
            <br/>
            To turn-in assignments, you should login using the Course link on Canvas.
            <br/>
            You should close this tab now.
          </p>
        </div>
      )
    }
    return (
      <div></div>
    )
  }
  render() {
    return (
      <div>
        <Header />
        <div className="main-content">
          <div className="container">
            <div className="col-md-8 col-md-offset-2">
              <div className="panel clearfix">
                {this.renderContent()}
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
