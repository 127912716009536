import React from 'react';

import Header from '../components/Header';

export default ({children}) => (
  <div>
    <Header />
    <div className="main-content">
      {children}
    </div>
  </div>
)