import React from "react";
import { Switch, Route } from "react-router-dom";
import Helmet from "react-helmet";

import Layout from "./layouts";
import { SignIn, Signup, Activation, ResetPassword, Redeem } from "./modules/signup";
import Student from "./modules/student";
import Teacher from "./modules/teacher";
import Analyst from "./modules/analyst";
import TeachingAssistant from './modules/ta/TAPage';
import CourseLinkHandler from "./modules/student/containers/CourseLinkHandler";
import Settings from "./modules/settings";
import ScrollTop from "./components/ScrollTop";
import NotFound from "./components/NotFound";
import TAResources from './modules/ta';

const Routes = () => (
  <div>
    <ScrollTop />
    <Helmet
      titleTemplate="Knowledge Varsity - %s"
      defaultTitle="Knowledge Varsity"
    />
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/redeem" component={Redeem} />
      <Route exact path="/ui" component={ResetPassword} />
      <Route exact path="/ui/activate" component={Activation} />
      <Layout>
        <Switch>
          <Route exact path="/ui/course/:id" component={CourseLinkHandler} />
          <Route path="/student" component={Student} />
          <Route path="/teacher" component={Teacher} />
          <Route path="/analyst" component={Analyst} />
          <Route path="/ta" component={TAResources} />
          <Route path="/course-instructor" component={TeachingAssistant} />
          <Route path="/settings" component={Settings} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Layout>
    </Switch>
  </div>
);
export default Routes;
