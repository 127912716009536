import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import drop from 'lodash/drop';
import Cell from './Cell';

export default class ChartType extends React.Component {
  static propTypes = {
    blocks: PropTypes.array,
    onGetFormulas: PropTypes.func,
    onCellClick: PropTypes.func,
  };

  state = {
    commentIdx: 0
  };

  onBlockRangeClick = (range) => {
    this.props.onCellClick(range, true);
  };

  render() {
    const { blocks } = this.props;
    const data = drop(blocks, 1);

    const rows = [];
    data.forEach(d => {
      rows.push(
        <tr>
          <td>
            <Cell range={d.blockCellRange} onCellClick={this.onBlockRangeClick}>
              {d.blockCellRange}
            </Cell>
          </td>
          <td>
            <span>{d.blockFormula}</span>
          </td>
        </tr>
      )
    });

    return (
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>A</th>
              <th>B</th>
            </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </table>
      </div>
    );
  }
}