import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Select, message } from "antd";
import { Table } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import uniqBy from 'lodash/uniqBy';
import courseApi from "../../api/course";
import questionApi from "../../api/question";

const Root = styled.div`
  .d-flex {
    display: flex;
    align-items: center;
  }
  .ml-a {
    margin-left: auto;
  }
`;

const TAPage = ({ user }) => {
  const [assignments, setAssignments] = useState([]);
  const [cohort, setCohort] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (cohort) {
      courseApi
        .cohortAssignments(cohort)
        .then((resp) => {
          const data = [];
          resp.courses.forEach(c => {
            data.push(...c.assignments.map(a => ({...a, courseId: c.courseId })));
          })
          setAssignments(data)
        });
    }
  }, [cohort]);

  const downloadModelAnswer = (assignment) => () => {
    setLoading(true);
    const hide = message.loading('Downloading...');
    questionApi
      .list(assignment.courseId, assignment.AssignmentID)
      .then((resp) => {
        const question = resp[0];
        if (question) {
          return courseApi.downloadModelAnswer(
            assignment.courseId,
            assignment.AssignmentID,
            question.questionId
          );
        }
      })
      .catch((e) => message.error(e.message))
      .finally(() => {
        setLoading(false);
        hide();
      });
  };

  const downloadGradedAnswer = (assignment) => () => {
    setLoading(true);
    const hide = message.loading('Downloading...');
    questionApi
      .list(assignment.courseId, assignment.AssignmentID)
      .then((resp) => {
        const question = resp[0];
        if (question) {
          return courseApi.downloadGradedAnswer(
            assignment.courseId,
            assignment.AssignmentID,
            question.questionId,
            cohort
          );
        }
      })
      .catch((e) => message.error(e.message))
      .finally(() => {
        setLoading(false);
        hide();
      });
  };

  const cohorts = user.cohorts;

  return (
    <Root>
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <div className="d-flex">
              <Select
                placeholder="Cohort"
                style={{ width: 200 }}
                value={cohort}
                onChange={setCohort}
              >
                {uniqBy(cohorts, 'cohortId').map((i) => (
                  <Select.Option key={i.cohortId} value={i.cohortId}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
              <div className="ml-a">
                <a className="lead" target="_blank" href="/ta/resources">Instructor Resources</a>
              </div>
            </div>
            <br />
            <div>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Assignment Name</th>
                    <th>Submission Deadline</th>
                    <th>Grading Deadline</th>
                    <th>Download Ideal Solution</th>
                    <th>Download Graded Response</th>
                    <th>Download Gradebook</th>
                  </tr>
                </thead>
                <tbody>
                  {assignments.map((i) => (
                    <tr>
                      <td>{i.AssignmentName}</td>
                      <td>{moment(i.EndDate).format("LL")}</td>
                      <td>{moment(i.EndDate).add(2, "d").format("LL")}</td>
                      <td>
                        <button
                          disabled={loading}
                          onClick={downloadModelAnswer(i)}
                        >
                          Download
                        </button>
                      </td>
                      <td>
                        <button
                          disabled={loading}
                          onClick={downloadGradedAnswer(i)}
                        >
                          Download
                        </button>
                      </td>
                      <td>
                        <a target="_blank" href={i.marksURL}>Download</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default connect(state => state.session)(TAPage);
