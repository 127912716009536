import React from 'react';
import { autobind } from 'core-decorators';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Course from '../../../api/course';

@autobind
export default class Courses extends React.Component {
  state = {
    courses: []
  };

  componentWillMount() {
    Course.list(0).then(
      courses => this.setState({ courses: courses.content })
    )
  }

  render() {
    const { courses } = this.state;
    return (
    <div className="container-fluid">
      <ul>
        {courses.map(c => <li key={c.courseId}><Link to={`/analyst/courses/${c.courseId}/assignments`}>{c.title}</Link></li>)}
      </ul>
    </div>
    )
  }
}