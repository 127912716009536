import React from 'react';
import PropTypes from 'prop-types';
import Cell from './Cell';
import Th from './Th';

const MAX_ROWS = 200;

const COLS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const isCellInRange = (cell, range = '') => {
  const ranges = range.split(':');

  if(ranges.length !== 2 || !cell) return false;

  const x0 = ranges[0][0];
  const y0 = Number(ranges[0].substr(1));
  const x1 = ranges[1][0];
  const y1 = Number(ranges[1].substr(1));
  const zx = cell[0];
  const zy = Number(cell.substr(1));

  let isInrange = false;
  if((zx >= x0 && zx <= x1) && (zy >= y0 && zy <= y1)) {
    isInrange = true;
  }
  return isInrange;
};

export default class Sheet extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object,
    onCellClick: PropTypes.func,
  };

  constructor() {
    super();
    this.state = {
      clickedCell: null,
      commentIdx: 0,
      colStates: {},
    }
  }

  onCellClick = (e, range) => {
    const { data } = this.props;
    const d = data[range] || {};
    const isClickedInRange = isCellInRange(range, d.blockCellRange);

    this.setState({
      clickedCell: range,
      isClickedInRange,
    });

    this.props.onCellClick(range, isClickedInRange);
  };

  onHeaderClick = (id) => {
    const { colStates } = this.state;
    colStates[id] = !colStates[id];
    this.setState({ colStates: {...colStates} });
  };

  renderRows = () => {
    const rows = [];
    for (let i = 1; i <= MAX_ROWS; i++) {
      rows.push(
        <tr key={i}>
          <td key={0}>{i}</td>
            {COLS.map(c => this.renderCell(c, i)
          )}
        </tr>
      )
    }
    return rows;
  };

  renderCell = (x, y) => {
    const key = x + y;
    const { clickedCell, isClickedInRange, colStates } = this.state;
    const { data } = this.props;
    const d = data[key] || {};
    const clickedData = data[clickedCell] || {};

    const isInRange = isCellInRange(key, d.blockCellRange);
    const isSameRangeOfClicked = clickedData.blockCellRange === d.blockCellRange;
    const shouldFocus = isClickedInRange && isInRange && isSameRangeOfClicked;
    const expanded = !!colStates[x];

    return (
      <Cell
        key={key}
        range={key}
        data={d}
        focusRange={shouldFocus}
        highlight={isInRange}
        onCellClick={this.onCellClick}
        expanded={expanded}
      >
        <span className={isInRange ? 'clickable' : undefined}>{d.value}</span>
      </Cell>
    );
  };

  render() {
    return (
      <div className="sheet">
        <table>
          <thead>
          <tr>
            <th key={0} />
            {COLS.map(c => <Th key={c} id={c} onClick={this.onHeaderClick} />)}
          </tr>
          </thead>
          <tbody>
          {this.renderRows()}
          </tbody>
        </table>
      </div>
    )
  }
}
