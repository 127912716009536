import createAction from '../actionCreator';

const action = createAction('analyst');

export const GET_ASSIGNMENTS = action('GET_ASSIGNMENTS');
export const GET_ASSIGNMENT = action('GET_ASSIGNMENT');
export const EDIT_ASSIGNMENT = action('EDIT_ASSIGNMENT');

export const LOAD_QUESTIONS = action('LOAD_QUESTIONS');
export const GET_QUESTION = action('GET_QUESTION');
export const EDIT_QUESTIONS = action('EDIT_QUESTIONS');

export const GET_STUDENT_ANSWERS = action('GET_STUDENT_ANSWERS');