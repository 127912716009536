import React, { useState } from 'react';
import { connect } from 'react-redux';
import { push } from "react-router-redux";
import { Form, Button, Input, message } from 'antd';
import Auth  from '../../../api/auth';
import { sessionActions } from "../../../redux/modules/session";
import get from "lodash/get";

const { Item, create } = Form;

const ChangePassword = ({ form, dispatch }) => {
  const { getFieldDecorator, validateFields, getFieldValue, resetFields } = form;
  const [saving, setSaving] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    return validateFields().then(
      values => {
        setSaving(true);
        return Auth.changePassword(values).then(
          () => {
            resetFields();
            alert('Password changed Successfully, you will be logged out. Login again Using course link');
            dispatch(sessionActions.logout());
            dispatch(push('/'));
          },
          (err) => {
            console.log(err);
            message.error(get(err, 'errors[0].error', err.message));
          }
        ).finally(() => {
          setSaving(false);
        })
      }
    )
  };

  const matchPassword = (rule, value, cb) => {
    const pass = getFieldValue('new_password');
    if (value !== pass) {
      cb(new Error('Password do not match.'))
    } else {
      cb();
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Item label="Current password">
          {getFieldDecorator('current_password', {
            rules:[
              { required: true, message: 'Required' }
            ]
          })(
            <Input type="password" />
          )}
        </Item>
        <Item label="New password">
          {getFieldDecorator('new_password', {
            rules:[
              { required: true,  message: 'Required' }
            ]
          })(
            <Input type="password" />
          )}
        </Item>
        <Item label="Confirm New password">
          {getFieldDecorator('confirm_password', {
            rules:[
              { required: true,  message: 'Required' },
              { validator: matchPassword }
            ]
          })(
            <Input type="password" />
          )}
        </Item>
        <Button loading={saving} htmlType="submit" type={"primary"}>Submit</Button>
      </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({

});

export default connect()(create()(ChangePassword));
