import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Spin, Drawer, AutoComplete } from "antd";
import axios from "axios";
import Course from "../../../api/course";
import Submissions from "../components/Submissions";
import NonSubmissions from "../components/NonSubmissions";
import GradedComments from "../components/GradedComments";
import StudentNotes from "../components/StudentNotes";
import StudentDashboard from "../components/StudentDashboard";
import fileDownload from "../../../utilities/fileDownload";

class CohortDetails extends React.Component {
  state = {
    loading: true,
    course: null,
    students: [],
    submissions: null,
    cohort: null,
    view: "",
    selectedStudent: null,
  };

  componentDidMount() {
    const { courseId, id } = this.props.match.params;
    Course.get(courseId).then((course) => {
      this.setState({
        course,
        cohort: id,
      });
      this.getCohortDetails(courseId, id).then(() =>
        this.setState({ loading: false })
      );
    });
  }

  onCohortChange = (e) => {
    this.setState({ cohort: e.target.value }, () => {
      this.getCohortDetails(this.state.course.courseId, this.state.cohort);
    });
  };

  getCohortDetails = (courseId, id) => {
    return Course.cohortDetails(courseId, id).then((resp) => {
      this.setState({
        loading: false,
        course: {
          ...this.state.course,
          ...resp.courses[0],
        },
        students: resp.students,
        selectedStudent: null,
      });
    });
  };

  getDetails = (assignment, view) => {
    Course.cohortSubmissions(
      this.props.match.params.courseId,
      assignment.AssignmentID,
      this.state.cohort
    ).then((resp) => {
      this.setState({
        submissions: resp,
        view,
      });
    });
  };

  downloadFile = (id) => {
    return axios
      .get(`files/${id}`, {
        responseType: "blob",
      })
      .then((resp) => {
        fileDownload(resp.content, resp.filename);
      });
  };

  render() {
    const {
      loading,
      view,
      submissions,
      students,
      selectedStudent,
    } = this.state;
    const { user, match } = this.props;
    const course = user.courses.find(c => c.courseId === Number(match.params.courseId));

    if (loading) return <Spin spinning />;

    const student = students.find((u) => u.userId === +selectedStudent);

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <Link to="/ta/courses">Back to Courses</Link>
            <div className="row">
              <div className="col-sm-7">
                <h3 className="panel-caption">{course.title}</h3>
              </div>
              <div className="col-sm-3">
                <label htmlFor="">Section:&nbsp;</label>
                <select onChange={this.onCohortChange}>
                  {course.cohorts.map((i) => (
                    <option value={i.cohortId}>{i.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-2">
                <AutoComplete
                  value={selectedStudent}
                  onChange={(val) => this.setState({ selectedStudent: val })}
                  style={{ width: 200 }}
                  dataSource={students.map((i) => ({
                    value: i.userId,
                    text: `${i.firstName} ${i.lastName}`,
                  }))}
                  placeholder="Search Student..."
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </div>
            </div>
            <table className="cd-table table table-striped">
              <thead>
                <tr>
                  <th>Assignments</th>
                  <th>Submissions</th>
                  <th>Non Submissions</th>
                  <th>Grader's comment</th>
                  {/*<th>Student Note</th>*/}
                  <th>Gradebook</th>
                </tr>
              </thead>
              <tbody>
                {this.state.course.assignments.map((a) => (
                  <tr key={a.AssignmentID}>
                    <td>{a.AssignmentName}</td>
                    <td>
                      {a.SubmissionsCount}{" "}
                      {a.SubmissionsCount ? (
                        <a
                          href="#"
                          onClick={() => this.getDetails(a, "submissions")}
                        >
                          view
                        </a>
                      ) : null}
                    </td>
                    <td>
                      {a.NonSubmissionsCount}{" "}
                      {a.NonSubmissionsCount ? (
                        <a
                          href="#"
                          onClick={() => this.getDetails(a, "nonSubmissions")}
                        >
                          view
                        </a>
                      ) : null}
                    </td>
                    <td>
                      {a.GraderCommentCount}{" "}
                      {a.GraderCommentCount ? (
                        <a
                          href="#"
                          onClick={() => this.getDetails(a, "gradedComments")}
                        >
                          view
                        </a>
                      ) : null}
                    </td>
                    {/*<td>*/}
                    {/*{a.StudentNoteCount} (*/}
                    {/*<a href="#" onClick={() => this.getDetails(a, "notes")}>*/}
                    {/*  view*/}
                    {/*</a>*/}
                    {/*)*/}
                    {/*</td>*/}
                    <td>
                      <a href={a.marksURL} target="_blank">
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {submissions && (
          <Drawer
            placement={"right"}
            visible={!!view}
            width={600}
            title={submissions.AssignmentName}
            onClose={() => this.setState({ view: null })}
          >
            {view === "submissions" && (
              <Submissions {...submissions} downloadFile={this.downloadFile} />
            )}
            {view === "nonSubmissions" && (
              <NonSubmissions
                {...submissions}
                downloadFile={this.downloadFile}
              />
            )}
            {view === "gradedComments" && (
              <GradedComments
                {...submissions}
                downloadFile={this.downloadFile}
              />
            )}
            {view === "notes" && (
              <StudentNotes {...submissions} downloadFile={this.downloadFile} />
            )}
          </Drawer>
        )}
        {student && (
          <StudentDashboard
            course={course}
            student={student}
            onClose={() => this.setState({ selectedStudent: null })}
            downloadFile={this.downloadFile}
          />
        )}
      </div>
    );
  }
}

export default connect((s) => s.session)(CohortDetails);
