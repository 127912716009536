import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { NavDropdown, MenuItem } from "react-bootstrap";
import { push } from "react-router-redux";

import { sessionActions } from "../../redux/modules/session";

class Header extends React.Component {
  logout = () => {
    this.props.dispatch(sessionActions.logout());
    this.props.dispatch(push("/"));
  };

  onMenuSelect = (key) => {
    if (key === "logout") this.logout();
    else if (key === 'settings') {
      this.props.dispatch(push("/settings"));
    }
  };

  render() {
    const { isLoggedIn, user } = this.props;
    return (
      <header className="main-header navbar navbar-inverse">
        <a className="main-logo" href="/">
          Knowledge Varsity <span className="text-blue">Learning Portal</span>
        </a>
        <div className="main-header__content">
          <h1 className="main-header__caption">
            Assignment Grading & Feedback Platform
          </h1>
        </div>
        <div className="dropdown main-header__name">
          {isLoggedIn && user && (
            <NavDropdown
              eventKey={3}
              title={user.firstName}
              onSelect={this.onMenuSelect}
            >
              <MenuItem eventKey="settings">
                <i className="glyphicon glyphicon-cog" /> Settings
              </MenuItem>
              <MenuItem eventKey="logout">
                <i
                  className="glyphicon glyphicon-log-out"
                  onClick={this.logout}
                />{" "}
                Logout
              </MenuItem>
            </NavDropdown>
          )}
        </div>
      </header>
    );
  }
}

export default connect(s => s.session)(Header);
