import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { autobind } from 'core-decorators';
import swal from 'sweetalert';

import Header from '../../../components/Header';

import Auth from '../../../api/auth';

@connect()
export default class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      code: '',
      password: '',
      confirmPassword: ''
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    Auth.resetPasswordWithCode(this.state.code, this.state.password)
      .then(
        () => {
          swal('Success!',
            'Password has been reset',
            'success');
          this.props.dispatch(push('/'));
        },
        err => {
          swal('Error!',
            err.msg,
            'error');
        }
      )

  };

  componentDidMount() {
    const query = qs.parse(this.props.location.search.substr('1'));
    if(query.code) {
      this.setState({ code: query.code });
    } else {
      this.props.dispatch(push('/'));
    }
  }

  render() {
    const { password, confirmPassword } = this.state;
    return (
      <div>
        <Header />
        <div className="main-content">
          <div className="container">
            <div className="col-md-4 col-md-offset-4">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>Password</label>
                    <input name="password" value={this.state.password} onChange={this.onChange} className="form-control" type="password" required />
                  </div>
                  <div className="form-group col-md-12">
                    <label>Re-type Password</label>
                    <input name="confirmPassword" value={this.state.confirmPassword} onChange={this.onChange} className="form-control" type="password" required />
                  </div>
                  <div className="col-md-12">
                    <button className="btn btn-primary btn-block" type="submit" disabled={!password || password !== confirmPassword}>Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}