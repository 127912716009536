import React from 'react';
import {autobind} from 'core-decorators';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import {Modal} from 'react-bootstrap';
import {push} from 'react-router-redux';
import { Link } from 'react-router-dom';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
import moment from 'moment';

import Course from '../../../api/course';
import { analystActions } from '../../../redux/modules/analist';
import Timezone from "../../../components/Timezone";

@connect(state => state.analyst, dispatch => bindActionCreators(analystActions, dispatch))
@autobind
export default class AssignmentQuestions extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showCopyModal: false,
      assignment: null
    }
  }

  componentWillMount() {
    const { courseId, assignmentId } = this.props.match.params;
    const { assignmentFolder } = this.props;
    this.props.getQuestions(courseId, assignmentId);

    const assignment = assignmentFolder.data.find(d => d.assignmentId === assignmentId);
    if (assignment) {
      this.setState({ assignment });
    } else {
      this.props.getAssignments(courseId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if(!this.state.assignment) {
      const assignment = nextProps.assignmentFolder.data.find(d => d.assignmentId === nextProps.match.params.assignmentId);
      if (assignment) {
        this.setState({ assignment });
      }
    }
  }


  onDelete(id) {
    swal({
      title: 'Delete the question?',
      text: 'The question',
      buttons: {
        cancel: true,
        confirm: true,
      },
      icon: 'warning'
    })
    .then((value) => {
      if (value) {
        swal('Question deleted successfully.');
      }
    });
  }


  render() {
    const { assignment } = this.state;
    const { assignmentQuestions } = this.props;
    const { courseId, assignmentId } = this.props.match.params;

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <div className="row" style={{marginBottom: 20}}>
              <div className="col-md-6">
                <a onClick={() => this.props.history.goBack()}>
                  <i className="glyphicon glyphicon-arrow-left"/>
                  Back to assignment folders
                </a>
              </div>
              <div className="col-md-6 text-right">
                <label>
                  Ready to grade status
                </label>
                {' '}
                <ToggleSwitch />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <h1 className="panel-caption">
                  <i className="glyphicon glyphicon-file"/> {assignment && assignment.title}
                </h1>
                <p>
                  {assignment && <Timezone timestamp={assignment.dueDateAndTime} />}</p>
              </div>
            </div>
            <table className="cd-table table table-striped">
              <thead>
              <tr>
                <th>Q#</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                {assignmentQuestions.data.map((d, idx) => (
                  <tr key={d.questionId}>
                    <td>Q{idx+1} - {d.questionText}</td>
                    <td>
                      <Link to={`/analyst/courses/${courseId}/assignments/${assignmentId}/questions/${d.questionId}/phrases`} className="btn btn-primary">
                        Manage Phrase Buckets
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}