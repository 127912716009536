import React from 'react';
import clickOutside from 'react-click-outside';
import {Modal, Radio} from 'react-bootstrap';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import {autobind} from 'core-decorators';
import orderBy from 'lodash/orderBy'


export default class TagSuggestions extends React.Component {

  static propTypes = {
    phrases: PropTypes.array,
    onSave: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      popoverBox: {},
      selected: null
    }
  }

  @autobind
  onChange(e) {
    const {value} = e.target;
    this.setState({
      selected: value
    });
  }

  onSave = () => {
    let { selected } = this.state;
    const { phrases, onSave } = this.props;
    if (!selected) return;

    selected = Number(selected);
    let tag = null;
    const phrase = phrases.find(p => {
      tag = p.tag_list.find(t => t.KeyphraseWithStopWordsID === selected);
      return !!tag;
    });
    onSave(phrase, tag)

  };

  render() {
    const {phrases} = this.props;

    const optionsList = [];
    phrases.forEach(phrase => {
      const tags = orderBy(phrase.tag_list, 'ngram', 'desc');
      const tag = tags[0];

      optionsList.push(
        <Radio
          style={styles.item}
          key={tag.KeyphraseWithStopWordsID}
          value={tag.KeyphraseWithStopWordsID}
          checked={this.state.selected == tag.KeyphraseWithStopWordsID}
          onChange={this.onChange}>{tag.KeyphraseTextWithStopWords}</Radio>
      )
    });

    return (
      <Modal show backdrop={true}>
        <Modal.Body>
          <div className="tags-menu__items" style={styles.list}>
            {optionsList}
          </div>
          <div>
            <button className="btn btn-primary btn-sm" onClick={this.onSave}>save</button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

const styles = {
  header: {
    padding: 5,
    backgroundColor: '#efefef',
    fontWeight: 700,
    display: 'flex'
  },
  dropdown: {
    // position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    boxShadow: '0 0 1px rgba(0,0,0,.15)'
  },
  item: {
    padding: '0 15px'
  },
  list: {
    maxHeight: 300,
    overflow: 'auto'
  }
};