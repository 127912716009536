import React, { useEffect } from "react";
import { Table } from "antd";
import { connect} from 'react-redux';
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from 'react-router-dom';

const Root = styled.div``;

const Resources = ({ user, history, match }) => {
  const { courseId } = match.params;

  const columns = [
    { title: "Chapter", dataIndex: "chapter", key: 'chapter' },
    { title: "Learning Material Type", dataIndex: "type" , key: 'type'},
    { title: "Title", dataIndex: "title", key: 'title' },
    { title: "Description", dataIndex: "description", key: 'description' },
    {
      title: "Link",
      dataIndex: "link",
      key: 'link',
      render: (val) => (
        <a href={val} target="_blank">
          View / Download
        </a>
      ),
    },
  ];

  const data = [
    {
      key: 1,
      courseId: '34767',
      chapter: "Ch-01",
      type: "Textbook Chapter",
      title: "Excel Environment",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch1",
      children: [
        {
          key: 11,
          type: "Learning exercise",
          title: "CH01 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE1",
        },
        {
          key: 12,
          type: "Master Business Case",
          title: "Introduction to Master Business Case",
          description: "Pdf Viewer",
          link: "https://buscom.knowledgevarsitypress.com/MBC-Intro",
        },
        {
          key: 13,
          type: "Master Business Case",
          title: "Introduction to Master Business Case",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC0",
        }
      ]
    },

    {
      key: 2,
      courseId: '34767',
      chapter: "Ch-02",
      type: "Textbook Chapter",
      title: "Formatting 1",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch2",
      children: [
        {
          key: 21,
          type: "Learning exercise",
          title: "CH02: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE2",
        },
        {
          key: 22,
          type: "Mini Case",
          title: "Mini Case 1",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC1",
        },
        {
          key: 23,
          type: "Mini Case Model Answer",
          title: "Mini Case 1 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC1-Model",
        },
        {
          key: 24,
          type: "Master Business Case",
          title: "MBC1",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC1",
        },
        {
          key: 25,
          type: "Master Business Case Model Answer",
          title: "MBC1 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC1-Model",
        }
      ]
    },

    {
      key: 3,
      courseId: '34767',
      chapter: "Ch-03",
      type: "Textbook Chapter",
      title: "Formula 1",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch3",
      children: [
        {
          key: 31,
          type: "Learning exercise",
          title: "CH03: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE3",
        },
        {
          key: 32,
          type: "Mini Case",
          title: "Mini Case 2",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC2",
        },
        {
          key: 33,
          type: "Mini Case Model Answer",
          title: "Mini Case 2 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC2-Model",
        }
      ]
    },

    {
      key: 4,
      courseId: '34767',
      chapter: "Ch-04",
      type: "Textbook Chapter",
      title: "Graphs and Charts",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch4",
      children: [
        {
          key: 41,
          type: "Learning exercise",
          title: "CH04: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE4",
        },
        {
          key: 42,
          type: "Mini Case",
          title: "Mini Case 3",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC3",
        },
        {
          key: 43,
          type: "Mini Case Model Answer",
          title: "Mini Case 3  Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC3-Model",
        }
      ]
    },

    {
      key: 5,
      courseId: '34767',
      chapter: "Ch-05",
      type: "Textbook Chapter",
      title: "Functions 1",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch5",
      children: [
        {
          key: 51,
          type: "Learning exercise",
          title: "CH05: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE5",
        },
        {
          key: 52,
          type: "Mini Case",
          title: "Mini Case 4",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC4",
        },
        {
          key: 53,
          type: "Mini Case Model Answer",
          title: "Mini Case 4 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC4-Model",
        },
        {
          key: 54,
          type: "Master Business Case",
          title: "MBC2",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC2",
        },
        {
          key: 55,
          type: "Master Business Case Model Answer",
          title: "MBC2 Model",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC2-Model",
        }
      ]
    },

    {
      key: 6,
      courseId: '34767',
      chapter: "Ch-06",
      type: "Textbook Chapter",
      title: "Data 1",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch6",
      children: [
        {
          key: 61,
          type: "Learning exercise",
          title: "CH06: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE6",
        },
        {
          key: 62,
          type: "Mini Case",
          title: "Mini Case 5",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC5",
        },
        {
          key: 63,
          type: "Mini Case Model Answer",
          title: "Mini Case 5 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC5-Model",
        }
      ]
    },

    {
      key: 7,
      courseId: '34767',
      chapter: "Ch-07",
      type: "Textbook Chapter",
      title: "Formatting 2",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch7",
      children: [
        {
          key: 71,
          type: "Learning exercise",
          title: "CH07: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE7",
        }
      ]
    },

    {
      key: 8,
      courseId: '34767',
      chapter: "Ch-08",
      type: "Textbook Chapter",
      title: "Formula 2",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch8",
      children: [
        {
          key: 81,
          type: "Learning exercise",
          title: "CH08: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE8",
        },
        {
          key: 82,
          type: "Mini Case",
          title: "Mini Case 6",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC6",
        },
        {
          key: 83,
          type: "Mini Case Model Answer",
          title: "Mini Case 6 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC6-Model",
        },
        {
          key: 84,
          type: "Master Business Case",
          title: "MBC3",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC3",
        },
        {
          key: 85,
          type: "Master Business Case Model Answer",
          title: "MBC3 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC3-Model",
        }
      ]
    },

    {
      key: 9,
      courseId: '34767',
      chapter: "Ch-09",
      type: "Textbook Chapter",
      title: "Graph 2",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch9",
      children: [
        {
          key: 91,
          type: "Learning exercise",
          title: "CH09: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE9",
        },
        {
          key: 92,
          type: "Mini Case",
          title: "Mini Case 7",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC7",
        },
        {
          key: 93,
          type: "Mini Case Model Answer",
          title: "Mini Case 7 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC7-Model",
        }
      ]
    },

    {
      key: 10,
      courseId: '34767',
      chapter: "Ch-10",
      type: "Textbook Chapter",
      title: "Functions 2",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch10",
      children: [
        {
          key: 101,
          type: "Learning exercise",
          title: "CH10: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE10",
        },
        {
          key: 102,
          type: "Mini Case",
          title: "Mini Case 8",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC8",
        },
        {
          key: 103,
          type: "Mini Case Model Answer",
          title: "Mini Case 8 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC8-Model",
        },
        {
          key: 104,
          type: "Master Business Case",
          title: "MBC4",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC4",
        },
        {
          key: 105,
          type: "Master Business Case Model Answer",
          title: "MBC4 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC4-Model",
        }
      ]
    },
    {
      key: 11,
      courseId: '34767',
      chapter: "Ch-11",
      type: "Textbook Chapter",
      title: "Functions 3",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch11",
      children: [
        {
          key: 111,
          type: "Learning exercise",
          title: "CH11: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE11",
        },
        {
          key: 112,
          type: "Mini Case",
          title: "Mini Case 9",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC9",
        },
        {
          key: 113,
          type: "Mini Case Model Answer",
          title: "Mini Case 9 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC9-Model",
        }
      ]
    },

    {
      key: 12,
      courseId: '34767',
      chapter: "Ch-12",
      type: "Textbook Chapter",
      title: "Data 2",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch12",
      children: [
        {
          key: 121,
          type: "Learning exercise",
          title: "CH12: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE12",
        },
        {
          key: 122,
          type: "Mini Case",
          title: "Mini Case 10",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC10",
        },
        {
          key: 123,
          type: "Mini Case Model Answer",
          title: "Mini Case 10 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC10-Model",
        },
        {
          key: 124,
          type: "Master Business Case",
          title: "MBC5",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC5",
        },
        {
          key: 125,
          type: "Master Business Case Model Answer",
          title: "MBC5 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC5-Model",
        }
      ]
    },
    {
      key: 13,
      courseId: '34767',
      chapter: "Ch-13",
      type: "Textbook Chapter",
      title: "Data 3",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch13",
      children: [
        {
          key: 131,
          type: "Learning exercise",
          title: "CH13: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE13",
        },
        {
          key: 132,
          type: "Mini Case",
          title: "Mini Case 11",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC11",
        },
        {
          key: 133,
          type: "Mini Case Model Answer",
          title: "Mini Case 11 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC11-Model",
        }
      ]
    },
    {
      key: 14,
      courseId: '34767',
      chapter: "Ch-14",
      type: "Textbook Chapter",
      title: "Data 4",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch14",
      children: [
        {
          key: 141,
          type: "Learning exercise",
          title: "CH14: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE14",
        },
        {
          key: 142,
          type: "Mini Case",
          title: "Mini Case 12",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC12",
        },
        {
          key: 143,
          type: "Mini Case Model Answer",
          title: "Mini Case 12 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC12-Model",
        },
        {
          key: 144,
          type: "Master Business Case",
          title: "MBC6",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC6",
        },
        {
          key: 145,
          type: "Master Business Case Model Answer",
          title: "MBC6 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MBC6-Model",
        }
      ]
    },
    {
      key: 15,
      courseId: '34767',
      chapter: "Ch-15",
      type: "Textbook Chapter",
      title: "Data 5",
      description: "PDF Viewer",
      link: "https://buscom.knowledgevarsitypress.com/Ch15",
      children: [
        {
          key: 151,
          type: "Learning exercise",
          title: "CH15: Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/LE15",
        },
        {
          key: 152,
          type: "Mini Case",
          title: "Mini Case 13",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC13",
        },
        {
          key: 153,
          type: "Mini Case Model Answer",
          title: "Mini Case 13 Model Answer",
          description: "EXCEL Workbook",
          link: "https://buscom.knowledgevarsitypress.com/MC13-Model",
        }
      ]
    },
    {
      key: 16,
      courseId: '34768',
      chapter: "Ch-1",
      type: "Textbook Chapter",
      title: "Information, Systems and Decision Making",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch1",
    },
    {
      key: 17,
      courseId: '34768',
      chapter: "Ch-2",
      type: "Textbook Chapter",
      title: "Foundational Data Processing Concepts",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch2",
    },
    {
      key: 18,
      courseId: '34768',
      chapter: "Ch-3",
      type: "Textbook Chapter",
      title: "Database Management Systems",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch3",
    },
    {
      key: 19,
      courseId: '34768',
      chapter: "Ch-4",
      type: "Textbook Chapter",
      title: "Networking Concepts",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch4",
    },
    {
      key: 20,
      courseId: '34768',
      chapter: "Ch-5",
      type: "Textbook Chapter",
      title: "Information Systems Strategy and Planning",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch5",
    },
    {
      key: 21,
      courseId: '34768',
      chapter: "Ch-6",
      type: "Textbook Chapter",
      title: "Information Systems Innovations",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch6",
      children: [
        {
          key: 211,
          type: "Assignment",
          title: "Assignment 5",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A5",
        },
        {
          key: 212,
          type: "Assignment Model Answer",
          title: "Assignment 5 Model Answer",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A5-model",
        }
      ]
    },
    {
      key: 22,
      courseId: '34768',
      chapter: "Ch-7",
      type: "Textbook Chapter",
      title: "Information Systems Project Management",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch7",
      children: [
        {
          key: 221,
          type: "Assignment",
          title: "Assignment 6",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A6",
        },
        {
          key: 222,
          type: "Assignment Model Answer",
          title: "Assignment 6 Model Answer",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A6-model",
        }
      ]
    },

    {
      key: 23,
      courseId: '34768',
      chapter: "Ch-8",
      type: "Textbook Chapter",
      title: "Information Systems Security and Ethics",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch8",
      children: [
        {
          key: 231,
          type: "Assignment",
          title: "Assignment 7",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A7",
        },
        {
          key: 232,
          type: "Assignment Model Answer",
          title: "Assignment 7 Model Answer",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A7-model",
        }
      ]
    },
    {
          key: 24,
          courseId: '34768',
          chapter: "Ch-9",
          type: "Textbook Chapter",
          title: "Strategy and the Internet",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/Ch9",
          children: [
            {
              key: 241,
              type: "Assignment",
              title: "Assignment 8",
              description: "EXCEL Workbook",
              link: "https://info.knowledgevarsitypress.com/A8",
            },
            {
              key: 242,
              type: "Assignment Model Answer",
              title: "Assignment 8 Model Answer",
              description: "EXCEL Workbook",
              link: "https://info.knowledgevarsitypress.com/A8-model",
            }
          ]
        },
        {
          key: 25,
          courseId: '34768',
          chapter: "Ch-10",
          type: "Textbook Chapter",
          title: "Referencing in Spreadsheets",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/Ch10",
          children: [
            {
              key: 251,
              type: "Learning Exercise Text",
              title: "CH10 Learning Exercise",
              description: "PDF Viewer",
              link: "https://info.knowledgevarsitypress.com/Ch10LE",
            },
            {
              key: 252,
              type: "Learning Exercise",
              title: "CH10 Learning Exercise",
              description: "EXCEL Workbook",
              link: "https://info.knowledgevarsitypress.com/LE10",
            },
            {
              key: 253,
              type: "Assignment",
              title: "Assignment 1",
              description: "EXCEL Workbook",
              link: "https://info.knowledgevarsitypress.com/A1",
            },
            {
              key: 254,
              type: "Assignment Model Answer",
              title: "Assignment 1 Model Answer",
              description: "EXCEL Workbook",
              link: "https://info.knowledgevarsitypress.com/A1-model",
            }
          ]
        },
    {
      key: 26,
      courseId: '34768',
      chapter: "Ch-11",
      type: "Textbook Chapter",
      title: "Functions in Spreadsheets",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch11",
      children: [
        {
          key: 261,
          type: "Learning Exercise Text",
          title: "CH11 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/Ch11LE",
        },
        {
          key: 262,
          type: "Learning exercise",
          title: "CH11 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/LE11",
        }
      ]
    },
    {
      key: 27,
      courseId: '34768',
      chapter: "Ch-12",
      type: "Textbook Chapter",
      title: "Visualization in Spreadsheets",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch12",
      children: [
        {
          key: 271,
          type: "Learning Exercise Text",
          title: "CH12 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/Ch12LE",
        },
        {
          key: 272,
          type: "Learning exercise",
          title: "CH12 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/LE12",
        },
        {
          key: 273,
          type: "Assignment",
          title: "Assignment 2",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A2",
        },
        {
          key: 274,
          type: "Assignment Model Answer",
          title: "Assignment 2 Model Answer",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A2-model",
        }
      ]
    },
    {
      key: 28,
      courseId: '34768',
      chapter: "Ch-13",
      type: "Textbook Chapter",
      title: "Rearranging And Selective Viewing Of The Data",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch13",
      children: [
        {
          key: 281,
          type: "Learning Exercise Text",
          title: "CH13 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/Ch13LE",
        },
        {
          key: 282,
          type: "Learning exercise",
          title: "CH12 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/LE13",
        }
      ]
    },
    {
      key: 29,
      courseId: '34768',
      chapter: "Ch-14",
      type: "Textbook Chapter",
      title: "Time Value of Money",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch14",
      children: [
        {
          key: 291,
          type: "Learning Exercise Text",
          title: "CH14 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/Ch14LE",
        },
        {
          key: 292,
          type: "Learning exercise",
          title: "CH14 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/LE14",
        },
        {
          key: 293,
          type: "Assignment",
          title: "Assignment 3",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A3",
        },
        {
          key: 294,
          type: "Assignment Model Answer",
          title: "Assignment 3 Model Answer",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A3-model",
        }
      ]
    },
    {
      key: 30,
      courseId: '34768',
      chapter: "Ch-15",
      type: "Textbook Chapter",
      title: "Application of Databases in Spreadsheets",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch15",
      children: [
        {
          key: 301,
          type: "Learning Exercise Text",
          title: "CH15 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/Ch15LE",
        },
        {
          key: 302,
          type: "Learning exercise",
          title: "CH15 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/LE15",
        },
        {
          key: 303,
          type: "Assignment",
          title: "Assignment 4",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A4",
        },
        {
          key: 304,
          type: "Assignment Model Answer",
          title: "Assignment 4 Model Answer",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/A4-model",
        }
      ]
    },
    {
      key: 31,
      courseId: '34768',
      chapter: "Ch-16",
      type: "Textbook Chapter",
      title: "Introduction to Microsoft Access",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/Ch16",
      children: [
        {
          key: 311,
          type: "Learning Exercise Text",
          title: "CH16 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/Ch16LE",
        },
        {
          key: 312,
          type: "Access File",
          title: "CH16 Access File",
          description: "Access File",
          link: "https://info.knowledgevarsitypress.com/LE16",
        }
      ]
    },
    {
      key: 32,
      courseId: '34775',
      chapter: "Ch-1",
      type: "Textbook Chapter",
      title: "Referencing in Spreadsheets",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/DDDMCh1",
      children: [
        {
          key: 321,
          type: "Learning Exercise Text",
          title: "CH1 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/DDDMCh1LE",
        },
        {
          key: 322,
          type: "Learning Exercise",
          title: "CH1 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDMLE1",
        }
    ]
    },
{
  key: 33,
  courseId: '34775',
  chapter: "Ch-2",
  type: "Textbook Chapter",
  title: "Functions in Spreadsheets",
  description: "PDF Viewer",
  link: "https://info.knowledgevarsitypress.com/DDDMCh2",
  children: [
    {
      key: 331,
      type: "Learning Exercise Text",
      title: "CH2 Learning Exercise",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/DDDMCh2LE",
    },
    {
      key: 332,
      type: "Learning exercise",
      title: "CH2 Learning Exercise",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDMLE2",
    },
    {
      key: 333,
      type: "Assignment",
      title: "Assignment 1",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDM-A1",
    },
    {
      key: 334,
      type: "Assignment Model Answer",
      title: "Assignment 1 Model Answer",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDM-A1M",
    }

  ]
},
{
  key: 34,
  courseId: '34775',
  chapter: "Ch-3",
  type: "Textbook Chapter",
  title: "Visualization in Spreadsheets",
  description: "PDF Viewer",
  link: "https://info.knowledgevarsitypress.com/Ch3",
  children: [
    {
      key: 341,
      type: "Learning Exercise Text",
      title: "CH3 Learning Exercise",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/DDDMCh3LE",
    },
    {
      key: 342,
      type: "Learning exercise",
      title: "CH3 Learning Exercise",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDMLE3",
    },
    {
      key: 343,
      type: "Assignment",
      title: "Assignment 2",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDM-A2",
    },
    {
      key: 344,
      type: "Assignment Model Answer",
      title: "Assignment 2 Model Answer",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDM-A2M",
    }
  ]
},
{
  key: 35,
  courseId: '34775',
  chapter: "Ch-4",
  type: "Textbook Chapter",
  title: "Rearranging And Selective Viewing Of The Data",
  description: "PDF Viewer",
  link: "https://info.knowledgevarsitypress.com/DDDMCh4",
  children: [
    {
      key: 351,
      type: "Learning Exercise Text",
      title: "CH4 Learning Exercise",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/DDDMCh4LE",
    },
    {
      key: 352,
      type: "Learning exercise",
      title: "CH4 Learning Exercise",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDMLE4",
    },
    {
      key: 353,
      type: "Assignment",
      title: "Assignment 3",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDM-A3",
    },
    {
      key: 354,
      type: "Assignment Model Answer",
      title: "Assignment 3 Model Answer",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDM-A3M",
    }

  ]
},
{
  key: 36,
  courseId: '34775',
  chapter: "Ch-5",
  type: "Textbook Chapter",
  title: "Computing Frequencies",
  description: "PDF Viewer",
  link: "https://info.knowledgevarsitypress.com/DDDMCh5",
  children: [
    {
      key: 361,
      type: "Learning Exercise Text",
      title: "CH5 Learning Exercise",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/DDDMCh5LE",
    },
    {
      key: 362,
      type: "Learning exercise",
      title: "CH5 Learning Exercise",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/LE15",
    },
    {
      key: 363,
      type: "Assignment",
      title: "Assignment 4",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDM-A4",
    },
    {
      key: 364,
      type: "Assignment Model Answer",
      title: "Assignment 4 Model Answer",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDM-A4M",
    }
  ]
},
{
  key: 37,
  courseId: '34775',
  chapter: "Ch-6",
  type: "Textbook Chapter",
  title: "Pivot Tables and Pivot Charts",
  description: "PDF Viewer",
  link: "https://info.knowledgevarsitypress.com/DDDMCh6",
  children: [
    {
      key: 371,
      type: "Learning Exercise Text",
      title: "CH6 Learning Exercise",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/DDDMCh6LE",
    },
    {
      key: 372,
      type: "Learning exercise",
      title: "CH6 Learning Exercise",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDMLE6",
    },
    {
      key: 373,
      type: "Assignment",
      title: "Assignment 5",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDM-A5",
    },
    {
      key: 374,
      type: "Assignment Model Answer",
      title: "Assignment 5 Model Answer",
      description: "EXCEL Workbook",
      link: "https://info.knowledgevarsitypress.com/DDDM-A5M",
    }

  ]
},
    {
      key: 38,
      courseId: '34775',
      chapter: "Ch-7",
      type: "Textbook Chapter",
      title: "Probability Distributions and their parameters",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/DDDMCh7",
      children: [
        {
          key: 381,
          type: "Learning Exercise Text",
          title: "CH7 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/DDDMCh7LE",
        },
        {
          key: 382,
          type: "Learning exercise",
          title: "CH7 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDMLE7",
        },
        {
          key: 383,
          type: "Assignment",
          title: "Assignment 6",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDM-A6",
        },
        {
          key: 384,
          type: "Assignment Model Answer",
          title: "Assignment 6 Model Answer",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDM-A6M",
        }
      ]
    },
    {
      key: 39,
      courseId: '34775',
      chapter: "Ch-8",
      type: "Textbook Chapter",
      title: "Probability Distributions and their parameters",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/DDDMCh8",
      children: [
        {
          key: 391,
          type: "Learning Exercise Text",
          title: "CH8 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/DDDMCh8LE",
        },
        {
          key: 392,
          type: "Learning exercise",
          title: "CH8 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDMLE8",
        },
        {
          key: 393,
          type: "Assignment",
          title: "Assignment 7",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDM-A7",
        },
        {
          key: 394,
          type: "Assignment Model Answer",
          title: "Assignment 7 Model Answer",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDM-A7M",
        }
      ]
    },
    {
      key: 40,
      courseId: '34775',
      chapter: "Ch-9",
      type: "Textbook Chapter",
      title: "Probability Distributions and their parameters",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/DDDMCh9",
      children: [
        {
          key: 401,
          type: "Learning Exercise Text",
          title: "CH9 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/DDDMCh9LE",
        },
        {
          key: 402,
          type: "Learning exercise",
          title: "CH9 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDMLE9",
        },
        {
          key: 403,
          type: "Assignment",
          title: "Assignment 8",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDM-A8",
        },
        {
          key: 404,
          type: "Assignment Model Answer",
          title: "Assignment 8 Model Answer",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDM-A8M",
        }
      ]
    },
    {
      key: 41,
      courseId: '34775',
      chapter: "Ch-10",
      type: "Textbook Chapter",
      title: "Probability Distributions and their parameters",
      description: "PDF Viewer",
      link: "https://info.knowledgevarsitypress.com/DDDMCh10",
      children: [
        {
          key: 411,
          type: "Learning Exercise Text",
          title: "CH10 Learning Exercise",
          description: "PDF Viewer",
          link: "https://info.knowledgevarsitypress.com/DDDMCh10LE",
        },
        {
          key: 412,
          type: "Learning exercise",
          title: "CH10 Learning Exercise",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDMLE10",
        },
        {
          key: 413,
          type: "Assignment",
          title: "Assignment 9",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDM-A9",
        },
        {
          key: 414,
          type: "Assignment Model Answer",
          title: "Assignment 9 Model Answer",
          description: "EXCEL Workbook",
          link: "https://info.knowledgevarsitypress.com/DDDM-A9M",
        }
      ]
    },
  ];

  if (!user) {
    history.push('/');
    return null;
  }
  if (!user.roles.includes('ta')) return 'You do not have access to this resources';

  const rows = courseId ? data.filter(i => i.courseId === courseId) : data;

  return (
    <Root>
      <Helmet title="Instructor Resources" />
      <div className="container-fluid">
        <div className="col-md-12">
            <div className="row">
              <div className="col-sm-7">
                <h3 className="panel-caption">
                  <font color ="white"> Instructor Resources </font>
                </h3>
              </div>
          </div>

          <div className="panel clearfix">
            <Link to="/ta/courses">Back to Courses</Link>
            <Table
              columns={columns}
              dataSource={rows}
              pagination={{
                defaultPageSize: 20
              }}
            />
          </div>
        </div>
      </div>
    </Root>
  );
};

export default connect(s => s.session)(Resources);
