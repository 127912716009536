import * as types from './types';
import { resolve } from '../../middleware/simple-promise';

const initialState = {
  isLoggedIn: false,
  user: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.STORE_SESSION:
    case resolve(types.LOGIN):
      return { isLoggedIn: true, ...payload };
    case resolve(types.GET_CURRENT_USER):
      return {...state, user: payload};
    case types.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
