import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import moment from 'moment-timezone';

const Option = Select.Option;
const FORMAT = 'MM/DD/YY | hh:mm a';

const tzName = moment.tz.guess();
const options = [
  { label: 'East Coast', value: 'America/New_York' },
  { label: 'Mountain time', value: 'America/Denver' },
  { label: 'West coast', value: 'America/Los_Angeles' },
  { label: 'India', value: 'Asia/Calcutta' },
  { label: 'GMT', value: 'GMT' },
  { label: 'UTC', value: 'Etc/UTC' },
];

const localExist = options.find(o => o.value === tzName);

if (!localExist) {
  options.push({ label: tzName, value: tzName });
}


export default class TimeZone extends React.Component {
  static propTypes = {
    timestamp: PropTypes.number,
    onChange: PropTypes.func,
    hideDate: false
  };

  constructor(props) {
    super(props);
    const localOption = options.find(o => o.value === tzName);
    this.state = {
      value: localOption.value,
    };
  }
  onChange = (value) => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  getTIme() {
    return moment.tz(this.props.timestamp, this.state.value).format(FORMAT);
  }

  render() {
    const select = (
      <Select style={{ width: 150 }} onChange={this.onChange} value={this.state.value}>
        { options.map(o => <Option value={o.value}>{o.label}</Option>)}
      </Select>
    );

    if (this.props.hideDate) {
      return select
    }

    return (
      <div>
        <span>{this.getTIme()}</span>&nbsp;
        {select}
      </div>
    );
  }
}

