import { combineReducers } from 'redux';
import * as types from './types';
import { resolve, reject } from '../../middleware/simple-promise';



const assignmentFolderReducerInitialState = {
  data: [],
  loading: false,
  error: null
};

const assignmentFolderReducer = (state = assignmentFolderReducerInitialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case types.GET_ASSIGNMENTS:
      return {
        ...state,
        data: [],
        loading: true,
        error: null
      };
    case reject(types.GET_ASSIGNMENTS):
      return {
        ...state,
        loading: false,
        error: payload
      };
    case resolve(types.GET_ASSIGNMENTS):
      return {
        ...state,
        loading: false,
        ...payload
      };
    case resolve(types.EDIT_ASSIGNMENT):
      return {
        ...state,
        data: state.data.map(d => d.assignmentId === payload.assignmentId ? payload : d)
      };
    case resolve(types.CREATE_ASSIGNMENT):
      return {
        ...state,
        data: [payload, ...state.data]
      };
    case resolve(types.DELETE_ASSIGNMENT):
      return {
        ...state,
        data: state.data.filter(d => d.assignmentId !== meta.assignmentId)
      };
    default:
      return state;
  }

};


const assignmentQuestionsInitialState = {
  data: [],
  loading: false,
  error: null
};

const assignmentQuestionsReducer = (state = assignmentQuestionsInitialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case types.LOAD_QUESTIONS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case reject(types.LOAD_QUESTIONS):
      return {
        ...state,
        loading: false,
        error: payload
      };
    case resolve(types.LOAD_QUESTIONS):
      return {
        ...state,
        loading: false,
        data: payload
      };
    case resolve(types.EDIT_QUESTIONS):
      return {
        ...state,
        data: state.data.map(d => d.questionId === payload.questionId ? payload : d)
      };
    case resolve(types.CREATE_QUESTIONS):
      return {
        ...state,
        data: [...state.data, payload]
      };
    case resolve(types.DELETE_QUESTIONS):
      return {
        ...state,
        data: state.data.filter(d => d.questionId !== meta.questionId)
      };

    default:
      return state;
  }

};

const studentAnswersState = {
  question: {},
  assignment: {},
  answers: []
};

const studentAnswersReducer = (state = studentAnswersState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case resolve(type.GET_STUDENT_ANSWERS):
      return {
        ...state,
        answers: payload
      };
    case resolve(types.GET_ASSIGNMENT):
      return {
        ...state,
        assignment: payload
      };
    case resolve(types.GET_QUESTION):
      return {
        ...state,
        question: payload
      };
    default:
      return state;
  }
};

export default combineReducers({
  assignmentFolder: assignmentFolderReducer,
  assignmentQuestions: assignmentQuestionsReducer,
  studentAnswers: studentAnswersReducer
});