/* eslint-disable */

import React from "react";
import ReactDOM from "react-dom";
import "url-search-params-polyfill";
import "antd/dist/antd.css";
import Client from "./client";
import { hot } from "react-hot-loader";

const HotClient = hot(module)(Client);
let App = <HotClient />;

if (process.env.NODE_ENV === "production" && window.bugsnagClient) {
  const ErrorBoundary = bugsnagClient.use(bugsnag__react(React));
  App = (
    <ErrorBoundary>
      <HotClient />
    </ErrorBoundary>
  );
}

ReactDOM.render(App, document.getElementById("root"));
