import axios from 'axios';

const Phrase = {

  getAllPhrases(courseId, assignmentId, questionId) {
    return axios.get(`courses/${courseId}/assignments/${assignmentId}/questions/${questionId}/allphrases`);
  },

  getPhrases(courseId, assignmentId, questionId) {
    return axios.get(`courses/${courseId}/assignments/${assignmentId}/questions/${questionId}/assign-phrase`);
  },

  createMasterPhrase(courseId, assignmentId, questionId, data) {
    return axios.get(`courses/${courseId}/assignments/${assignmentId}/questions/${questionId}/assign-phrase`, data);
  },

  getSimilarTags(courseId, assignmentId, questionId, KeyPhraseID) {
    return axios.get(`courses/${courseId}/assignments/${assignmentId}/questions/${questionId}/similartag`, {
      params: {
        KeyPhraseID
      }
    });
  },

  createTag(courseId, assignmentId, questionId, answerId, text) {
    return axios.post(`courses/${courseId}/assignments/${assignmentId}/questions/${questionId}/createtags`, {
      "text": text.toLowerCase(),
      "answerId": answerId,
      "modeValue": 1
    });
  },

  deleteTag(courseID, assignmentID, questionID, KeyPhraseID) {
    return axios.delete(`courses/${courseID}/assignments/${assignmentID}/questions/${questionID}/taglist?KeyPhraseID=${KeyPhraseID}`)
  },

  filterTagList(courseId, assignmentId, questionId, query) {
    return axios.get(`courses/${courseId}/assignments/${assignmentId}/questions/${questionId}/taglist`, {
      params: { keyTag: query }
    });
  },

  updateTagList(courseId, assignmentId, questionId, data) {
    return axios.patch(`courses/${courseId}/assignments/${assignmentId}/questions/${questionId}/taglist`, data);
  },

  createBucket(data) {
    return axios.post('buckets', data);
  },

  addPhraseToBucket(bucketID, KeyphraseID){
    return axios.post(`buckets/${bucketID}/phrase/${KeyphraseID}`)
  },

};

export default Phrase;