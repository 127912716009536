import { combineReducers } from 'redux';
import * as types from './types';
import { resolve, reject } from '../../middleware/simple-promise';


const enrollmentState = {
  enrolled: false,
  enrollmentId: null,
  loading: false,
  error: null
};

const enrollmentReducer = (state = enrollmentState, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case types.SET_ENROLLMENT:
      return {
        ...state,
        enrollmentId: payload,
        enrolled: false
      };
    case types.ENROLL:
      return {
        ...state,
        loading: true,
        enrolled: false,
        error: null
      };
    case resolve(types.ENROLL):
      return {
        ...state,
        loading: false,
        enrolled: true,
        courseId: payload.linkCodeCourseId
      };
    case reject(types.ENROLL):
      return {
        ...state,
        loading: false,
        error: payload
      };
    default:
      return state;
  }
};


const assignmentInitialState = {
  data: [],
  assignments: [],
  loading: false,
  error: null
};


const assignmentQuestionsReducer = (state = assignmentInitialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case types.LOAD_QUESTIONS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case reject(types.LOAD_QUESTIONS):
      return {
        ...state,
        loading: false,
        error: payload
      };
    case resolve(types.LOAD_QUESTIONS):
      return {
        ...state,
        loading: false,
        data: payload
      };
    case resolve(types.GET_ASSIGNMENTS):
      return {
        ...state,
        assignments: payload
      };
    default:
      return state;
  }

};


export default combineReducers({
  enrollment: enrollmentReducer,
  assignmentQuestions: assignmentQuestionsReducer
});