import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default class CopyModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    link: PropTypes.string,
    type: PropTypes.string,
  };

  render() {
    const { onClose, link, type } = this.props;
    const typeName = type === 'gradeview' ? 'Grade' : 'Assignment';
    return (
      <Modal show onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Copy {typeName} View Link</Modal.Title>
        </Modal.Header>
        <Modal.Body className="clearfix">
          <div className="text-center">
            <h4>
              Distribute {typeName} view link by copying the link below and then
              email students.
            </h4>
            <input className="form-control" type="text" readOnly value={link} />
            <CopyToClipboard text={link}>
              <button className="btn btn-primary" style={{ margin: 20 }}>
                Copy Link
              </button>
            </CopyToClipboard>
            <p className="alert alert-info">
              By clicking on Copy, the {typeName} View link will get copied to
              the clipboard
            </p>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
