import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import UpgradeModal from "../components/UpgradeModal";

class Courses extends React.Component {
  state = {
    upgrade: false,
  };
  onUpgrade = async (courseId) => {
    this.setState({ upgrade: courseId });
  };

  render() {
    const { courses } = this.props.user;

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <h1 className="panel-caption">My Courses</h1>
            <div>
              <table className="cd-table table table-striped">
                <thead>
                  <tr>
                    <th>Course Title</th>
                    {/* <td>Section</td> */}
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {courses.map((c) => (
                    <tr key={c.courseId}>
                      <td>
                      {/*  <Link to={`/student/courses/${c.courseId}/assignments`}>*/}
                          {c.title}
                      {/*  </Link>*/}
                      </td>
                      {/*   <td>{c.cohorts.map((i) => i.name).join(", ")}</td>*/}
                      <td>{moment.utc(c.startDate).format("LLL")}</td>
                      <td>{moment.utc(c.endDate).format("LLL")}</td>
                      <td>
                        {c.accessCodeType === 'TRIAL' && (
                            <button
                                className="btn btn-default"
                                onClick={() => this.onUpgrade(c.courseId)}
                            >
                              Upgrade
                            </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.state.upgrade && (
          <UpgradeModal
            courseId={this.state.upgrade}
            onClose={() => this.setState({ upgrade: false })}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => state.session)(Courses);
