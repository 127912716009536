import React from "react";
import { autobind } from "core-decorators";
import swal from "sweetalert";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import Header from "../../../components/Header";
import Loginbar from "../components/LoginBar";

import auth from "../../../api/auth";
import { sessionActions } from "../../../redux/modules/session";
import { COURSE_CODES } from "../../../constants";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

@connect()
@autobind
export default class Signup extends React.Component {
  constructor() {
    super();
    this.state = {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        emailConfirm: "",
        password: "",
        passwordConfirm: "",
        role: "student",
        collegeId: "",
        studentId: "",
        cohort: "",
        tnc: false,
        accessCode: "",
      },
      loading: false,
      success: false,
      error: null,
    };
  }

  onChange(e) {
    const { name, value, checked } = e.target;
    const { user } = this.state;
    if (name === "tnc") {
      user[name] = checked;
    } else {
      user[name] = value;
    }
    this.setState({ user });
  }

  onStudentIdChange = (e) => {
    const { name, value } = e.target;
    this.onChange({
      target: {
        name,
        value: value.replace(/\s/, '')
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { emailConfirm, passwordConfirm, ...rest } = this.state.user;

    let error = "";
    if (emailConfirm !== rest.email) error = "Email do not match";
    else if (passwordConfirm !== rest.password) error = "Password do not match";
    else if (!rest.role) error = "Please select role";
    else if (!rest.cohort) error = "Please select Course ID";
    else if (!rest.accessCode) error = "Please enter access code";
    else if (!rest.studentId) error = "Please enter username";
    else if (rest.studentId.length > 15) error = "The length of Canvas username should be less than or equal to 15. If your canvas username length is more than 15, then please contact support@knowledgevarsity.com .";
    else if (!emailConfirm.endsWith('.edu')) error = "Please register using your University (.edu) email address ";

    const emailUsername = emailConfirm.split('@')[0];

    if (emailUsername !== rest.studentId) {
      if (!confirm(`You have entered Canvas User name as ${rest.studentId}. Are you sure, your Canvas User name is not ${emailUsername}?`)){
        return;
      }
    }


    if (error) {
      swal("Validation error", error, "error");
    } else {
      if (!rest.lastName) rest.lastName = " ";
      rest.roles = [rest.role];

      this.setState({ error: "", loading: true });
      auth.register(rest).then(
        () => {
          swal(
            "Success!",
            "Signup is successful and a verification link is emailed from support@knowledgevarsity.com to your registered email id. If you do not receive the confirmation message within a few minutes then please check spam folder. You should close this tab now.",
            "success"
          );
          this.setState({
            loading: false,
            success: true,
            user: {},
          });
        },
        (err) => {
          const errors = err.errors ? err.errors.map((e) => e.error).join("\r\n") : (err.mesage || err.msg) || 'Something went wrong.';
          swal("Oops", errors, "error");
          this.setState({
            loading: false,
          });
        }
      );
    }
  }

  onLoginSuccess(resp) {
    const { dispatch } = this.props;
    const role = resp.user.roles[0];
    if (role === "student") {
      return swal(
        "Not allowed",
        "Students cannot login here. Students should login only through the links provided in the Assignments on the canvas course page",
        "error"
      );
    }
    dispatch(sessionActions.storeSession(resp));

    if (role === "student") {
      dispatch(push("/student"));
    } else if (role === "analyst") {
      dispatch(push("/analyst"));
    } else if (role === "instructor") {
      dispatch(push("/teacher"));
    } else if (role === "ta") {
      dispatch(push("/ta"));
    }
  }

  onGoogleResponse = (r) => {
    const { dispatch } = this.props;
    if (r.accessToken) {
      const role = this.state.user.role;
      if (!role) {
        return swal("Role not selected", "Please select role", "error");
      }
      auth.registerWithGoogle(role, r.accessToken).then(
        (resp) => {
          dispatch(sessionActions.storeSession(resp));
          swal(
            "Success!",
            "Signup is successful and a verification link is emailed from support@knowledgevarsity.com to your registered email id. If you do not receive the confirmation message within a few minutes then please check spam folder. You should close this tab now.",
            "success"
          );
        },
        (err) => {
          swal("Error", err.msg, "error");
        }
      );
    } else {
      swal("Oops", "Google login error", "error");
    }
  };

  renderSuccess = () => {
    return (
      <div className="panel clearfix">
        <p>
          Signup is successful and a verification link is emailed from
          support@knowledgevarsity.com to your registered email id. If you do
          not receive the confirmation message within a few minutes then please
          check spam folder.
        </p>
      </div>
    );
  };

  render() {
    const { user, loading, error, success } = this.state;

    return (
      <div>
        <Header />
        <div className="main-content">
          <div className="container">
            <div className="col-md-8 col-md-offset-2">
              {success ? (
                this.renderSuccess()
              ) : (
                <div className="panel clearfix">
                  <h1 className="panel-caption">Sign Up</h1>
                  <p className="alert alert-info">All fields are required</p>
                  {error && <p className="text-danger">{error}</p>}
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>First Name</label>
                        <input
                          name="firstName"
                          value={user.firstName}
                          onChange={this.onChange}
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Last Name</label>
                        <input
                          name="lastName"
                          value={user.lastName}
                          onChange={this.onChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Official University Email Address</label>
                        <input
                          name="email"
                          value={user.email}
                          onChange={this.onChange}
                          className="form-control"
                          type="email"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Re-type Email ID</label>
                        <input
                          name="emailConfirm"
                          value={user.emailConfirm}
                          onChange={this.onChange}
                          className="form-control"
                          type="email"
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Password</label>
                        <input
                          name="password"
                          value={user.password}
                          onChange={this.onChange}
                          className="form-control"
                          type="password"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>Re-type Password</label>
                        <input
                          name="passwordConfirm"
                          value={user.passwordConfirm}
                          onChange={this.onChange}
                          className="form-control"
                          type="password"
                          required
                        />
                      </div>
                    </div>
                    <div className="row" id="studentFields">
                      <div className="form-group col-md-6">
                        <label htmlFor="cohort">Class Section</label>
                        <select
                          required
                          className="form-control"
                          name="cohort"
                          value={user.cohort}
                          onChange={this.onChange}
                        >
                          <option value="" disabled>
                            Please select
                          </option>
                          {COURSE_CODES.map((i) => (
                            <option value={i} key={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="">Canvas / Blackboard Username</label>
                        <input
                          required
                          name="studentId"
                          value={user.studentId}
                          onChange={this.onStudentIdChange}
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Digital Access Code</label>
                        <input
                          name="accessCode"
                          value={user.accessCode}
                          onChange={this.onChange}
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input
                          required
                          name="tnc"
                          checked={user.tnc}
                          onChange={this.onChange}
                          type="checkbox"
                        />
                        Some of your assignments are graded through the
                        Knowledge Varsity system. For this purpose, your email
                        ID and Canvas user name are used to identify you
                        uniquely. All information, including your grades, is
                        kept confidential and only shared with the course
                        coordinator, assigned instructor, or teaching assistant.
                      </label>
                    </div>
                    <input
                      disabled={loading || !user.tnc}
                      className="mt-10 btn btn-lg btn-wide btn-default"
                      type="submit"
                      value="Register"
                    />
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
