import React from 'react';
import {Col, message, Modal, Row, Spin} from 'antd';
import SplitPane from 'react-split-pane';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import Sheet from '../../../components/Sheet';
import ChartType from '../../../components/GraphChartSheet';
import PivotSource from '../../../components/PivotSource';
import CFSource from '../../../components/CFSource';
import FilterSource from '../../../components/FilterSource';
import SortSource from '../../../components/SortSource';
import ActionPanel from './XLSActionPanel';
import questionApi from '../../../api/question';
import commentApi from '../../../api/comments';
import assignmentApi from '../../../api/assignment';

export default class AnswerModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    answers: PropTypes.array,
    answer: PropTypes.object,
    courseId: PropTypes.string,
    assignmentId: PropTypes.string,
    questionId: PropTypes.string,
    studentId: PropTypes.string
  };

  state = {
    selectedAnswer: undefined,
    answerData: [],
    data: {},
    blocks: [],
    rubrics: [],
    cellFormulas: {},
    cellValues: {},
    sheets: {},
    selectedSheet: '',
    cellRef: null,
    questionData: null,
    file: null,
    submitting: false,
    loading: true,
    loadingBlock: false,
  };

  componentWillMount() {
    const { courseId, questionId, assignmentId, answers } = this.props;
    const { studentId } = answers[0];
    this.setState({
      selectedAnswer: studentId
    });
    Promise.all([
      questionApi.getQuestionXls(courseId, assignmentId, questionId),
      questionApi.getAnswerXls(courseId, assignmentId, questionId, studentId)
    ]).then(
      ([questionData, answerData]) => {
        const questionDataGrouped = groupBy(questionData, 'sheetName');
        let sheets = {};
        let defaultSheet = null;

        Object.keys(questionDataGrouped).forEach(sheetName => {
          const questionExcelData = questionDataGrouped[sheetName];
          if (!defaultSheet) {
            defaultSheet = sheetName;
          }

          const questionDataMap = {};
          questionExcelData.forEach(q => {
            questionDataMap[q.cellRange] = q;
          });

          sheets[sheetName] = {
            questionDataMap,
          }
        });

        sheets = this.composeAnswerData(sheets, answerData);

        this.setState({
          sheets,
          selectedSheet: defaultSheet,
          loading: false,
        });
      }
    );
    this.getRubric();
  }

  composeAnswerData(sheets, answerData) {
    const answerDataGrouped = groupBy(answerData, 'worksheetName');
    const updatedSheets = {};
    Object.keys(sheets).map(sheetName => {
      const sheet = cloneDeep(sheets[sheetName]);
      const excelBlocks = answerDataGrouped[sheetName];
      const blocks = [];
      const data = sheet.questionDataMap;
      let isChart = false;

      forEach(excelBlocks, a => {
        blocks.push(a);
        if (a.blockCellRange === 'ChartType') {
          isChart = true;
        }
        a.cells.forEach(cell => {
          cell.excelBlockID = a.excelBlockID;
          cell.blockCellRange = a.blockCellRange;
          cell.worksheetId = a.worksheetId;
          data[cell.range] = cell;
        });
      });

      const firstBlock = excelBlocks && excelBlocks[0];

      const blockCellRange = firstBlock && firstBlock.blockCellRange;

      updatedSheets[sheetName] = {
        answerData: excelBlocks,
        questionDataMap: sheets[sheetName].questionDataMap,
        data,
        blocks,
        isChart,
        blockCellRange
      };
    });
    return updatedSheets;
  }

  onSheetSelect = (name) => {
    this.setState({
      selectedSheet: name
    });
  };

  onAnswerSelect = (val) => {
    this.setState({
      selectedAnswer: val
    });
    this.getStudentAnswer(val);
  };

  getStudentAnswer = async (studentId) => {
    this.setState({ loading: true });
    const { courseId, questionId, assignmentId } = this.props;
    const answerData = await questionApi.getAnswerXls(courseId, assignmentId, questionId, studentId);

    const sheets = this.composeAnswerData(this.state.sheets, answerData);

    this.setState({
      sheets,
      loading: false
    });
  };

  getFormulas = (range, sheetName) => {
    const { courseId, questionId, assignmentId } = this.props;
    return questionApi.getFormulas(courseId, assignmentId, questionId, range, sheetName);
  };

  getRubric = (range, sheetName) => {
    const { courseId, questionId, assignmentId } = this.props;
    assignmentApi.getRubrics(courseId, assignmentId, questionId).then(resp => {
      this.setState({
        rubrics: resp
      });
    });
  };

  onSaveComment = async (payload, id) => {
    if (id) {
      console.log(payload);
      await commentApi.updateComment(id, {
        commentText: payload.commentText,
        marks: payload.marks
      });
    } else {
      await commentApi.addComment(payload);
    }
    this.onCellClick(this.state.cellRef, true);
    message.success('Comment saved');
  };

  onSaveRubic = (values, xlsBlockId) => {
    const { courseId, questionId, assignmentId } = this.props;
    return assignmentApi.updateRubrics(courseId, assignmentId, questionId, xlsBlockId, values);
  };

  onDeleteComment = async (block, id) => {
    await commentApi.deleteComment(id);
    this.onCellClick(this.state.cellRef, true);
    message.success('Comment deleted');
  };

  onCellClick = (cell, isInRange) => {
    if (isInRange) {
      this.setState({
        loadingBlock: true,
        cellRef: cell,
      });
      this.getFormulas(cell, this.state.selectedSheet).then(
        ({ blocks }) => {
          this.setState({
            loadingBlock: false,
            cellFormulas: {
              ...this.state.cellFormulas,
              [cell]: blocks,
            }
          })
        }
      );
    }
  };

  renderComponent(sheet) {
    const {data, blocks, isChart, blockCellRange} = sheet;
    switch (blockCellRange) {
      case "PivotSource":
        return <PivotSource blocks={blocks} onCellClick={this.onCellClick} />;
      case 'CFSource':
        return <CFSource blocks={blocks} onCellClick={this.onCellClick} />;
      case 'FilterSource':
        return <FilterSource blocks={blocks} onCellClick={this.onCellClick} />;
      case 'SortSource':
        return <SortSource blocks={blocks} onCellClick={this.onCellClick} />;
      default: {
        if (isChart) {
          return (
            <ChartType
              blocks={blocks}
              onGetFormulas={this.getFormulas}
              onCellClick={this.onCellClick}
            />
          )
        } else {
          return (
            <Sheet
              data={data}
              onGetFormulas={this.getFormulas}
              onCellClick={this.onCellClick}
            />
          )
        }
      }
    }
  }

  render() {
    const { onClose, answers } = this.props;
    const { loading, sheets, selectedSheet, cellRef, loadingBlock, selectedAnswer, cellFormulas } = this.state;

    const sheet = sheets[selectedSheet];

    if (!sheet) return null;

    return (
      <Modal
        className="xls-modal"
        wrapClassName="xls-modal-wrapper"
        visible onCancel={onClose}
        footer={false}
        title={
          <div className="sheets-tabs">
            { Object.keys(sheets).map(s => <button onClick={() => this.onSheetSelect(s)} disabled={selectedSheet === s}>{s}</button>)}
          </div>
        }
      >
        <Spin spinning={loading}>
          <SplitPane split="vertical" defaultSize="50%" minSize={300} maxSize={-300} style={{ height: '100vh'}}>
            <div>
              {this.renderComponent(sheet)}
            </div>
            <div>
              <ActionPanel
                  key={cellRef}
                  worksheetName={this.state.selectedSheet}
                  blockCellRange={sheet.blockCellRange}
                  answers={answers}
                  selectedAnswer={selectedAnswer}
                  onSaveComment={this.onSaveComment}
                  onDeleteComment={this.onDeleteComment}
                  block={cellFormulas[cellRef]}
                  cellRef={cellRef}
                  loadingBlock={loadingBlock}
                  onAnswerSelect={this.onAnswerSelect}
                  rubrics={this.state.rubrics}
                  onSaveRubic={this.onSaveRubic}
              />
            </div>
          </SplitPane>
          {/*<Row type="flex">*/}
          {/*  <Col span={12}>*/}
          {/*    */}
          {/*  </Col>*/}
          {/*  <Col span={12}>*/}
          {/*    */}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Spin>
      </Modal>
    );
  }
}
