import React from 'react';
import sortBy from 'lodash/sortBy';

const NonSubmissions = (props) => {
    const nonSubmissions = props['non-submissions'];
    return (
        <ol>
            {sortBy(nonSubmissions, ['firstName', 'lastName']).map(i => <li>{i.firstName} {i.lastName}</li>)}
        </ol>
    )
}

export default NonSubmissions;