import flatten from 'lodash/flatten';
import * as types from './types';
import Course from '../../../api/course';
import Assignment from '../../../api/assignment';
import Question from '../../../api/question';

export const getAssignments = (courseId) => ({
  type: types.GET_ASSIGNMENTS,
  payload: {
    promise: new Promise(async (resolve, reject) => {
      try {
        const assignments = await Assignment.list(courseId)
        resolve({ data: assignments });

      } catch (e) {
        reject(e);
      }
    })
  }
});

export const createAssignment = (courseId, data) => ({
  type: types.CREATE_ASSIGNMENT,
  payload: {
    promise: new Promise(async (resolve, reject) => {
      try {
        const assignent = await Assignment.create(courseId, data);
        resolve(assignent);
      } catch (e) {
        reject(e);
      }
    })
  }
});

export const updateAssignment = (courseId, assignmentId, data) => ({
  type: types.EDIT_ASSIGNMENT,
  payload: {
    promise: Assignment.update(courseId, assignmentId, data)
  }
});

export const deleteAssignment = (courseId, assignmentId) => ({
  type: types.DELETE_ASSIGNMENT,
  payload: {
    promise: Assignment.delete(courseId, assignmentId),
    assignmentId
  }
});

export const getCourses = () => ({
  type: types.LOAD_COURSES
});


export const getQuestions = (courseId, assignmentId) => ({
  type: types.LOAD_QUESTIONS,
  payload: {
    promise: Question.list(courseId, assignmentId)
  }
});

export const createQuestion = (courseId, assignmentId, data) => ({
  type: types.CREATE_QUESTIONS,
  payload: {
    promise: Question.create(courseId, assignmentId, data)
  }
});

export const updateQuestion = (courseId, assignmentId, questionId, data) => ({
  type: types.EDIT_QUESTIONS,
  payload: {
    promise: Question.update(courseId, assignmentId, questionId, data)
  }
});

export const deleteQuestion = (courseId, assignmentId, questionId) => ({
  type: types.DELETE_QUESTIONS,
  payload: {
    promise: Question.delete(courseId, assignmentId, questionId),
    questionId
  }
});

export const getStudentAnswers = (courseId, assignmentId, questionId) => ({
  type: types.GET_STUDENT_ANSWERS,
  payload: {
    promise: Assignment.getStudentAnswers(courseId, assignmentId, questionId).then(
      resp => {
        const { content } = resp;
        let answers = [];
        content.forEach(r => {
          r.answers.forEach(a => {
            a.studentId = r.userId;
          });
          answers.push(r.answers);
        });
        answers = flatten(answers).filter(a => a.questionId == questionId);
        return answers;
      }
    )
  }
});

export const getQuestion = (courseId, assignmentId, questionId) => ({
  type: types.GET_QUESTION,
  payload: {
    promise: Question.get(courseId, assignmentId, questionId)
  }
});

export const getAssignment = (courseId, assignmentId) => ({
  type: types.GET_ASSIGNMENT,
  payload: {
    promise: Assignment.get(courseId, assignmentId)
  }
});
