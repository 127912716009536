import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import swal from 'sweetalert';
import { GoogleLogin } from 'react-google-login';
import get from 'lodash/get';

import auth from '../../../api/auth';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

@autobind
export default class LoginBar extends React.Component {
  static propTypes = {
    onLogin: PropTypes.func
  };

  constructor() {
    super();
    this.state = {
      user: {
        userName: '',
        password: ''
      }
    }
  }

  onChange(e) {
    const { name, value } = e.target;
    const { user } = this.state;
    user[name] = value;
    this.setState({ user });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { userName, password } = this.state.user;
    if (userName && password) {
      auth.login(userName, password).then(
        resp => {
          this.props.onLogin(resp);
        },
        err => {
          swal('Oops', get(err, 'errors[0].error', err.message), 'error');
        }
      )
    }
  }

  onGoogleResponse = (r) => {
    if (r.accessToken) {
      auth.loginWithGoogle(r.accessToken).then(
        resp => {
          this.props.onLogin(resp);
        },
        err => {
          swal('Oops', get(err, 'errors[0].error', err.message), 'error');
        }
      )
    } else {
      swal('Oops', 'Login error', 'error');
    }
  };

  onForgotPasswordClick(e) {
    e.preventDefault();
    swal({
      text: 'Enter your registered email to receive password reset link',
      content: {
        element: 'input',
        attributes: {
          type: 'email'
        }
      },
      button: {
        text: 'Send',
        closeModal: false
      }
    })
      .then(email => {
        if (!email) throw null;

        return auth.sendResetPasswordLink(email);
      })
      .then(resp => {
        swal('Success!', resp.msg, 'success');
      })
      .catch(err => {
        if (err) {
          swal("Oops", err.msg, "error");
        }
      });
  }

  render() {
    const { user } = this.state;

    return (
      <div className="login navbar">
        <div className="container">
          <p className="login__alert">
            Students cannot login here. Students should login only through the links provided in the Assignments on the canvas course page
          </p>
          <form className="form-inline" onSubmit={this.handleSubmit}>
            <div className="login__field">
              <input name="userName" value={user.userName} onChange={this.onChange} className="form-control" type="email" placeholder="Email" />
            </div>
            <div className="login__field">
              <input name="password" value={user.password} onChange={this.onChange} className="form-control" type="password" placeholder="Password" />
                <a href="#" onClick={this.onForgotPasswordClick}>Forgot password?</a>
            </div>
            <div className="login__field">
              <input className="btn btn-default" type="submit" value="Login" id="homeLogin" />
            </div>
          </form>
        </div>
      </div>
    )
  }
}
