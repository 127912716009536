import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Courses from './containers/Courses';
import TAPage from "./TAPage";
import Resources from "./Resources";
import CohortDetails from "./containers/CohortDetails";

export default () =>
  <Switch>
    <Route exact path="/ta/courses" component={Courses} />
    <Route exact path="/ta/courses/:courseId/cohort-details/:id" component={CohortDetails} />
    <Route path="/ta/resources/:courseId?" component={Resources} />
    <Redirect to="/ta/courses" from="/ta" />
  </Switch>