import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import promiseMiddleware from './middleware/simple-promise';

import * as reducers from './modules';

const config = {
  key: 'collegedesk',
  storage,
};

export default function (initialState = {}, history) {
  const appReducer = persistCombineReducers(config, {
    ...reducers,
    router: routerReducer
  });
  const middlewares = applyMiddleware(thunk, promiseMiddleware(), routerMiddleware(history));
  const store = createStore(appReducer, initialState, middlewares);

  const persistor = persistStore(store);

  return { store, persistor };
}

