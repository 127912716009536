import React from "react";
import { connect } from "react-redux";
import { message, Alert } from "antd";
import assignmentAPI from "../../../api/assignment";
import swal from "sweetalert";

@connect((s) => ({ user: s.session.user }))
class DownloadQuestion extends React.Component {
  state = {
    downloading: false,
    success: false,
  };

  componentDidMount() {
    const { user, match } = this.props;
    const isEnrolled = user.courses.find(c => c.courseId === Number(match.params.courseId));
    this.setState({
      isEnrolled
    })
    if (isEnrolled) {
      this.download();
    }
  }

  download = () => {
    const { courseId, assignmentId, questionId } = this.props.match.params;
    this.setState({ downloading: true });
    assignmentAPI
      .downloadQuestionFile(courseId, assignmentId, questionId)
      .then(
        () => {
          message.success("Question File downloaded");
          this.setState({ success: true });
          swal(
            "After working on the file, Please turn in .xlsx file",
            "You should use Office 365 for this course. However, If you are using Apple Numbers, then before turning in, export the .numbers file as .xlsx without the summary sheet"
          );
        },
        (e) => {
          console.log(e);
          swal("Oops", e.errors[0].error, "error");
        }
      )
      .finally(() => this.setState({ downloading: false }));
  };
  render() {
    const { downloading, success, isEnrolled } = this.state;


    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            { !isEnrolled && <Alert showIcon message="You are not enrolled for this course" type={"error"} />}
            {downloading && "Downloading...."}
            {success && <Alert showIcon message="File downloaded." type={"success"} />}
          </div>
        </div>
      </div>
    );
  }
}

export default DownloadQuestion;
