import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { Row, Col, Button } from 'antd';
import PropTypes from 'prop-types';


export default class EditQuestionModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    question: PropTypes.object,
    onSubmit: PropTypes.func
  };

  state = {
    questionText: '',
    maxScore: '',
    file: null,
    modelAnswerFile: undefined,
    ZIPAnswerFile: undefined,
    questionType: 'FILE_UPLOAD',
  };

  componentWillMount() {
    const { question } = this.props;
    if (question) {
      this.setState({
        questionId: question.questionId,
        questionText: question.questionText,
        maxScore: question.maxScore,
      });
    }
  }

  onChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  onFileSelect = (e) => {
    this.setState({
      file: e.target.files && e.target.files[0]
    });
  };

  onSubmit = () => {
    const update = this.state;
    this.setState({
      saving: true
    });
    this.props.onSubmit(update).catch(
      (e) => {
        alert(e.message);
        this.setState({
          saving: false
        });
      }
    )
  };

  render() {
    const { onClose, question } = this.props;
    return (
      <Modal show onHide={onClose} autoFocus>
        <Modal.Header closeButton>
          <Modal.Title>{!!question ? 'Edit Question' : 'Add New Question'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="clearfix">
          <div className="form-group">
            <Row type="flex" align="middle">
              <Col span={19}>Type your question below</Col>
              <Col span={2}>Marks:</Col>
              <Col span={3}>
                <input className="form-control" type="number" value={this.state.maxScore} onChange={this.onChange} name="maxScore" />
              </Col>
            </Row>
          </div>
          <div className="form-group">
            <textarea rows={6} className="form-control" autoFocus value={this.state.questionText} onChange={this.onChange} name="questionText" />
          </div>
          <div className="form-group">
            <label>Upload file</label>
            <Row type="flex" align="middle" style={{ marginBottom: 5 }} gutter={12}>
              <Col span={6}>Add Question File</Col>
              <Col span={10}><input type="file" className="form-control" onChange={this.onFileSelect} /></Col>
              <Col span={8}>
                {question && question.fileName}
              </Col>
            </Row>
          </div>
          <div className="text-right">
            <Button type="primary" loading={this.state.saving} onClick={this.onSubmit}>Save</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}