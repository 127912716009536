import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ToggleSwitch from '@trendmicro/react-toggle-switch';

import AssignmentModal from '../components/AssignmentFolderModal';

import { teacherActions } from '../../../redux/modules/teacher';


@connect(state => state.teacher.studentAnswers, dispatch => bindActionCreators(teacherActions, dispatch))
export default class StudentAnswers extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedAnswer: null,

    }
  }

  componentWillMount() {
    const { courseId, assignmentId, questionId } = this.props.match.params;
    this.props.getQuestion(courseId, assignmentId, questionId);
    this.props.getAssignment(courseId, assignmentId);
    this.props.getStudentAnswers(courseId, assignmentId, questionId);
  }

  renderAnswer = (answer) => {
    return (
      <div className="answer" key={answer.studentAnswerId} onClick={() => this.setState({ selectedAnswer: answer })}>
        <p className="answer__title">Student ID: {answer.studentId}</p>
        <div className="answer__answer">
          <p>{answer.shortAnswerText}</p>
        </div>
      </div>
    )
  };

  render() {
    const { selectedAnswer } = this.state;
    const { assignment, question, answers } = this.props;
    const { courseId, assignmentId } = this.props.match.params;

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <div className="row" style={{marginBottom: 20}}>
              <div className="col-md-6">
                <Link to={`/teacher/courses/${courseId}/assignments/${assignmentId}`}>
                  <i className="glyphicon glyphicon-arrow-left"/>
                  Back to assignment
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-7">
                <div className="row">
                  <div className="col-sm-7">
                    <h4>
                      <strong><i className="glyphicon glyphicon-file"/> {assignment && assignment.title}</strong>
                    </h4>
                  </div>
                  <div className="col-sm-5">
                    <h4 className="text-muted">Due on: {assignment && moment(assignment.dueDateAndTime).format('MM/DD/YY | hh:mm a')}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <div className="row" style={{position: 'relative'}}>
                    <div className="col-md-12">
                      {answers.map(this.renderAnswer)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}