import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import WithLogin from "./components/WithLogin";
import Login from "./containers/Login";
import Courses from "./containers/Courses";
import Assignments from "./containers/Assignments";
import Assignment from "./containers/Assignment";
import GradeView from "./containers/GradeView";

import DownloadQuestion from "./containers/DownloadQuestion";
import DownloadAnswer from "./containers/DownloadAnswer";
import AnswerQuestion from "./containers/AnswerQuestion";

export default () => (
  <Switch>
    <Route path="/student/login" component={Login} />
    <Route exact path="/student/courses" component={Courses} />
    <Route
      exact
      path="/student/assignments/:id/gradeview"
      component={GradeView}
    />
    <Route
      exact
      path="/student/courses/:id/assignments"
      component={Assignments}
    />
    <Route path="/student/assignments/:id" component={Assignment} />
    <Route
      exact
      path="/student/courses/:courseId/assignments/:assignmentId/questions/:questionId/download-question"
      render={(props) => (
        <WithLogin {...props} skipEnrollment>
          <DownloadQuestion {...props} />
        </WithLogin>
      )}
    />
    <Route
      exact
      path="/student/courses/:courseId/assignments/:assignmentId/questions/:questionId/download-answer"
      render={(props) => (
        <WithLogin {...props} skipEnrollment>
          <DownloadAnswer {...props} />
        </WithLogin>
      )}
    />
    <Route
      exact
      path="/student/courses/:courseId/assignments/:assignmentId/questions/:questionId/answer"
      render={(props) => (
        <WithLogin {...props} skipEnrollment>
          <AnswerQuestion {...props} />
        </WithLogin>
      )}
    />
    <Redirect to="/student/courses" from="/student" />
  </Switch>
);
