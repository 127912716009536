import React from 'react';
import clickOutside from 'react-click-outside';
import { Checkbox, Radio } from 'react-bootstrap';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

export default class TagSuggestions extends React.Component {

  static propTypes = {
    tags: PropTypes.array,
    onSave: PropTypes.func,
    onSelect: PropTypes.func
  };

  constructor(...args){
    super(...args);
    this.state = {
      popoverBox: {},
    }
  }

  @autobind
  onChange(e) {
    const { value, checked } = e.target;
    this.props.onSelect(Number(value), checked);
  }

  render() {
    const { tags, onSave } = this.props;

    const optionsList = tags.map(i =>
      <Checkbox
        style={styles.item}
        key={i.KeyphraseWithStopWordsID}
        value={i.KeyphraseWithStopWordsID}
        checked={i.is_selected}
        onChange={this.onChange}>{i.KeyphraseTextWithStopWords}</Checkbox>
    );

    return (
      <div style={styles.dropdown}>
          <p>Select similar tags:</p>
          <div className="tags-menu__items" style={styles.list}>
            {optionsList}
          </div>
        <button className="btn btn-primary btn-sm" onClick={onSave}>save</button>
      </div>
    )
  }
}

const styles = {
  header: {
    padding: 5,
    backgroundColor: '#efefef',
    fontWeight: 700,
    display: 'flex'
  },
  dropdown: {
   // position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    boxShadow: '0 0 1px rgba(0,0,0,.15)',
    padding: 10
  },
  item: {
    padding: '0 15px'
  },
  list: {
    maxHeight: 300,
    overflow: 'auto'
  }
};