import React, { useState } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import swal from "sweetalert";
import get from "lodash/get";
import Header from "../../../components/Header";
import {
  BOOKS,
  UNIVERSITY,
  CLASS_SECTIONS,
  COURSE_CODES,
} from "../../../constants";
import auth from "../../../api/auth";
import { sessionActions } from "../../../redux/modules/session";

const StepOne = ({ onChange, values, onNext }) => {
  const classSection = CLASS_SECTIONS.find(
    (i) => i.book === values.book && i.university === values.university
  );
  const sections = classSection ? classSection.sections : [];

  return (
    <div>
      <div className="form-group">
        <label htmlFor="book">Book</label>
        <select
          required
          className="form-control"
          name="book"
          value={values.book}
          onChange={onChange}
        >
          <option value="" disabled>
            Please select
          </option>
          {BOOKS.map((i) => (
            <option value={i.id} key={i.id}>
              {i.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="university">University</label>
        <select
          required
          className="form-control"
          name="university"
          value={values.university}
          onChange={onChange}
        >
          <option value="" disabled>
            Please select
          </option>
          {UNIVERSITY.map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="section">Class Section</label>
        <select
          required
          className="form-control"
          name="section"
          value={values.section}
          onChange={onChange}
        >
          <option value="" disabled>
            Please select
          </option>
          {sections.map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Digital Access Code</label>
        <input
          name="code"
          value={values.code}
          onChange={onChange}
          className="form-control"
          required
        />
      </div>
      <button
        onClick={onNext}
        disabled={
          !values.book || !values.university || !values.section || !values.code
        }
        className="btn btn-default  btn-lg"
      >
        Next
      </button>
    </div>
  );
};

const StepTwo = ({ onChange, values, onNext, onBack }) => {
  return (
    <div className="form-group">
      <div className="radio">
        <label>
          <input
            type="radio"
            name="type"
            value="new"
            onChange={onChange}
            checked={values.type === "new"}
          />{" "}
          I am a new user
        </label>
      </div>
      <div className="radio">
        <label>
          <input
            type="radio"
            name="type"
            value="existing"
            onChange={onChange}
            checked={values.type === "existing"}
          />{" "}
          I am a registered user
        </label>
      </div>
      <button
        className="btn btn-default  btn-lg"
        disabled={!values.type}
        onClick={onNext}
      >
        Next
      </button>{" "}
      <button className="btn btn-link" onClick={onBack}>
        Back
      </button>
    </div>
  );
};

const LoginForm = ({ values, onChange, dispatch, onBack }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const { userName, password } = values;
    if (userName && password) {
      auth.login(userName, password, values.section, values.code).then(
        (resp) => {
          onLoginSuccess(resp);
        },
        (err) => {
          swal("Oops", get(err, "errors[0].error", err.message), "error");
        }
      );
    }
  };

  const onLoginSuccess = (resp) => {
    dispatch(sessionActions.storeSession(resp));
    dispatch(push("/student"));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4>Login</h4>
      <div className="row">
        <div className="form-group col-md-6">
          <input
            name="userName"
            value={values.userName}
            onChange={onChange}
            className="form-control"
            type="email"
            placeholder="Email"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <input
            name="password"
            value={values.password}
            onChange={onChange}
            className="form-control"
            type="password"
            placeholder="Password"
          />
        </div>
      </div>
      <div className="login__field">
        <input
          className="btn btn-default  btn-lg"
          type="submit"
          value="Sign In"
        />{" "}
        <button className="btn btn-link" onClick={onBack}>
          Back
        </button>
      </div>
    </form>
  );
};

const SignupForm = ({ values, onChange, onBack }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    const { emailConfirm, passwordConfirm, code, section, ...rest } = values;

    let error = "";
    if (emailConfirm !== rest.email) error = "Email do not match";
    else if (passwordConfirm !== rest.password) error = "Password do not match";
    //else if (!emailConfirm.endsWith(".edu"))
    //  error = "Please register using your University (.edu) email address ";

    if (error) {
      swal("Validation error", error, "error");
    } else {
      if (!rest.lastName) rest.lastName = " ";
      rest.roles = ["student"];

      setError();
      setLoading(true);

      auth
        .register({
          ...rest,
          cohort: section,
          accessCode: code,
          //studentId: emailConfirm.split("@")[0],
          //studentId: emailConfirm.split("@")[0].split(".")[0],
          studentId: "christ",
        })
        .then(
          () => {
            swal(
              "Success!",
              "Signup is successful and a verification link is emailed from support@knowledgevarsity.com to your registered email id. If you do not receive the confirmation message within a few minutes then please check spam folder. You should close this tab now.",
              "success"
            );
            setLoading(false);
            setSuccess(true);
          },
          (err) => {
            console.log(err);
            const errors = err.errors
              ? err.errors.map((e) => e.error).join("\r\n")
              : err.mesage || err.msg || "Something went wrong.";
            swal("Oops", errors, "error");
            setLoading(false);
          }
        );
    }
  };

  const renderSuccess = () => {
    return (
      <div className="panel clearfix">
        <p>
          Signup is successful and a verification link is emailed from
          support@knowledgevarsity.com to your registered email id. If you do
          not receive the confirmation message within a few minutes then please
          check spam folder.
        </p>
      </div>
    );
  };

  if (success) return renderSuccess();

  return (
    <div>
      <p className="alert alert-info">All fields are required</p>
      {error && <p className="text-danger">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-md-6">
            <label>First Name</label>
            <input
              name="firstName"
              value={values.firstName}
              onChange={onChange}
              className="form-control"
              type="text"
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label>Last Name</label>
            <input
              name="lastName"
              value={values.lastName}
              onChange={onChange}
              className="form-control"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label>Official University Email Address</label>

            <input
              name="email"
              value={values.email}
              onChange={onChange}
              className="form-control"
              type="email"
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label>Re-type Official University Email Address</label>
            <input
              name="emailConfirm"
              value={values.emailConfirm}
              onChange={onChange}
              className="form-control"
              type="email"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label>Password</label>
            <input
              name="password"
              value={values.password}
              onChange={onChange}
              className="form-control"
              type="password"
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label>Re-type Password</label>
            <input
              name="passwordConfirm"
              value={values.passwordConfirm}
              onChange={onChange}
              className="form-control"
              type="password"
              required
            />
          </div>
        </div>
        <div className="checkbox">
          <label>
            <input
              required
              name="tnc"
              checked={values.tnc}
              onChange={onChange}
              type="checkbox"
            />
            Some of your assignments are graded through the Knowledge Varsity
            system. For this purpose, your email ID and Canvas user name are
            used to identify you uniquely. All information, including your
            grades, is kept confidential and only shared with the course
            coordinator, assigned instructor, or teaching assistant.
          </label>
        </div>
        <input
          disabled={loading || !values.tnc}
          className="mt-10 btn btn-lg btn-wide btn-default"
          type="submit"
          value="Register"
        />{" "}
        <button className="btn btn-link" onClick={onBack}>
          Back
        </button>
      </form>
    </div>
  );
};

const Redeem = ({ dispatch }) => {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({
    book: "",
    university: "",
    section: "",
    code: "",
    type: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div>
      <Header />
      <div className="main-content">
        <div className="container">
          <div className="col-md-8 col-md-offset-2">
            <div className="panel clearfix">
              <h1 className="panel-caption">Redeem - Step {step} of 3</h1>
              {step === 1 && (
                <StepOne
                  onChange={onChange}
                  values={values}
                  onNext={() => setStep(2)}
                />
              )}
              {step === 2 && (
                <StepTwo
                  onChange={onChange}
                  values={values}
                  onNext={() => setStep(3)}
                  onBack={() => setStep(1)}
                />
              )}
              {step === 3 && values.type === "existing" && (
                <LoginForm
                  onChange={onChange}
                  values={values}
                  dispatch={dispatch}
                  onBack={() => setStep(2)}
                />
              )}
              {step === 3 && values.type === "new" && (
                <SignupForm
                  onChange={onChange}
                  values={values}
                  onBack={() => setStep(2)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(Redeem);
