import axios from "axios";
import get from "lodash/get";
import fileDownload from "../utilities/fileDownload";
const Course = {
  get(id) {
    return axios.get(`courses/${id}`);
  },

  list(page = 0, size = 10) {
    return axios.get("courses", { params: { page, size } });
  },

  create(data) {
    return axios.post("courses", data);
  },

  update(id, data) {
    return axios.patch(`courses/${id}`, data);
  },

  delete(id) {
    return axios.delete(`courses/${id}`);
  },

  enroll(id) {
    return axios.post(`selfenroll?add-course-id=${id}`).catch((err) => {
      throw get(err, "errors.0.error", err.message);
    });
  },

  enrollCourse(userId, courseId) {
    return axios.post(`users/${userId}/enroll/${courseId}`, {});
  },

  cohorts(courseId) {
    return axios.get(`courses/${courseId}/cohort`);
  },

  cohortDetails(courseId, cohortId) {
    return axios.get(`course/${courseId}/cohort-details/${cohortId}`);
  },

  cohortAssignments(cohortId) {
    return axios.get(`/v2/cohort/${cohortId}`);
  },

  downloadModelAnswer(courseID, assignmentID, questionID) {
    return axios
      .get(
        `courses/${courseID}/assignments/${assignmentID}/questions/${questionID}/download-answer?userId=10000`,
        {
          responseType: "blob",
        }
      )
      .then((resp) => {
        fileDownload(resp.content, resp.filename);
      });
  },
  downloadGradedAnswer(courseID, assignmentID, questionID, cohortId) {
    return axios
      .get(
        `courses/${courseID}/assignments/${assignmentID}/questions/${questionID}/download-answer?cohortid=${cohortId}&graded=true `,
        {
          responseType: "blob",
        }
      )
      .then((resp) => {
        fileDownload(resp.content, resp.filename);
      });
  },

  cohortSubmissions(courseID, assignmentID, cohortId) {
    return axios.get(`course/${courseID}/assignment/${assignmentID}/cohort-submissions/${cohortId}`)
  },

  getStudentSubmissions(courseID, userId) {
    return axios.get(`course/${courseID}/student-submission-details?userId=${userId}`)
  }

};

export default Course;
