import React from "react";
import { message, Alert, Spin, Button } from "antd";
import assignmentAPI from "../../../api/assignment";
import axios from "axios";
import fileDownload from "../../../utilities/fileDownload";
import moment from "moment-timezone";
import { connect } from "react-redux";

@connect((s) => ({ user: s.session.user }))
class DownloadAnswer extends React.Component {
  state = {
    loading: false,
    downloading: false,
    success: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    const { courseId, assignmentId, questionId } = this.props.match.params;
    assignmentAPI.getStudentAssignmentId(courseId, assignmentId).then(
      (resp) => {
        const { content } = resp;
        if (content.length) {
          this.setState({
            assignment: content[0],
            loading: false,
          });
        } else {
          message.error("No assignment found");
          this.setState({ loading: false });
        }
      },
      (e) => {
        message.error(e.message);
      }
    );
  }

  download = (answer) => (e) => {
    e.preventDefault();
    this.setState({ downloading: true });
    axios
      .get(answer.downloadGradedUrl, {
        responseType: "blob",
      })
      .then(
        (resp) => {
          fileDownload(resp.content, resp.filename);
          this.setState({ downloading: false });
        },
        (e) => {
          console.log(e);
          message.error("Error downloading answer");
          this.setState({ downloading: false });
        }
      );
  };

  onDownloadSubmittedFile = (answer) => (e) => {
    e.preventDefault();
    this.setState({ downloading: true });
    return axios
      .get(answer.downloadUrl, {
        responseType: "blob",
      })
      .then(
        (resp) => {
          fileDownload(resp.content, resp.filename);
          this.setState({ downloading: false });
        },
        (e) => {
          console.log(e);
          message.error("Error downloading answer");
          this.setState({ downloading: false });
        }
      );
  };

  render() {
    const { assignment, loading, downloading, success } = this.state;
    const { courses } = this.props.user;

    const course = courses.find(
      (c) => c.courseId === Number(this.props.match.params.courseId)
    );

    const trialDaysRemaining = 14 - moment().diff(course.enrollmentDate, "day");
    const isTrial = course.accessCodeType === "TRIAL";
    const isTrialing = trialDaysRemaining >= 0 && isTrial;
    const trialExpired = trialDaysRemaining < 0 && isTrial;

    if (trialExpired) {
      return (
        <div className="container-fluid">
          <div className="col-md-12">
            <Alert
              showIcon
              type="error"
              message={`Your trial access to this course has expired. `}
              description={
                <div>
                  Visit{" "}
                  <a
                    target="_blank"
                    href="www.knowledgevarsitypress.com/product-list"
                  >
                    www.knowledgevarsitypress.com/product-list
                  </a>{" "}
                  to purchase access code. For any issues send an email to
                  support@knowledgevarsity.com
                </div>
              }
            />
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <Spin spinning={loading || downloading} tip={downloading && 'Downloading...'}>
            <div className="panel clearfix">
              <div className="panel-caption">
                <h3>Download Answers</h3>
              </div>
              <br/>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Submission Time</th>
                    <th>Submitted File</th>
                    <th>Grader's Comments</th>
                    <th>Score</th>
                    <th>Graded File</th>
                  </tr>
                </thead>
                <tbody>
                {assignment?.answers.map((i, idx) => (
                    <tr key={idx}>
                      <td>{idx+1}</td>
                      <td>{moment(i.submissionTime).format('LLL')}</td>
                      <td>
                        <a href="#" onClick={this.onDownloadSubmittedFile(i)}>Download</a>
                      </td>
                      <td>{i.graderComment}</td>
                      <td>{i.was_comment_processed ? i.marks : 'Not available'}</td>
                      <td>
                        {i.downloadGradedUrl ? <a href="#" onClick={this.download(i)}>Download</a> : 'Not available'}
                      </td>
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}

export default DownloadAnswer;
