import React from 'react';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import createStore from './redux/createStore';
import authProvider from './utilities/authProvider';

import App from './App';

const history = createBrowserHistory();
const { store, persistor } = createStore(undefined, history);

authProvider(store);

const Loading = () => <h5>Loading...</h5>;

const Client = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<Loading />}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default Client;
