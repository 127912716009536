import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Courses extends React.Component {
  render() {
    const { courses } = this.props.user;

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <div className="row">
              <div className="col-sm-7">
                <h1 className="panel-caption">My Courses</h1>
              </div>
            </div>
            <table className="cd-table table table-striped">
              <thead>
                <tr>
                  <th>Course Title</th>
                  <th>Instructor Resource</th>
                  <th>Sections</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {courses.map((c) => (
                  <tr key={c.courseId}>
                    <td> {c.title}</td>
                    <td>
                      <Link to={`/ta/resources/${c.courseId}`}>Click here</Link>
                    </td>
                    <td>
                      {c.cohorts.map((i) => (
                        <Link
                          style={{ marginRight: 10 }}
                          to={`/ta/courses/${c.courseId}/cohort-details/${i.cohortId}`}
                        >
                          {i.name}
                        </Link>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((s) => s.session)(Courses);
