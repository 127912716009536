import React from "react";
import { autobind } from "core-decorators";
import swal from "sweetalert";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import Header from "../../../components/Header";
import auth from "../../../api/auth";
import { sessionActions } from "../../../redux/modules/session";
import get from "lodash/get";
import { PRODUCT_LIST_URL } from "../../../constants";

@connect()
@autobind
export default class Signup extends React.Component {
  constructor() {
    super();
    this.state = {
      user: {
        userName: "",
        password: "",
      },
      loading: false,
      success: false,
      error: null,
    };
  }

  onChange(e) {
    const { name, value, checked } = e.target;
    const { user } = this.state;
    if (name === "tnc") {
      user[name] = checked;
    } else {
      user[name] = value;
    }
    this.setState({ user });
  }

  onLoginSuccess(resp) {
    const { dispatch } = this.props;
    const role = resp.user.roles[0];

    dispatch(sessionActions.storeSession(resp));

    if (role === "student") {
      dispatch(push("/student"));
    } else if (role === "analyst") {
      dispatch(push("/analyst"));
    } else if (role === "instructor") {
      dispatch(push("/teacher"));
    } else if (role === "ta") {
      dispatch(push("/ta"));
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    const { user } = this.state;
    user[name] = value;
    this.setState({ user });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { userName, password } = this.state.user;
    if (userName && password) {
      auth.login(userName, password).then(
        (resp) => {
          this.onLoginSuccess(resp);
        },
        (err) => {
          swal("Oops", get(err, "errors[0].error", err.message), "error");
        }
      );
    }
  };

  onForgotPasswordClick(e) {
    e.preventDefault();
    swal({
      text: "Enter your registered email to receive password reset link",
      content: {
        element: "input",
        attributes: {
          type: "email",
        },
      },
      button: {
        text: "Send",
        closeModal: false,
      },
    })
      .then((email) => {
        if (!email) throw null;

        return auth.sendResetPasswordLink(email);
      })
      .then((resp) => {
        swal("Success!", resp.msg, "success");
      })
      .catch((err) => {
        if (err) {
          swal("Oops", err.msg, "error");
        }
      });
  }

  render() {
    const { success, user } = this.state;

    return (
      <div>
        <Header />
        <div className="main-content">
          <div className="container">
            <div className="col-md-6 col-md-offset-3">
              {success ? (
                this.renderSuccess()
              ) : (
                <div className="panel clearfix">
                  <div className="text-center">
                    <Link to="/redeem" className="btn-link btn-block">
                      Redeem Access Code
                    </Link>
                    <a target="_blank" className="btn-link btn-block" href={PRODUCT_LIST_URL}>
                      Purchase Access Code
                    </a>
                  </div>
                  <br/>
                  <div className="mt-10">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="form-group col-md-12">
                          <input
                            name="userName"
                            value={user.userName}
                            onChange={this.onChange}
                            className="form-control"
                            type="email"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-12">
                          <input
                            name="password"
                            value={user.password}
                            onChange={this.onChange}
                            className="form-control"
                            type="password"
                            placeholder="Password"
                          />
                        </div>
                      </div>
                      <input
                        className="btn btn-default btn-block btn-lg"
                        type="submit"
                        value="Login"
                        id="homeLogin"
                      />
                      <div className="row mt-10">
                        <div className="form-group col-md-12 text-center">
                          <a href="#" onClick={this.onForgotPasswordClick}>
                            Forgot password?
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
