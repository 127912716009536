import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import Popover from 'react-popover';

import SelectionPopover from '../../../components/SelectionPopover';
import Highlighter from '../../../components/Highlighter';
import TagSuggestions from '../components/TagSuggestions';
import SimilarTagsPopup from '../components/SimilarTagsPopup';
import Comment from '../components/Comment';
import AnswerXLSModal from '../components/AnswerXLSModal';

import Phrase from '../../../api/phrase';
import CommentApi from '../../../api/comments';

import { analystActions } from '../../../redux/modules/analist';
import selection from'../../../utilities/selection';
import moment from "moment/moment";
import fileDownload from "../../../utilities/fileDownload";
import axios from "axios/index";
import Timezone from "../../../components/Timezone";

function generateHighlightedText(text, regions) {
  if(!regions) return [];

  return regions.map(chunk => {
    return '\\b' + text.substring(chunk[0], chunk[1] + 1) + '\\b'; // match whole word only
  });
};

function isSubPhrase(allPhrases, answer) {
 let isSubStr = false;
 allPhrases.forEach(p => {
   p.tag_list.forEach(t => {
     t.student_answers.forEach(a => {
       if (a.student_answer_id === answer.student_answer_id && a.SentenceNumber === answer.SentenceNumber) {
         const isSame = (answer.Start_Index === a.Start_Index && answer.End_Index === a.End_Index);
         if(!isSame && (answer.Start_Index >= a.Start_Index) && (answer.End_Index <= a.End_Index)) {
              isSubStr = true;
         }
       }
     });
   });
 });
 return isSubStr;
}

@connect(state => state.analyst.managePhraseBucket, dispatch => bindActionCreators(analystActions, dispatch))
@autobind
export default class ManagePhraseBuckets extends React.Component {
  constructor() {
    super();
    this.state = {
      assignment: null,
      question: null,
      showPopover: false,
      allPhrases: [],
      comments: [],
      createTagPhrases:   [

      ],
      showSuggestions: false,
      suggestions: [],
      selectedPhrase: null,
      selectedAnswer: null,
      showAnswerXls: true,
    };
  }

  componentWillMount() {
    const { courseId, assignmentId, questionId } = this.props.match.params;
//    this.props.getQuestions(courseId, assignmentId);

    // const assignment = assignmentFolder.data.find(d => d.assignmentId == assignmentId);
    // if (assignment) {
    //   this.setState({ assignment });
    // } else {
    //   this.props.getAssignments(courseId);
    // }
    this.props.getQuestion(courseId, assignmentId, questionId);
    this.props.getAssignment(courseId, assignmentId);
    this.props.getStudentAnswers(courseId, assignmentId, questionId);

    // get all phrases
    //TODO this.getAllPhrases(courseId, assignmentId, questionId);

  }

  componentWillReceiveProps(nextProps) {
    // if(!this.state.assignment) {
    //   const assignment = nextProps.assignmentFolder.data.find(d => d.assignmentId == nextProps.match.params.assignmentId);
    //   if (assignment) {
    //     this.setState({ assignment });
    //   }
    // }
  }

  getAllPhrases(courseId, assignmentId, questionId) {
    Phrase.getAllPhrases(courseId, assignmentId, questionId).then(
      resp => {
        this.setState({
          allPhrases: resp.phrases,
          comments: resp.comments
        });
      }
    );
  }

  onTextSelect() {
    const text = selection.getSelectionText();
    if(text && text.length)
     this.setState({ showPopover: true });
  }

  onTextDeselect() {
    this.setState({ showPopover: false });
  }

  onCreateTag(e) {
    const { answerId, node } = this.state;
    const { courseId, assignmentId, questionId } = this.props.match.params;

    const text = selection.getSelectionText();
    if(!text || text === ' ') return;
    const { allPhrases } = this.state;

    this.setState({
      showPopover: false
    });

    Phrase.createTag(courseId, assignmentId, questionId, answerId, text).then(
      resp => {
        // resp.KeyphraseText = text;
        // allPhrases.push({
        //   id: resp.keyphraseID,
        //   text,
        //   student_answers: resp.student_answers.map(a => ({
        //     Start_Index: a.keyphraseAnswers[0].Start_Index,
        //     End_Index: a.keyphraseAnswers[0].End_Index,
        //     student_answer_id: a.student_answer_id
        //   }))
        // });
        this.setState({
          createTagPhrases: resp.phrases,
          showPopover: false
        });
      }
    )
  }

  saveSimilarPhrases = async (phrase, tag) => {
    console.log(phrase, tag);
    const { courseId, assignmentId, questionId } = this.props.match.params;

/*
    const bucket = await Phrase.createBucket({
      name: phrase.keytag,
      named: true,
      sequence: 1,
      questionId
    });
    await Phrase.addPhraseToBucket(bucket.bucketId, phrase.KeyphraseID);
*/

    //const tag2 = orderBy(phrase.tag_list, 'ngram', 'desc')[0];

    await Phrase.updateTagList(courseId, assignmentId, questionId, {
      KeyTagID: phrase.KeyphraseID,
      tag_list: [
        {
          KeyphraseWithStopWordsID: tag.KeyphraseWithStopWordsID,
          KeyphraseText: tag.KeyphraseTextWithStopWords,
          is_selected: 1
        }
      ]
    });

    this.setState({
      createTagPhrases: []
    });

    this.getAllPhrases(courseId, assignmentId, questionId);
  };

  setFocusedAnswer(e, id) {
    this.setState({
      node: e.target,
      answerId: id
    });
  }

  onTagClick = async (e, tag, chunk, index) => {
    const { courseId, assignmentId, questionId } = this.props.match.params;
    this.setState({ showSuggestions: false });

    const { answerId, allPhrases } = this.state;

    let KeyphraseID = null;
    let answer = null;
    this.state.allPhrases.find(p => {
      return p.tag_list.find(t => {
        return t.student_answers.forEach(a => {
          if (a.student_answer_id === answerId) {
            if(chunk.start === a.Start_Index && chunk.end === a.End_Index + 1) {
              KeyphraseID = p.KeyphraseID;
              return true;
            }
          }
        })
      });
    });

    if (!KeyphraseID) return;


    const phrase = allPhrases.find(p => {
      answer = p.student_answers.find(a => a.student_answer_id === answerId);
      return !!answer;
    });

    const target = e.target;

    const similarTags = await Phrase.getSimilarTags(courseId, assignmentId, questionId, KeyphraseID);

    const suggestions = [];

    const existing = {};
    allPhrases.forEach(p => {
      p.tag_list.forEach(t => {
        existing[t.KeyphraseWithStopWordsID] = true;
      })
    });

    similarTags.tag_list.forEach(t => {
      t.student_answers.forEach(a => {
        if (a.SentenceNumber === answer.SentenceNumber && answer.student_answer_id === a.student_answer_id) {
          if (existing[t.KeyphraseWithStopWordsID]) {
            t.is_selected = true;
          }
          suggestions.push(t);
        }
      });
    });

    similarTags.tag_list = orderBy(suggestions, 'ngram', 'desc');

    this.setState({
      showSuggestions: target,
      selectedPhrase: phrase,
      suggestions: similarTags
    });


  };

  onSuggestionSelect(value, selected) {
    let { suggestions, allPhrases } = this.state;

    suggestions.tag_list.forEach(t => {
      if (t.KeyphraseWithStopWordsID === value) {
        t.is_selected = selected;
      }
    });

    this.setState({ suggestions });
  }

  saveTagSelections = () => {
    const { courseId, assignmentId, questionId } = this.props.match.params;
    const { selectedPhrase, suggestions } = this.state;
    this.setState({showSuggestions: false});

    const changes = [];

    suggestions.tag_list.forEach(i => {
      if (i.is_selected === true || i.is_selected === false) {
        changes.push(
          {
            KeyphraseWithStopWordsID: i.KeyphraseWithStopWordsID,
            KeyphraseText: i.KeyphraseTextWithStopWords,
            is_selected: i.is_selected
          }
        )
      }
    });

    if (!changes.length) return;

    Phrase.updateTagList(courseId, assignmentId, questionId, {
      KeyTagID: selectedPhrase.KeyphraseID,
      tag_list: changes
    }).then(
      (resp) => {
        this.getAllPhrases(courseId, assignmentId, questionId);
      },
      (err) => swal(err.message, 'error')
    );
  };

  onSaveComment = async (marks, comment, answer, phrase, commentId, commentFor) => {
    const { courseId, assignmentId, questionId } = this.props.match.params;

    const payload = {
      "id": 2560, //hardcoded as bucket is not being used
      "keyphraseID": phrase.KeyphraseID,
      "studentAnswerID": answer.studentAnswerId,
      "commentText": comment,
      "marks": marks || 0,
      "type": "text"
    };

    if(commentId) {
      if (commentFor === 'student') {
        await CommentApi.updateSingleStudentComment({
          "oldcommentID": commentId,
          "StudentAnswerId": answer.studentAnswerId,
          "commentText": comment,
          "marks": marks
        })
      } else {
       await CommentApi.updateComment(commentId, {
         commentText: comment,
         marks
       });
      }
    } else {
      await CommentApi.addComment(payload);
    }

    this.getAllPhrases(courseId, assignmentId, questionId);
  };

  onDeleteComment = async (id) => {
    const { courseId, assignmentId, questionId } = this.props.match.params;
    await CommentApi.deleteComment(id);
    this.getAllPhrases(courseId, assignmentId, questionId);
  };

  deleteTags = (tags) => {
    const { courseId, assignmentId, questionId } = this.props.match.params;
    const promises = [];
    const phrases = [];

    tags.forEach(t => {
      const id = Number(t);
      const phrase = this.state.allPhrases.find(p => p.id === id);
      phrases.push(phrase);
    });

    const grouped = groupBy(phrases, 'parentId');

    Object.keys(grouped).forEach(key => {
      if (key === 'undefined') {
        grouped['undefined'].forEach(phrase => {
          promises.push(
            Phrase.deleteTag(courseId, assignmentId, questionId, phrase.id)
          );
        })
      } else {
        const changes = [];
        grouped[key].forEach(phrase => {
          changes.push({
            KeyphraseID: phrase.id,
            KeyphraseText: phrase.text,
            is_selected: 0
          });
        });
        promises.push(
          Phrase.updateTagList(courseId, assignmentId, questionId, {
            KeyTagID: Number(key),
            tag_list: changes
          })
        );
      }
    });

    Promise.all(promises).then(
      () => this.getAllPhrases(courseId, assignmentId, questionId),
      (err) => swal(err.message, 'error')
    );
  };

  viewQuestionXls = (e) => {
    axios.get(this.props.question.downloadUrl, {
      responseType: 'blob'
    }).then(
      resp => {
        fileDownload(resp.content, resp.filename);
      }
    )
  };

  viewAnswerXls = (e, answer) => {
    e.stopPropagation();
    this.setState({ showAnswerXls: answer });
  };

  renderAnswer = (answer) => {
    const popoverProps = {
      preferPlace: 'below',
      enterExitTransitionDurationMs: 0,
      tipSize: .01,
      target: this.state.showSuggestions,
      onOuterAction: () => this.setState({showSuggestions: false}),
      body: <TagSuggestions
        tags={this.state.suggestions.tag_list}
        onSelect={this.onSuggestionSelect}
        onSave={this.saveTagSelections}
      />
    };

    const regions = [];
    this.state.allPhrases.forEach(p => {
      p.tag_list.forEach(t => {
        t.student_answers.forEach(a => {
          if (a.student_answer_id === answer.studentAnswerId) {
            regions.push([a.Start_Index, a.End_Index]);
          }
        })
      });
    });

    return (
      <div className="answer" key={answer.studentAnswerId} onClick={() => this.setState({ selectedAnswer: answer })}>
        <p className="answer__title">Student ID: {answer.studentId}</p>
        <div className="answer__answer">
          <p onClick={(e) => this.setFocusedAnswer(e, answer.studentAnswerId)}>
            <Popover {...popoverProps} isOpen={this.state.showSuggestions && this.state.answerId === answer.studentAnswerId}>
              <Highlighter
                highlightClassName='answer__note'
                searchWords={generateHighlightedText(answer.shortAnswerText, regions)}
                onClick={this.onTagClick}
                autoEscape={false}
                textToHighlight={answer.shortAnswerText}
              />
            </Popover>
          </p>
          <hr />
          <div>
            { answer.downloadUrl && <button className="btn btn-info" onClick={(e) => this.viewAnswerXls(e, answer)}>View answer</button> }
          </div>
        </div>
      </div>
    )
  };

  renderStudentAnswerDashboard = answer => {
    if (answer) {
      const added = {};
      const phrases = [];

      this.state.allPhrases.forEach(p => {
        p.tag_list.forEach(t => {
          t.student_answers.forEach(a => {
            if (a.student_answer_id === answer.studentAnswerId) {
              p.tag_list.forEach(tt => {
                // remove duplicate
                if(added[t.KeyphraseWithStopWordsID]) return;
                added[t.KeyphraseWithStopWordsID] = true;

                // remove substring duplicate
                if(isSubPhrase(this.state.allPhrases, a)) return;


                const comment = this.state.comments.find(c => {
                  return c.student_answers.find(s =>
                    (s.student_answer_id == answer.studentAnswerId) &&
                    (s.KeyphraseWithStopWordsID == tt.KeyphraseWithStopWordsID));
                });
                phrases.push(
                  <li key={t.KeyphraseWithStopWordsID}>
                    <Comment
                      answer={answer}
                      comment={comment}
                      tag={t}
                      phrase={p}
                      onSave={this.onSaveComment}
                      onDelete={this.onDeleteComment}
                    >
                      <span>{t.KeyphraseTextWithStopWords}</span>
                    </Comment>
                  </li>
                )
              });
            }
          });
        });
      });
      return (
        <ul className="list-unstyled">
          {phrases}
        </ul>
      )
    }

    return null;
  };

  render() {
    const { selectedAnswer, createTagPhrases } = this.state;
    const { assignment, question } = this.props;
    const { courseId, assignmentId, questionId } = this.props.match.params;

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix" style={{ height: '100vh', overflow: 'hidden'}}>
            <div className="row" style={{marginBottom: 20}}>
              <div className="col-md-6">
                <a onClick={() => this.props.history.goBack()}>
                  <i className="glyphicon glyphicon-arrow-left"/>
                  Back to assignment
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-7">
                <div className="row">
                  <div className="col-sm-7">
                    <h4>
                      <strong><i className="glyphicon glyphicon-file"/> {assignment && assignment.title}</strong>
                    </h4>
                  </div>
                  <div className="col-sm-5">
                    <h4 className="text-muted">Due on: {assignment && <Timezone timestamp={assignment.dueDateAndTime} />}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="grade-panel-header">
                  <div className="grade-panel-content">
                    <div className="row">
                      <div className="col-sm-2">Question {question && question.questionSequence}</div>
                      <div className="col-sm-8">
                        {question && question.questionText}
                        &nbsp; {question && question.downloadUrl && (<button className="btn btn-sm btn-default" onClick={this.viewQuestionXls}>
                          <i className="fa fa-download" />
                        </button>)}
                        </div>
                      <div className="col-sm-2">Marks: {question && question.maxScore}</div>
                    </div>
                  </div>
                  <hr />
                  <div className="grade-panel-content">
                    <div className="row">
                      <div className="col-sm-2">
                        <p>Student ID</p>
                        <label>{selectedAnswer && selectedAnswer.studentId}</label>
                      </div>
                      <div className="col-sm-10">
                        { this.renderStudentAnswerDashboard(selectedAnswer)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ overflow: 'auto', height: '100%', paddingTop: 15 }}>
              <div className="col-md-12">
                <div>
                  <div className="row" style={{position: 'relative', marginBottom: 330}}>
                    <div className="col-md-12" data-selectable>
                      {this.props.answers.map(this.renderAnswer)}
                    </div>
                    <SelectionPopover
                      topOffset={35}
                      showPopover={this.state.showPopover}
                      onSelect={this.onTextSelect}
                      onDeselect={this.onTextDeselect}
                    >
                      <button className="btn btn-sm btn-primary" onClick={this.onCreateTag}>Create Tag</button>
                    </SelectionPopover>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        { createTagPhrases.length ? <SimilarTagsPopup phrases={createTagPhrases} onSave={this.saveSimilarPhrases} /> : null }
        { this.state.showAnswerXls && this.props.answers.length &&
          <AnswerXLSModal
            questionId={questionId}
            assignmentId={assignmentId}
            courseId={courseId}
            answer={this.state.showAnswerXls}
            answers={this.props.answers}
            onClose={() => this.props.history.goBack()} />}
      </div>
    )
  }
}
