import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { push } from "react-router-redux";

import MainRoutes from "./Routes";
import "@trendmicro/react-toggle-switch/dist/react-toggle-switch.css";
import "./app.css";
import "react-datepicker/dist/react-datepicker.css";

import { sessionActions } from "./redux/modules/session";
import auth from "./api/auth";

@connect((state) => ({
  isLoggedIn: state.session.isLoggedIn,
  user: state.session.user,
  refreshToken: state.session.refreshToken,
}))
class App extends React.Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.dispatch(sessionActions.getCurrentuser());
    }
  }

  //
  // componentWillMount() {
  //   if (this.props.isLoggedIn) {
  //     const role = this.props.user.roles[0];
  //     const requestPath = this.props.location.pathname;
  //     if (role === 'student' && !requestPath.startsWith('/student')) {
  //       this.props.dispatch(push('/student'));
  //     } else if (role === 'instructor' && !requestPath.startsWith('/teacher')) {
  //       this.props.dispatch(push('/teacher'));
  //     } else if(role === 'analyst' && !requestPath.startsWith('/analyst')) {
  //       this.props.dispatch(push('/analyst'));
  //     }
  //     auth.refresh(this.props.refreshToken)
  //       .then(
  //         resp => {
  //           //console.log(resp)
  //           //this.props.dispatch(sessionActions.storeSession(resp));
  //         },
  //         (err) => {
  //           console.log(err);
  //           this.props.dispatch(push('/'));
  //         }
  //       );
  //   } else {
  //     this.props.dispatch(push('/'));
  //   }
  // }

  render() {
    return <MainRoutes />;
  }
}

export default withRouter(App);
