import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import drop from 'lodash/drop';
import uniq from 'lodash/uniq';
import Cell from './Cell';

export default class PivotSource extends React.Component {
  static propTypes = {
    blocks: PropTypes.array,
    onGetFormulas: PropTypes.func,
    onCellClick: PropTypes.func,
  };

  state = {
    data: []
  };

  componentWillMount() {
    const { blocks } = this.props;
    this.parseData(blocks);
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.blocks !== this.props.blocks) {
      this.parseData(nextProps.blocks);
    }
  }

  parseData(blocks) {
    const firstBlock = blocks[0];
    const blockCellRanges = uniq(drop(blocks, 1).map(i => i.blockCellRange));
    const data = [];

    blockCellRanges.forEach(range => {
      const rows = blocks.filter(b => b.blockCellRange === range);
      data.push({
        blockCellRange: range,
        a: `${firstBlock.blockFormula} : ${range}`,
        b: rows.map(r => r.blockFormula).join(' : ')
      });
    });
    this.setState({ data });
  }

  onBlockRangeClick = (range) => {
    this.props.onCellClick(range, true);
  };

  render() {
    const { data } = this.state;

    const rows = [];
    data.forEach(d => {
      rows.push(
        <tr key={d.a}>
          <td>
            <Cell range={d.blockCellRange} onCellClick={this.onBlockRangeClick}>
              {d.a}
            </Cell>
          </td>
          <td>
            <span>{d.b}</span>
          </td>
        </tr>
      )
    });

    return (
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>A</th>
              <th>B</th>
            </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </table>
      </div>
    );
  }
}