import React, { useState, useEffect } from "react";
import { Drawer, Spin } from "antd";
import max from "lodash/maxBy";
import moment from 'moment';
import Course from "../../../api/course";

const StudentDashboard = ({ course, student, onClose, downloadFile }) => {
  const [details, setDetails] = useState();
  const [showSubmissions, setShowSubmissions] = useState(false);

  useEffect(() => {
    Course.getStudentSubmissions(course.courseId, student.userId).then(
      setDetails
    );
  }, []);

  const onSubmissionsClick = (assignment) => (e) => {
    e.preventDefault();
    setShowSubmissions(assignment);
  };

  const handleDownload = (FileID) => (e) => {
    e.preventDefault();
    downloadFile(FileID);
  };

  return (
    <Drawer
      visible
      onClose={onClose}
      placement={"right"}
      width={600}
      title={`${student.firstName} ${student.lastName} - ${course.title}`}
    >
      {details ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Assignment</th>
              <th></th>
              <th className="text-right">Score</th>
            </tr>
          </thead>
          <tbody>
            {details.assignments.map((s) => (
              <tr>
                <td>{s.title}</td>
                <td>
                  {s.studentAnswers?.length ? (
                    <a href="#" onClick={onSubmissionsClick(s)}>submissions</a>
                  ) : null}
                </td>
                <td className="text-right">
                  {max(s.studentAnswers, "Marks")?.Marks}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Spin spinning />
      )}
        { showSubmissions && (
            <Drawer title={showSubmissions.title} visible width={500} onClose={() => setShowSubmissions(false)}>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Date</th>
                        <th className="text-right">Marks</th>
                        <th>Grader's Comments</th>
                        <th>Submitted file</th>
                        <th>Graded file</th>
                    </tr>
                    </thead>
                    <tbody>
                    {showSubmissions.studentAnswers.map((s, idx) => (
                        <tr>
                            <td>{idx+1}</td>
                            <td>{moment(s.SubmissionTime).format('L')}</td>
                            <td className="text-right">{s.Marks}</td>
                            <td>{s.GraderComment}</td>
                            <td><a href="#" onClick={handleDownload(s.FileID)}>download</a></td>
                            <td>
                              {s.was_comment_processed && (
                                  <a href="#" onClick={handleDownload(s.GradedFileID)}>download</a>
                              )}
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </Drawer>
        )}
    </Drawer>
  );
};

export default StudentDashboard;
