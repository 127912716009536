import React from "react";
import { autobind } from "core-decorators";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { message, Modal, Select, Button, Menu, Dropdown, Icon } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import EditQuestionModal from "../components/EditQuestionModal";
import CopyModal from "../components/CopyModal";

import Course from "../../../api/course";
import assignmnentApi from "../../../api/assignment";
import commentAPI from "../../../api/comments";
import { teacherActions } from "../../../redux/modules/teacher";
import Timezone from "../../../components/Timezone";
import UploadFiles from "../components/UploadFiles";

@connect(
  (state) => state.teacher,
  (dispatch) => bindActionCreators(teacherActions, dispatch)
)
@autobind
export default class AssignmentQuestions extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showCopyModal: false,
      showUploadModal: false,
      assignment: null,
      selectedQuestion: null,
      course: null,
      page: 0,
    };
  }

  componentWillMount() {
    const { courseId, assignmentId } = this.props.match.params;
    const { assignmentFolder } = this.props;
    this.props.getQuestions(courseId, assignmentId);

    const assignment = assignmentFolder.data.find(
      (d) => d.assignmentId == assignmentId
    );
    if (assignment) {
      this.setState({ assignment });
    } else {
      this.props.getAssignments(courseId);
    }

    Course.get(courseId).then((resp) => this.setState({ course: resp }));
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.assignment) {
      const assignment = nextProps.assignmentFolder.data.find(
        (d) => d.assignmentId == nextProps.match.params.assignmentId
      );
      if (assignment) {
        this.setState({ assignment });
      }
    }
  }

  onDelete(q) {
    const { courseId, assignmentId } = this.props.match.params;
    swal({
      title: "Delete the question?",
      text: q.questionText,
      buttons: {
        cancel: true,
        confirm: true,
      },
      icon: "warning",
    }).then((value) => {
      if (value) {
        this.props.deleteQuestion(courseId, assignmentId, q.questionId);
        //  swal('Question deleted successfully.');
      }
    });
  }

  onUpload(q) {
    this.setState({ showUploadModal: true, selectedQuestion: q });
  }

  hideUploadModal = () => {
    this.setState({ showUploadModal: false, selectedQuestion: null });
  };

  handleUpload = async (files) => {
    const { selectedQuestion } = this.state;
    const { courseId, assignmentId } = this.props.match.params;
    const questionId = selectedQuestion.questionId;
    let modelAnswerFileId = null;
    let ZIPAnswerFileId = null;
    let ZIPGradedFileId = null;

    if (files.modelAnswerFile) {
      const fileResp = await assignmnentApi.uploadFile(files.modelAnswerFile);
      modelAnswerFileId = fileResp.fileId;
    }
    if (files.ZIPAnswerFile) {
      const fileResp = await assignmnentApi.uploadFile(files.ZIPAnswerFile);
      ZIPAnswerFileId = fileResp.fileId;
    }

    if (files.ZIPGradeFile) {
      const fileResp = await assignmnentApi.uploadFile(files.ZIPGradeFile);
      ZIPGradedFileId = fileResp.fileId;
    }

    if (modelAnswerFileId) {
      await assignmnentApi.uploadModelAnswer(
        courseId,
        assignmentId,
        questionId,
        modelAnswerFileId
      );
    }
    if (ZIPAnswerFileId) {
      await assignmnentApi.uploadZIPAnswer(
        courseId,
        assignmentId,
        questionId,
        ZIPAnswerFileId
      );
    }
    if (ZIPGradedFileId) {
      await assignmnentApi.uploadZipGradedFile(
        courseId,
        assignmentId,
        questionId,
        ZIPGradedFileId
      );
    }
  };

  onDownload = (q) => {
    assignmnentApi.downloadQuestion(q.downloadUrl);
    message.success("Downloading...");
  };

  downloadAnswerFiles = (q) => {
    const { courseId, assignmentId } = this.props.match.params;
    assignmnentApi
      .getTotalPages(courseId, assignmentId, q.questionId)
      .then((resp) => {
        Modal.info({
          title: "Select page",
          content: this.renderPagesList(resp.totalPages, () => {
            message.success("Downloading...");
            assignmnentApi.downloadAnswerFiles(
              courseId,
              assignmentId,
              q.questionId,
              this.state.page
            );
          }),
          onOk: () => {
            this.setState({
              page: 0,
            });
          },
          okText: "Close",
          okButtonProps: {
            type: "default",
          },
        });
      });
  };

  downloadGradedFiles = (q) => {
    const { courseId, assignmentId } = this.props.match.params;
    assignmnentApi
      .getTotalPages(courseId, assignmentId, q.questionId)
      .then((resp) => {
        Modal.info({
          title: "Select page",
          content: this.renderPagesList(resp.totalPages, () => {
            message.success("Downloading...");
            assignmnentApi.downloadAnswerFiles(
              courseId,
              assignmentId,
              q.questionId,
              this.state.page
            );
          }),
          onOk: () => {
            this.setState({
              page: 0,
            });
          },
          okText: "Close",
          okButtonProps: {
            type: "default",
          },
        });
      });
  };

  downloadMarks = (d) => {
    const { assignmentId } = this.props.match.params;
    message.success("Downloading...");
    assignmnentApi.downloadMarksFile(assignmentId);
  };

  async onSaveQuestion(data) {
    const { selectedQuestion } = this.state;
    const { courseId, assignmentId } = this.props.match.params;

    if (data.file) {
      const fileResp = await assignmnentApi.uploadFile(data.file);
      data.fileId = fileResp.fileId;
    }
    delete data.file;

    if (selectedQuestion) {
      delete data.questionType;
      this.props.updateQuestion(
        courseId,
        assignmentId,
        selectedQuestion.questionId,
        data
      );
    } else {
      await this.props.createQuestion(courseId, assignmentId, {
        ...data,
        questionSequence: this.props.assignmentQuestions.data.length + 1,
      });
    }

    this.setState({
      showModal: false,
      selectedQuestion: null,
    });
  }

  getAssignmentLink() {
    const { showCopyModal } = this.state;
    if (showCopyModal === "gradeview") {
      return (
        window.location.origin +
        `/student/assignments/${this.props.match.params.assignmentId}/gradeview`
      );
    }
    return this.state.course && this.state.course.courseLink;
  }

  processComments = () => {
    const { courseId, assignmentId } = this.props.match.params;
    commentAPI.processComments(courseId, assignmentId).then(
      () => swal("Done", "Comments successfully Processed.", "success"),
      () => swal("Error", "Error in processing comments.", "error")
    );
  };

  onPageSelect = (value) => {
    this.setState({
      page: value,
    });
  };

  onCopy = () => {
    message.success("Link copied");
  };

  renderPagesList = (total, onDownload) => {
    const options = [];
    for (let i = 0; i < total; i++) {
      options.push(
        <Select.Option value={i} key={i}>
          {i + 1}
        </Select.Option>
      );
    }
    return (
      <div>
        <Select
          value={this.state.page}
          onChange={this.onPageSelect}
          placeholder="Select Page"
        >
          {options}
        </Select>{" "}
        <Button type="primary" onClick={onDownload}>
          Download
        </Button>
      </div>
    );
  };

  getStudentLink = (type) => () => {};

  render() {
    const {
      showModal,
      showCopyModal,
      selectedQuestion,
      assignment,
      showUploadModal,
    } = this.state;
    const { assignmentQuestions, match } = this.props;
    const { courseId, assignmentId } = this.props.match.params;

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <div className="row">
              <div className="col-md-6">
                <a onClick={() => this.props.history.goBack()}>
                  <i className="glyphicon glyphicon-arrow-left" />
                  Back to assignment folders
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-7">
                <h1 className="panel-caption">
                  <i className="glyphicon glyphicon-file" />{" "}
                  {assignment && assignment.title}
                </h1>
                <p>
                  Due on:{" "}
                  {assignment && (
                    <Timezone timestamp={assignment.dueDateAndTime} />
                  )}
                </p>
              </div>
              <div className="col-sm-5 assignment-info">
                <button
                  className="mt-10 btn btn-primary"
                  onClick={this.processComments}
                >
                  Process comments
                </button>
                &nbsp;&nbsp;
                <button
                  className="mt-10 btn btn-primary"
                  onClick={() => this.setState({ showModal: true })}
                >
                  <i className="glyphicon glyphicon-plus" />
                  Add New Question
                </button>
              </div>
            </div>

            <table className="cd-table table table-striped">
              <thead>
                <tr>
                  <th>Q#</th>
                  <th>Question</th>
                  <th>Marks</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {assignmentQuestions.data.map((d) => (
                  <tr key={d.questionId}>
                    <td>Q{d.questionSequence}</td>
                    <td>
                      <p style={{ marginBottom: 5, fontSize: 16 }}>
                        {d.questionText}{" "}
                        <small>
                          <i
                            title="Download question file"
                            onClick={() => this.onDownload(d)}
                            className="glyphicon glyphicon-download clickable"
                          />
                        </small>
                      </p>
                      <div>
                        <a onClick={() => this.downloadAnswerFiles(d)}>
                          <i className="glyphicon glyphicon-download" />{" "}
                          Download answer file
                        </a>{" "}
                        <a onClick={() => this.downloadGradedFiles(d)}>
                          <i className="glyphicon glyphicon-download" />{" "}
                          Download graded file
                        </a>{" "}
                        <a onClick={() => this.downloadMarks(d)}>
                          <i className="glyphicon glyphicon-download" />{" "}
                          Download marks list
                        </a>{" "}
                        <Dropdown
                          overlay={
                            <Menu>
                              <CopyToClipboard
                                text={`${window.location.origin}/student/courses/${courseId}/assignments/${assignmentId}/questions/${d.questionId}/download-question`}
                                onCopy={this.onCopy}
                              >
                                <Menu.Item>
                                  Download Question file Link
                                </Menu.Item>
                              </CopyToClipboard>
                              <CopyToClipboard
                                text={`${window.location.origin}/student/courses/${courseId}/assignments/${assignmentId}/questions/${d.questionId}/answer`}
                                onCopy={this.onCopy}
                              >
                                <Menu.Item>Upload Answer file Link</Menu.Item>
                              </CopyToClipboard>
                              <CopyToClipboard
                                text={`${window.location.origin}/student/courses/${courseId}/assignments/${assignmentId}/questions/${d.questionId}/download-answer`}
                                onCopy={this.onCopy}
                              >
                                <Menu.Item>
                                  Download answer or graded file Link
                                </Menu.Item>
                              </CopyToClipboard>
                            </Menu>
                          }
                        >
                          <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                          >
                            <Icon type="link" /> Get Student Links
                          </a>
                        </Dropdown>
                      </div>
                    </td>
                    <td>
                      <span className="badge badge-marks">{d.maxScore}</span>
                    </td>
                    <td>
                      <button
                        title="Edit question"
                        className="btn btn-primary"
                        onClick={() =>
                          this.setState({
                            showModal: true,
                            selectedQuestion: d,
                          })
                        }
                      >
                        <i className="glyphicon glyphicon-pencil" />
                      </button>{" "}
                      <button
                        title="Delete question"
                        className="btn btn-danger"
                        onClick={() => this.onDelete(d)}
                      >
                        <i className="glyphicon glyphicon-trash" />
                      </button>{" "}
                      <button
                        title="Upload Model Answer"
                        className="btn btn-success"
                        onClick={() => this.onUpload(d)}
                      >
                        <i className="glyphicon glyphicon-upload" />
                      </button>{" "}
                      <Link
                        to={`/teacher/courses/${courseId}/assignments/${assignmentId}/questions/${d.questionId}/phrases`}
                      >
                        <button className="btn btn-info">Grade Now</button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {showModal ? (
          <EditQuestionModal
            onSubmit={this.onSaveQuestion}
            question={selectedQuestion}
            onClose={() =>
              this.setState({ showModal: false, selectedQuestion: null })
            }
          />
        ) : null}
        {showCopyModal ? (
          <CopyModal
            type={showCopyModal}
            link={this.getAssignmentLink()}
            onClose={() => this.setState({ showCopyModal: false })}
          />
        ) : null}
        {showUploadModal ? (
          <UploadFiles
            question={selectedQuestion}
            onClose={this.hideUploadModal}
            onSubmit={this.handleUpload}
          />
        ) : null}
      </div>
    );
  }
}
