import React from "react";
import { connect } from "react-redux";
import Login from "./Login";
import { Alert } from "react-bootstrap";

@connect((state) => ({
  session: state.session,
  student: state.student,
}))
class WithLogin extends React.Component {
  onLoginSuccess = () => {

  };

  render() {
    const {
      session: { isLoggedIn },
      student: { enrollment },
      match,
      children,
      skipEnrollment
    } = this.props;
    if (!isLoggedIn) {
      return (
        <Login
          courseId={skipEnrollment ? null : match.params.courseId}
          onLoginSuccess={this.onLoginSuccess}
        />
      );
    } else if (enrollment.error) {
      return <Alert bsStyle="danger">{enrollment.error}</Alert>;
    }
    return children;
  }
}

export default WithLogin;
