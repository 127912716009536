import React from "react";
import { Table } from 'antd';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

const GradedComments = ({ downloadFile, submissions }) => {
  const rows = [];
  submissions.forEach((s, idx) => {
    const row = {
        key: s.userID,
        name: `${s.firstName} ${s.lastName}`,
        answers: s.studentAnswers.filter(i => i.GraderComment)
    };
    if (row.answers.length) {
        rows.push(row);
    }
  });

  const handleDownload = (FileID) => (e) => {
    e.preventDefault();
    downloadFile(FileID);
  };

  const expandedRowRender = (row) => {
    return (
        <table className="table table-striped">
          <thead>
          <tr>
            <th>Submission Time</th>
            <th>Grader's Comment</th>
            <th>Submitted File</th>
            <th>Graded File</th>
          </tr>
          </thead>
          <tbody>
          {row.answers.map((s, idx) => (
              <tr>
                <td>{moment(s.SubmissionTime).format('L')}</td>
                <td>{s.GraderComment}</td>
                <td>
                  <a href="#" onClick={handleDownload(s.FileID)}>
                    download
                  </a>
                </td>
                <td>
                    {s.was_comment_processed && (
                        <a href="#" onClick={handleDownload(s.GradedFileID)}>
                            download
                        </a>
                    )}
                </td>
              </tr>
          ))}
          </tbody>
        </table>
    )
  }

  const columns = [
    { title: 'Sr. No.', render: (t, r, i) => i +1},
    { title: 'Student Name', dataIndex: 'name'},
  ];


  return (
      <Table
          rowKey="key"
          columns={columns}
          dataSource={sortBy(rows, 'name')}
          expandedRowRender={expandedRowRender}
          pagination={false}
      />
  )
};

export default GradedComments;
