import axios from 'axios';
import get from 'lodash/get';
import set from 'lodash/set';
import qs from 'qs';
import { push } from 'react-router-redux';
import { sessionActions } from '../redux/modules/session';


axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' });


const addAuthorizationToHeaders = (state, config) => {
  const userToken = get(state, 'session.accessToken', null);
  if (!userToken) {
    return config;
  }

  set(config, 'headers.authorization', `Bearer ${userToken}`);

  return config;
};

const authorizationProvider = (store) => {
  axios.interceptors.request.use((config) => {
    const state = store.getState(store);
    return addAuthorizationToHeaders(state, config);
  });

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    const contentHeader = response.headers['content-disposition'];
    if(contentHeader) {
     const parts = contentHeader.split('=');
     if(parts[1]) {
       return {
         filename: parts[1],
         content: response.data
       }
     }
    }
    return response.data;
  }, function (error) {
    if(!error.response) {
      return Promise.reject({ msg: error.message });
    } else if(error.response.status === 401 && !error.config.url.includes('/auth/login')) {
      store.dispatch(sessionActions.logout());
      // store.dispatch(push('/'));
    }
    return Promise.reject(error.response.data);
  });
};

export default authorizationProvider;
