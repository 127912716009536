import axios from 'axios';

const Question = {
  list(courseId, assignmentId) {
    return axios.get(`courses/${courseId}/assignments/${assignmentId}/questions`);
  },

  get(courseId, assignmentId, questionId) {
    return axios.get(`courses/${courseId}/assignments/${assignmentId}/questions/${questionId}`);
  },

  create(courseId, assignmentId, data) {
    return axios.post(`courses/${courseId}/assignments/${assignmentId}/questions`, data);
  },

  update(courseId, assignmentId, questionId, data) {
    return axios.patch(`courses/${courseId}/assignments/${assignmentId}/questions/${questionId}`, data);
  },

  delete(courseId, assignmentId, questionId) {
    return axios.delete(`courses/${courseId}/assignments/${assignmentId}/questions/${questionId}`);
  },

  getQuestionXls(courseId, assignmentId, questionId) {
    return axios.get(`questionExcelData/courseId/${courseId}/assignmentId/${assignmentId}/questionId/${questionId}`)
  },

  getAnswerXls(courseId, assignmentId, questionId, studentId) {
    return axios.get(`excelBlocks/courseId/${courseId}/assignmentId/${assignmentId}/questionId/${questionId}/studentId/${studentId}`)
  },

  getFormulas(courseId, assignmentId, questionId, range, worksheetName) {
    return axios.get(`excelBlocks/courseId/${courseId}/assignmentId/${assignmentId}/questionId/${questionId}/range/${range}`, {
      params: { worksheetName }
    });
  },
};

export default Question;