import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AssignmentFolders from './containers/AssignmentFolders';
import AssignmentQuestions from './containers/AssignmentQuestions';
import ManagePhraseBuckets from './containers/ManagePhraseBuckets';
import Courses from './containers/Courses';

export default () =>
  <Switch>
    <Redirect exact path="/analyst" to="/analyst/assignments" />
    <Route exact path="/analyst/assignments" component={AssignmentFolders} />
    <Route exact path="/analyst/courses/:courseId/assignments/:assignmentId/questions" component={AssignmentQuestions} />
    <Route exact path="/analyst/courses/:courseId/assignments/:assignmentId/questions/:questionId/phrases" component={ManagePhraseBuckets} />
  </Switch>