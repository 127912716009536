import React from 'react';
import styled from 'styled-components';
import { Row, Col, Panel } from 'react-bootstrap';
import Helmet from "react-helmet";
import ChangePassword from "./components/ChangePassword";

const Root = styled.div`


`;

const Settings = ({ }) => {
  return (
    <Root>
      <Helmet title="Settings" />
      <h1>Settings</h1>
      <Row>
        <Col md={6}>
          <Panel>
            <h4>Change Password</h4>
            <ChangePassword />
          </Panel>
        </Col>
      </Row>
    </Root>
  )
}

export default Settings;
