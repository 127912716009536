import React from 'react';
import { Modal, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import axios from 'axios';
import assignmnentApi from '../../../api/assignment';
import fileDownload from '../../../utilities/fileDownload';
import swal from "sweetalert";

export default class AnswerModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    question: PropTypes.object,
    answer: PropTypes.object,
    assignment: PropTypes.object,
    onSubmit: PropTypes.func
  };

  state = {
    answer: '',
    file: null,
    submitting: false,
  };

  componentWillMount() {
    if (this.props.answer) {
      this.setState({ answer: this.props.answer.shortAnswerText });
    }
  }

  onFileSelect = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (!file.name.toLowerCase().endsWith(".xlsx")) {
        swal(
          "Invalid File",
          "You should Turn in only .xlsx file. You should use Office 365 for this course. However, If you are using Apple Numbers, then before turning in, export the .numbers file as .xlsx without the summary sheet",
          "error"
        );
      } else {
        this.setState({
          file,
        });
      }
    }
  };

  onSubmit = () => {
    this.setState({ submitting: true });
    this.props
      .onSubmit(this.props.question, this.state.answer, this.state.file)
      .then(
        () => {
          this.setState({ submitting: false });
          this.props.onClose();
        },
        (e) => {
          swal("Error", e.msg, "error");
          this.setState({ submitting: false });
        }
      );
  };

  downloadQuestion = () => {
    const { question, assignment } = this.props;
    assignmnentApi.downloadQuestionFile(assignment.courseId, assignment.assignmentId, question.questionId);
  };

  downloadAnswer = () => {
    const { answer } = this.props;
    axios.get(answer.downloadUrl, {
      responseType: 'blob'
    }).then(
      resp => fileDownload(resp.content, resp.filename)
    );
  };

  render() {
    const { onClose, question, answer, assignment } = this.props;

    // const canAnswer = moment.utc(assignment.dueDateAndTime).isAfter(moment.utc());
    const hasAnswerFile = answer && answer.downloadUrl;
    return (
      <Modal show onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{assignment.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="marks-obtained text-right">
            Marks Obtained:
            <span className="badge badge-marks">
              — / {question.maxScore}
            </span>
          </p>
          <p className="wrap">Q{question.questionSequence}: {question.questionText}</p>
          <div className="form-group">
            <textarea rows={10} className="form-control" value={this.state.answer} onChange={e => this.setState({ answer: e.target.value })} />
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-xs-6">
                { question && question.downloadUrl && <button onClick={this.downloadQuestion}>Download Question file</button>}
                { hasAnswerFile && <button onClick={this.downloadAnswer}>{answer && answer.was_comment_processed ? 'Download graded file' : 'Download Answer file'}</button> }
              </div>
              <div className="col-xs-6">
                <label>Upload file {hasAnswerFile && '(has existing attachment)'}</label>
                <input
                  accept=".xlsx"
                  type="file" className="form-control" onChange={this.onFileSelect} />
              </div>
            </div>
          </div>
          <button disabled={this.state.submitting} className="btn btn-primary" onClick={this.onSubmit}>Save</button>
        </Modal.Body>
      </Modal>
    )
  }
}
