import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Icon} from "antd";

export default class Th extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
  };

  state = {
    expanded: false
  };

  toggleExpand = () => {
    this.props.onClick(this.props.id);
  };

  render() {
    const { id } = this.props;
    return (
      <th data-id={id} className={cx({ expanded: this.state.expanded})}><span>{id}</span> <Icon onClick={this.toggleExpand} type="plus" theme="outlined" /></th>
    )
  }
}