import axios from 'axios';

const Auth = {
  login(userName, password, cohort, accessCode) {
    return axios.post('auth/login', {
      email: userName.toLowerCase(),
      password,
      cohort,
      accessCode
    });
  },

  getCurrentUser() {
    return axios.get('/users/current');
  },

  studentLogin(userName, password, course) {
    const params = {};
    if (course) {
      params['add-course-id'] = course;
    }
    return axios.post(`auth/login`, {
      email: userName.toLowerCase(),
      password
    }, {
      params
    });
  },

  register(payload) {
    payload.email = payload.email.toLowerCase();
    return axios.post('auth/register', payload);
  },

  studentRegister(payload, course) {
    payload.email = payload.email.toLowerCase();
    const params = {};
    if (course) {
      params['add-course-id'] = course;
    }
    return axios.post(`auth/register`, payload, {
      params
    });
  },

  sendResetPasswordLink(email) {
    return axios.post('auth/reset_password_link', { email: email.toLowerCase() });
  },

  resetPasswordWithCode(code, password) {
    return axios.post(`auth/reset_password?code=${code}`, { password });
  },

  refresh(token) {
    return axios.post('auth/refresh', { token }).then(
      resp => {
        if (!resp.auth) {
          throw new Error('Session expired');
        } else {
          return resp.auth;
        }
      }
    )
  },

  activateAccount(code) {
    return axios.post(`auth/activate?code=${code}`).then(
      resp => resp.data
    );
  },

  registerWithGoogle(role, accessToken) {
    return axios.post(`auth/registerGoogleUser`, {
      accessToken,
      roles: [role]
    });
  },

  loginWithGoogle(accessToken) {
    return axios.post(`auth/googleLogin`, { accessToken });
  },

  studentRegisterWithGoogle(courseId, accessToken) {
    return axios.post(`auth/registerGoogleUser?add-course-id=${courseId}`, {
      accessToken,
      roles: ['student']
    });
  },

  studentLoginWithGoogle(accessToken, courseId) {
    return axios.post(`auth/googleLogin?add-course-id=${courseId}`, { accessToken });
  },

  changePassword(payload) {
    return axios.post('users/resetSelfPassword', payload);
  }
};

export default Auth;
