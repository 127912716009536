import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import drop from 'lodash/drop';
import groupBy from 'lodash/groupBy';
import Cell from './Cell';

export default class PivotSource extends React.Component {
  static propTypes = {
    blocks: PropTypes.array,
    onGetFormulas: PropTypes.func,
    onCellClick: PropTypes.func,
  };

  state = {
    data: []
  };

  componentWillMount() {
    const { blocks } = this.props;
    this.parseData(blocks);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.blocks !== this.props.blocks) {
      this.parseData(nextProps.blocks);
    }
  }

  parseData(blocks) {
    let data = [];
    let part = [];
    blocks.forEach((block, idx) => {
      if (block.blockCellRange === 'CFSource') {
        if (idx > 0) {
          data.push(part);
          part = [];
          part.push(block);
        } else {
          part.push(block);
        }
      } else if (blocks.length - 1 === idx) {
        part.push(block);
        data.push(part);
      } else {
        part.push(block);
      }
    });

    const parsed = [];
    data.forEach(group => {
      const first = group[0];
      const elements = drop(group, 1);
      const elementGrouped = groupBy(elements, 'blockCellRange');
      Object.keys(elementGrouped).forEach(key => {
        const groupData = elementGrouped[key];
        parsed.push({
          blockCellRange: key,
          a: `${first.blockFormula} : ${key}`,
          b: groupData.map(r => r.blockFormula).join(' : ')
        });
      });
    });
    this.setState({ data: parsed });
  }

  onBlockRangeClick = (range) => {
    this.props.onCellClick(range, true);
  };

  render() {
    const { data } = this.state;

    const rows = [];
    data.forEach(d => {
      rows.push(
        <tr key={d.a}>
          <td>
            <Cell range={d.blockCellRange} onCellClick={this.onBlockRangeClick}>
              {d.a}
            </Cell>
          </td>
          <td>
            <span>{d.b}</span>
          </td>
        </tr>
      )
    });

    return (
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>A</th>
              <th>B</th>
            </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </table>
      </div>
    );
  }
}