import React from "react";
import { connect } from 'react-redux';
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Upgrade from "../../../api/upgrade";
import swal from "sweetalert";
import { getCurrentuser } from '../../../redux/modules/session/actions';
import { PRODUCT_LIST_URL } from "../../../constants";

@connect(s => s.session)
export default class UpgradeModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
  };

  state = {
    code: "",
    submitting: false,
  };

  onSubmit = () => {
    this.setState({ submitting: true });
    Upgrade.upgrade(this.props.courseId, this.props.user.userId, this.state.code).then(
      () => {
        this.setState({ submitting: false });
        swal('Success!', 'Upgrade successful.', 'success');
        this.props.dispatch(getCurrentuser());
        this.props.onClose();
      },
      (e) => {
        swal("Error", e.msg, "error");
        this.setState({ submitting: false });
      }
    );
  };

  render() {
    const { onClose } = this.props;

    return (
      <Modal show onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upgrade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-inline">
            <div className="form-group">
              <input
                placeholder="Digital access code"
                className="form-control"
                value={this.state.answer}
                onChange={(e) => this.setState({ code: e.target.value })}
              />
            </div>
            {' '}
            <button
              disabled={this.state.submitting}
              className="btn btn-primary"
              onClick={this.onSubmit}
            >
              Upgrade
            </button>
          </div>
          <div className="mt-10 text-center">
            <p>
              If you do not have digital access code, click below to purchase
              one.
            </p>
            <a className="btn btn-info" href={PRODUCT_LIST_URL} target="_blank">
              Buy Access Code
            </a>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
