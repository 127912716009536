import axios from "axios";
import fileDownload from "../utilities/fileDownload";

const Assignment = {
  list(courseId) {
    return axios.get(`courses/${courseId}/assignments`);
  },

  get(courseId, assignemntId) {
    return axios.get(`courses/${courseId}/assignments/${assignemntId}`);
  },

  create(courseId, data) {
    return axios.post(`courses/${courseId}/assignments`, data);
  },

  update(courseId, assignmentId, data) {
    return axios.patch(`courses/${courseId}/assignments/${assignmentId}`, data);
  },

  delete(courseId, assignmentId) {
    return axios.delete(`courses/${courseId}/assignments/${assignmentId}`);
  },

  getStudentAssignmentsForQuestion(courseId, assignmentId, questionId) {
    return axios.get(
      `courses/${courseId}/assignments/${assignmentId}/student-assignments`,
      { params: { questionId } }
    );
  },

  getStudentAnswers(courseId, assignmentId) {
    return axios.get(
      `courses/${courseId}/assignments/${assignmentId}/student-assignments`,
      { params: { size: 200 } }
    );
  },

  getStudentAssignmentId(courseId, assignmentId) {
    return axios.get(
      `courses/${courseId}/assignments/${assignmentId}/student-assignments`
    );
  },

  createStudentAssignments(courseId, assignmentId) {
    return axios.post(
      `courses/${courseId}/assignments/${assignmentId}/student-assignments`,
      {}
    );
  },

  getAnswers(courseId, assignmentId) {
    return axios.get(
      `courses/${courseId}/assignments/${assignmentId}/student-assignments/answer`
    );
  },

  async submitAnswer(
    courseId,
    assignmentId,
    questionId,
    shortAnswerText,
    fileId
  ) {
    try {
      let studentAssignmentId;
      const studentAssignments = await Assignment.getStudentAssignmentId(
        courseId,
        assignmentId
      );
      if (studentAssignments.content.length === 0) {
        const studentAssignment = await Assignment.createStudentAssignments(
          courseId,
          assignmentId
        );
        studentAssignmentId = studentAssignment.studentAssignmentId;
      } else {
        studentAssignmentId = studentAssignments.content[0].studentAssignmentId;
      }
      return axios
        .post(
          `courses/${courseId}/assignments/${assignmentId}/student-assignments/${studentAssignmentId}/answer`,
          {
            questionId,
            shortAnswerText,
            fileId,
          }
        )
        .catch((err) => {
          throw {
            msg: err.errors[0].error,
          };
        });
    } catch (e) {
      throw e;
    }
  },

  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post("files/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  downloadAnswerFiles(courseID, assignmentID, questionID, page = 1) {
    return axios
      .get(
        `courses/${courseID}/assignments/${assignmentID}/questions/${questionID}/download-answer?page=${page}`,
        {
          responseType: "blob",
        }
      )
      .then((resp) => {
        fileDownload(resp.content, resp.filename);
      });
  },

  getTotalPages(courseID, assignmentId, questionId) {
    return axios.get(
      `courses/${courseID}/assignments/${assignmentId}/questions/${questionId}/download-answer/totalPages`
    );
  },

  downloadMarksFile(assignmentId) {
    return axios
      .post(`comments/generate-report/${assignmentId}`, {
        responseType: "blob",
      })
      .then((resp) => {
        fileDownload(resp.content, resp.filename);
      });
  },

  uploadModelAnswer(courseId, assignmentId, questionId, fileID) {
    return axios.post(
      `courses/${courseId}/assignments/${assignmentId}/questions/${questionId}/modelanswer`,
      { mode: "model", fileID: fileID }
    );
  },

  uploadZIPAnswer(courseId, assignmentId, questionId, fileID) {
    return axios.post(
      `courses/${courseId}/assignments/${assignmentId}/questions/${questionId}/modelanswer`,
      { mode: "reserved", fileID: fileID }
    );
  },

  uploadZipGradedFile(courseId, assignmentId, questionId, fileID) {
    return axios.post(
      `courses/${courseId}/assignments/${assignmentId}/questions/${questionId}/modelanswer`,
      { mode: "graded", fileID: fileID }
    );
  },

  downloadQuestionFile(courseID, assignmentID, questionID) {
    return axios
      .post(
        `courses/${courseID}/assignments/${assignmentID}/questions/${questionID}/download-question`
      )
      .then((resp) => {
        return axios
          .get(`files/${resp.FileId}`, {
            responseType: "blob",
          })
          .then((resp) => {
            fileDownload(resp.content, resp.filename);
          });
      });
  },

  downloadQuestion(url) {
    return axios
      .get(url, {
        responseType: "blob",
      })
      .then((resp) => {
        fileDownload(resp.content, resp.filename);
      });
  },

  getRubrics(courseID, assignmentID, questionID) {
    return axios.get(
      `courses/${courseID}/assignments/${assignmentID}/questions/${questionID}/rubrics`
    );
  },

  updateRubrics(courseID, assignmentID, questionID, ExcelBlockID, data) {
    return axios.patch(
      `courses/${courseID}/assignments/${assignmentID}/questions/${questionID}/rubrics/${ExcelBlockID}`,
      data
    );
  },

  gradeView(assignmentid) {
    return axios.get(`assignments/${assignmentid}/gradeview`);
  },
};

export default Assignment;
