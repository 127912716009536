import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import styled from "styled-components";
import moment from "moment";
import Assignment from "../../../api/assignment";

const Root = styled.div``;

const GradeView = ({ match }) => {
  const [data, setData] = useState({
    UserID: "1",
    AssignmentID: "1",
    AssignmentName: "Week1",
    TotalMarks: 80,
    TotalMaxMarks: 100,
    Marks: [
      {
        SheetName: "Q1",
        GradingComponent: "Name entered",
        ModelBlockCellRange: "A1:A2",
        MaxMarks: 2,
        MarksObtained: 2,
        CellDetails: [
          {
            AnswerBlockCellRange: "A1",
            MarksObtained: 1,
            Comments: "Correct, Good job",
          },
          {
            AnswerBlockCellRange: "A1",
            MarksObtained: 1,
            Comments: "Correct, Good job",
          },
        ],
      },
      {
        SheetName: "Q1",
        GradingComponent: "Write Formula in A3A6",
        ModelBlockCellRange: "A3:A6",
        MaxMarks: 4,
        MarksObtained: 1,
        CellDetails: [
          {
            AnswerBlockCellRange: "A3:A3",
            MarksObtained: 1,
            Comments: "Write formula in entire range A3:A6",
          },
          {
            AnswerBlockCellRange: "A4:A4",
            MarksObtained: 0,
            Comments: "Comments mention in previous cell",
          },
          {
            AnswerBlockCellRange: "A5:A5",
            MarksObtained: 0,
            Comments: "Comments mention in previous cell",
          },
          {
            AnswerBlockCellRange: "A6:A6",
            MarksObtained: 0,
            Comments: "Comments mention in previous cell",
          },
        ],
      },
      {
        SheetName: "Q2",
        GradingComponent: "Write Formula in A1A4",
        ModelBlockCellRange: "A1A4",
        MaxMarks: 4,
        MarksObtained: 4,
        CellDetails: [
          {
            AnswerBlockCellRange: "A1:A4",
            MarksObtained: 4,
            Comments: "correct",
          },
        ],
      },
    ],
  });
  useEffect(() => {
    Assignment.gradeView(match.params.id).then(setData);
  }, []);

  const columns = [
    { title: "Sheet Name", dataIndex: "SheetName" },
    { title: "Grading Component", dataIndex: "GradingComponent" },
    {
      title: "Model Answer Block Cell Range",
      dataIndex: "ModelBlockCellRange",
    },
    { title: "Max Marks", dataIndex: "MaxMarks" },
    { title: "Marks Obtained", dataIndex: "MarksObtained" },
  ];

  return (
    <Root>
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            {!data && <Spin spinning />}
            <div>
              <table className="table table-bordered" style={{ width: 300 }}>
                <tbody>
                  <tr>
                    <td>Assignment Name</td>
                    <td>{data.AssignmentName}</td>
                  </tr>
                  <tr>
                    <td>Total Marks Obtained</td>
                    <td>{data.TotalMarks}</td>
                  </tr>
                  <tr>
                    <td>Total Max Marks</td>
                    <td>{data.TotalMaxMarks}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <strong>
                        Next grading scheduled at{" "}
                        {moment().add(1, "h").startOf("h").format("HH:mm A")}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Table
                expandRowByClick
                columns={columns}
                dataSource={data.Marks}
                expandedRowRender={(row) => (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Answer Block Cell Range</th>
                        <th>Marks Obtained</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.CellDetails.map((i, idx) => (
                        <tr key={idx}>
                          <td>{i.AnswerBlockCellRange}</td>
                          <td>{i.MarksObtained}</td>
                          <td>{i.Comments}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default GradeView;
