import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import { push } from 'react-router-redux';

import auth from '../../../api/auth';
import { sessionActions } from '../../../redux/modules/session';

@connect()
@autobind
export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      user: {
        userName: '',
        password: ''
      },
      showSignupModal: false
    }
  }

  onChange(e) {
    const { name, value } = e.target;
    const { user } = this.state;
    user[name] = value;
    this.setState({ user });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { userName, password } = this.state.user;
    if (userName && password) {
      auth.login(userName, password).then(
        resp => {
          this.props.dispatch(sessionActions.storeSession(resp));
        },
        err => {
          swal('Oops', err.errors[0].error, 'error');
        }
      )
    }
  }

  onForgotPasswordClick(e) {
    e.preventDefault();
    swal({
      text: 'Enter your registered email to receive password reset link',
      content: {
        element: 'input',
        attributes: {
          type: 'email'
        }
      },
      button: {
        text: 'Send',
        closeModal: false
      }
    })
      .then(email => {
        if (!email) throw null;

        return auth.sendResetPasswordLink(email);
      })
      .then(resp => {
        swal('Success!', resp.msg, 'success');
      })
      .catch(err => {
        if (err) {
          swal("Oops", err.msg, "error");
        }
      });
  }

  showSignUp(e) {
    e.preventDefault();
    this.setState({showSignupModal: true});
  }
  close() {
    this.setState({showSignupModal: false});
  }

  render() {
    const { user, showSignupModal } = this.state;
    return (
      <div className="container">
        <div className="col-md-6 col-md-offset-3">
          <div className="panel clearfix">
            <h3 className="text-center">Please login before proceeding to your assignment page</h3>
            {
            // <p className="col-md-10 col-md-offset-1 alert alert-info text-center">If you don't have an account please <a href="#" onClick={this.showSignUp}>create</a> an account.</p>
            }
            <form onSubmit={this.handleSubmit} className="col-md-8 col-md-offset-2">
              <div className="form-group">
                <label for="">Email ID (Use your .edu Email ID)</label>
                <input name="userName" value={user.userName} onChange={this.onChange} className="form-control" type="email" />
              </div>
              <div className="form-group">
                <label for="">Password</label>
                <label for="">Unless you have changed the password, enter the same password which you created at the time of sign up.</label>
                <label for="">If you have changed the password, then enter the new password.</label>
                <input name="password" value={user.userName} onChange={this.onChange} className="form-control" type="password" />
              </div>
              <button className="btn btn-lg btn-wide btn-default" style={{marginRight: 10}} id="studentLogin">Login</button>
              <a href="#" onClick={this.onForgotPasswordClick}>Forgot password?</a>
            </form>
          </div>
        </div>
        { showSignupModal &&
        <Modal show={showSignupModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Register</Modal.Title>
            <Modal.Body>

            </Modal.Body>
          </Modal.Header>
        </Modal>}
      </div>
    )
  }
}
