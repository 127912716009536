import React from "react";
import PropTypes from "prop-types";
import uniq from "lodash/uniq";
import {
  Badge,
  Button,
  Checkbox,
  Radio,
  Icon,
  List,
  Popover,
  Select,
  Tabs,
  Tooltip,
  Input,
} from "antd";
import Comment from "../../../components/Sheet/Comment";
import RubicFrom from "./RubricForm";
import { COMMENTS } from "../../../constants";

const TabPane = Tabs.TabPane;
const Option = Select.Option;

const styles = {
  container: {
    padding: 10,
  },
  tabBar: {
    margin: 0,
  },
  header: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#eee",
    borderBottom: "1px solid #ccc",
    padding: 5,
    width: "100%",
  },
  listHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
};

export default class XLSActionPanel extends React.Component {
  static propTypes = {
    onSaveRubic: PropTypes.func,
    onSaveComment: PropTypes.func,
    onDeleteComment: PropTypes.func,
    onAnswerSelect: PropTypes.func,
    loadingBlock: PropTypes.bool,
    cellRef: PropTypes.string,
    block: PropTypes.object,
    answers: PropTypes.array,
    selectedAnswer: PropTypes.number,
    rubrics: PropTypes.array,
    worksheetName: PropTypes.string,
    blockCellRange: PropTypes.string,
  };

  state = {
    selectedComment: null,
    selectedBock: null,
    showMultiComment: false,
    commentFilter: "",
    marksFilter: undefined,
    queryFilter: '',
    selectedBlocks: [],
    tab: "excel",
  };

  onTabChange = (key) => {
    this.setState({ tab: key, selectedBlocks: [] });
  };

  onBlockSelect = (val) => {
    this.setState({
      selectedBlock: val,
    });
  };

  onMarksFilterSelect = (val) => {
    this.setState({
      marksFilter: val
    });
  };

  onQueryChange = (e) => {
    this.setState({
      queryFilter: e.target.value
    });
  }

  showComment = (id) => {
    this.setState({
      selectedComment: id,
    });
  };

  hideComment = (e) => {
    e && e.stopPropagation();
    this.setState({
      selectedComment: null,
    });
  };

  toggleMultiComment = () => {
    const state = {
      showMultiComment: !this.state.showMultiComment,
    };
    if (!state.showMultiComment) {
      state.selectedBlocks = [];
    }
    this.setState(state);
  };

  onSelectBlocks = (values) => {
    this.setState({
      selectedBlocks: values,
    });
  };

  saveExcelComment = (block, comment, marks, id) => {
    const payload = {
      blockIds: [block.ExcelBlockID],
      commentText: comment,
      marks: marks,
      type: "excel",
      blocks: [
        {
          BlockCellRange: block.BlockCellRange,
          BlockFormula: block.BlockFormula,
        },
      ],
    };
    this.props.onSaveComment(payload, id);
  };

  saveExcelMultiComment = (comment, marks) => {
    const blocks = [];
    this.state.selectedBlocks.forEach((id) => {
      const b = this.props.block.find((i) => i.ExcelBlockID === id);
      if (b) {
        blocks.push(b);
      }
    });
    const payload = {
      blockIds: blocks.map((b) => b.ExcelBlockID),
      commentText: comment,
      marks: marks,
      type: "excel",
      blocks: blocks.map((b) => ({
        BlockCellRange: b.BlockCellRange,
        BlockFormula: b.BlockFormula,
      })),
    };
    this.props.onSaveComment(payload);
  };

  saveValueMultiComment = (comment, marks) => {
    debugger;
    const values = this.getValues();
    const blocks = [];
    this.state.selectedBlocks.forEach((id) => {
      const b = values.find((i) => i.value === id);
      if (b) {
        blocks.push(b);
      }
    });

    const payload = {
      id: blocks[0],
      marks,
      commentText: comment,
      type: "cell",
      CellRange: blocks[0].range,
      CellValue: blocks[0].value,
      QuestionID: this.props.answers[0].questionId,
    };

    return;

    this.props.onSaveComment(payload);
  };

  saveValueComment = (cell, comment, marks, id) => {
    const payload = {
      id: cell.id,
      marks,
      commentText: comment,
      type: "cell",
      CellRange: cell.range,
      CellValue: cell.value,
      QuestionID: this.props.answers[0].questionId,
    };
    this.props.onSaveComment(payload, id);
  };

  onSaveComment = (block, id, comment, marks) => {
    if (this.state.tab === "excel") {
      this.saveExcelComment(block, comment, marks, id);
    } else if (this.state.tab === "cell") {
      this.saveValueComment(block, comment, marks, id);
    }
    this.hideComment();
  };

  onSaveMultiComment = (id, comment, marks) => {
    const { tab } = this.state;
    if (tab === "excel") {
      this.saveExcelMultiComment(comment, marks);
    } else if (tab === "cell") {
      this.saveValueMultiComment(comment, marks, id);
    }
    this.toggleMultiComment();
  };

  onDeleteComment = (block, id) => {
    this.props.onDeleteComment(block, id);
    this.hideComment();
  };

  onSaveRubic = async (values, ExcelBlockID) => {
    return this.props.onSaveRubic(values, ExcelBlockID);
  };

  getPopupContainer = () => {
    return document.getElementById(
      this.state.tab === "excel" ? "formula-list" : "values-list"
    );
  };

  onToggleAll = (e, values) => {
    const { checked } = e.target;
    if (checked) {
      const checkedItems = values.map((i) => i.value || i.ExcelBlockID);
      this.onSelectBlocks(checkedItems);
    } else {
      this.setState({
        selectedBlocks: [],
      });
    }
  };

  onCommentFilterSelect = (e) => {
    this.setState({
      commentFilter: e.target.value,
    });
  };

  toggleUncommented = (e) => {
    const { checked } = e.target;
    this.setState({
      showUncommented: checked,
    });
  };

  getValues = () => {
    const { cellRef, block } = this.props;
    const { commentFilter } = this.state;
    const values = [];
    block &&
      block.forEach((b) => [
        b.cells.forEach((cell) => {
          if (cell.range === cellRef) {
            const c = { ...cell };
            c.comments = c.comments ? [c.comments] : [];
            if (commentFilter !== "") {
              if (commentFilter === "1" && c.comments.length) {
                values.push(c);
              } else if (commentFilter === "0" && !c.comments.length) {
                values.push(c);
              }
            } else {
              values.push(c);
            }
          }
        }),
      ]);
    return values;
  };

  renderListItem = (item) => {
    const selected = this.state.selectedComment === item.ExcelBlockID;
    const tip = item.cells.map((i) => i.value).join("\r\n");
    return (
      <List.Item
        style={{ wordBreak: "break-word" }}
        actions={[this.renderCommentButton(item, selected)]}
      >
        <Checkbox value={item.ExcelBlockID} />
        <span style={{ flex: 1 }}>
          <Tooltip title={tip}>
            {item.BlockCellRange} | {item.BlockFormula}
          </Tooltip>
        </span>
        <span style={{ textAlign: 'right', width: 50 }}>
          {item.comments.length ? (
              <Tooltip title={item.comments[0].CommentText}>
                {item.comments[0].Marks}
              </Tooltip>
          ) : null}
        </span>
        <span style={{ textAlign: 'right', width: 80 }}>
          {item.frequency}
        </span>
      </List.Item>
    );
  };

  renderListHeader = (values) => {
    return (
      <div style={styles.listHeader} className="xls-filter-bar">
        <Checkbox
          checked={
            values.length > 0 &&
            values.length === this.state.selectedBlocks.length
          }
          onChange={(e) => this.onToggleAll(e, values)}
        >
          All
        </Checkbox>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: 12, paddingRight: 5 }}>Show: </span>
          <Radio.Group
            name="comment"
            value={this.state.commentFilter}
            onChange={this.onCommentFilterSelect}
          >
            <Radio value="">All</Radio>
            <Radio value="1">Commented</Radio>
            <Radio value="0">Uncommented</Radio>
          </Radio.Group>
        </div>
      </div>
    );
  };

  renderValue = (item) => {
    const selected = this.state.selectedComment === item.id;
    return (
      <List.Item
        style={{ wordBreak: "break-word" }}
        actions={[this.renderCommentButton(item, selected)]}
      >
        <Checkbox value={item.value} />
        <span style={{ flex: 1 }}>
          <Tooltip title={item.formula}>{item.value}</Tooltip>
        </span>
        <span style={{ textAlign: 'right', width: 50 }}>
          {item.comments.length ? (
              <Tooltip title={item.comments[0].CommentText}>
                {item.comments[0].Marks}
              </Tooltip>
          ) : null}
        </span>
        <span style={{ textAlign: 'right', width: 80 }}>
          {item.frequency}
        </span>
      </List.Item>
    );
  };

  renderCommentButton = (block, selected) => {
    const hasComments = block.comments.length;
    if (selected) {
      const title = this.state.tab === 'excel' ? `${block.BlockCellRange} | ${block.BlockFormula}` : block.value;
      return (
        <Popover
          title={
            <div className="d-flex">
              <span style={{ flex: 1 }}>
                {title}
              </span>
              <span className="clickable" onClick={this.hideComment}>
                <Icon
                  type="close-circle"
                  style={{
                    color: "#e04747",
                    fontWeight: "bold",
                  }}
                />
              </span>
            </div>
          }
          autoAdjustOverflow={false}
          visible={selected}
          content={this.renderCommentBox(block)}
          placement="leftTop"
          trigger="click"
          getPopupContainer={this.getPopupContainer}
        >
          <Badge dot={hasComments}>
            <Icon
              type="message"
              status="success"
              onClick={() => this.showComment(block.ExcelBlockID || block.id)}
            />
          </Badge>
        </Popover>
      );
    }
    return (
      <Badge dot={hasComments}>
        <Icon
          type="message"
          status="success"
          onClick={() => this.showComment(block.ExcelBlockID || block.id)}
        />
      </Badge>
    );
  };

  renderCommentBox = (block) => {
    const comments = [];
    const commentsLower = [];
    this.props.block.forEach((b) => {
      const c = b.comments[0];
      if (c) {
        const text = c.CommentText;
        const textLower = text.toLowerCase();
        if (!commentsLower.includes(textLower)) {
          comments.push({ text: c.CommentText, marks: c.Marks });
          commentsLower.push(textLower);
        }
      }
    });
    COMMENTS.forEach(
      (c) =>
        !commentsLower.includes(c.toLowerCase()) && comments.push({ text: c })
    );
    return (
      <Comment
        previousComments={comments}
        comment={block.comments[0]}
        onSave={this.onSaveComment.bind(null, block)}
        onDelete={this.onDeleteComment.bind(null, block)}
      />
    );
  };

  renderMultipleCommentBox = () => {
    if (
      !this.state.selectedBlocks.length ||
      !this.props.block ||
      !this.state.showMultiComment
    )
      return null;
    const comments = [];
    this.props.block.forEach((b) => {
      const c = b.comments[0];
      if (c) {
        comments.push(c.CommentText);
      }
    });

    return (
      <Comment previousComments={comments} onSave={this.onSaveMultiComment} />
    );
  };

  renderRubric = () => {
    const { cellRef, rubrics, worksheetName, blockCellRange } = this.props;
    if (this.state.tab !== "rubric") return null;

    if (!cellRef) return "Please select a cell range";
    const cellRubrics = rubrics.find(
      (r) =>
        r.block_cell_range === blockCellRange &&
        r.worksheetName === worksheetName
    );

    if (!cellRubrics) return null;

    return (
      <RubicFrom
        blockCellRange={blockCellRange}
        values={cellRubrics}
        onSubmit={this.onSaveRubic}
      />
    );
  };

  render() {
    const {
      loadingBlock,
      cellRef,
      block,
      answers,
      selectedAnswer,
      onAnswerSelect,
    } = this.props;
    const {
      selectedBlocks,
      showUncommented,
      tab,
      selectedBlock,
      commentFilter,
      marksFilter,
      queryFilter,
    } = this.state;

    const blockRanges = block ? block.map((i) => i.BlockCellRange) : [];
    const filteredBlock = block
      ? block.filter((b) => {
          if (selectedBlock && selectedBlock !== b.BlockCellRange) return false;

          const exists = b.BlockCellRange.startsWith(cellRef);
          if (exists) {
            if (queryFilter) {
              return (b.BlockFormula.toLowerCase().includes(queryFilter.toLowerCase()));
            }
            const hasComment = b.comments.length;
            if (commentFilter === "" && !marksFilter) return true;
            else if (hasComment && commentFilter === "1") {
              return true;
            } else if (commentFilter === "0" && !hasComment) {
              return true
            } else if (marksFilter && hasComment && b.comments[0].Marks === Number(marksFilter)) {
              return true;
            }
          }
          return false;
        })
      : [];
    const marksOptions = block ? block.map(i => i.comments[0]?.Marks) : [];

    const values = this.getValues();

    return (
      <div className="xls-action-panel">
        <Select
          value={selectedAnswer}
          onChange={onAnswerSelect}
          style={{ width: "100%" }}
          placeholder="Select Student Answer"
        >
          {answers
            ? answers.map((a) => (
                <Option key={a.studentId} value={a.studentId}>
                  Student ID: {a.studentId}
                </Option>
              ))
            : null}
        </Select>
        <div style={styles.header}>
          <span style={{ flex: 1 }}>CellRef# {cellRef || ""}</span>
          <Input.Search
              placeholder="Search"
              value={queryFilter}
              onChange={this.onQueryChange}
              style={{ width: 150 }}
          />
          <Select
              value={marksFilter}
              onChange={this.onMarksFilterSelect}
              style={{ width: 100 }}
              placeholder="Marks"
              allowClear
          >
            {uniq(marksOptions.filter(i => i)).map((i) => (
                <Select.Option value={i} key={i}>
                  {i}
                </Select.Option>
            ))}
          </Select>
          <Select
            value={selectedBlock}
            onChange={this.onBlockSelect}
            style={{ width: 150 }}
            placeholder="Filter block range"
            allowClear
          >
            {uniq(blockRanges).map((i) => (
              <Select.Option value={i} key={i}>
                {i}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Tabs
          defaultActiveKey="cell"
          activeKey={tab}
          tabBarStyle={styles.tabBar}
          onChange={this.onTabChange}
        >
          <TabPane tab="Formula" key="excel" style={styles.container}>
            {tab === "excel" && (
              <React.Fragment>
                {this.renderListHeader(filteredBlock)}
                {selectedBlocks.length >= 2 && (
                  <Popover
                    placement="bottom"
                    visible={this.state.showMultiComment}
                    title={
                      <div className="d-flex">
                        <span style={{flex: 1}}>Add multi comment</span>
                        <span className="clickable" onClick={this.toggleMultiComment}><Icon type="close" style={{
                          color: '#2162ff',
                          fontWeight: 'bold'
                        }}/></span>
                      </div>
                    }
                    content={this.renderMultipleCommentBox()}
                    trigger={['click']}
                  >
                    <Button type="primary" size="small" onClick={this.toggleMultiComment}>Add comment</Button>
                  </Popover>
                )}
                <Checkbox.Group
                  value={selectedBlocks}
                  style={{ width: "100%" }}
                  onChange={this.onSelectBlocks}
                >
                  <List
                    id="formula-list"
                    style={{ overflow: "auto", height: "calc(100vh - 210px)" }}
                    loading={loadingBlock}
                    dataSource={filteredBlock}
                    renderItem={this.renderListItem}
                  />
                </Checkbox.Group>
              </React.Fragment>
            )}
          </TabPane>
          <TabPane tab="Value" key="cell" style={styles.container}>
            {tab === "cell" && (
              <React.Fragment>
                {this.renderListHeader(values)}
                { selectedBlocks.length >=2 && (
                  <Popover
                    placement="bottom"
                    visible={this.state.showMultiComment}
                    title={
                      <div className="d-flex">
                        <span style={{ flex: 1}}>Add multi comment</span>
                        <span className="clickable" onClick={this.toggleMultiComment}><Icon type="close" style={{ color: '#2162ff', fontWeight: 'bold' }} /></span>
                      </div>
                    }
                    content={this.renderMultipleCommentBox()}
                    trigger={['click']}
                  >
                    <Button type="primary" size="small" onClick={this.toggleMultiComment}>Add comment</Button>
                  </Popover>
                )}
                <Checkbox.Group
                  value={selectedBlocks}
                  style={{ width: "100%" }}
                  onChange={this.onSelectBlocks}
                >
                  <List
                    id="values-list"
                    style={{ overflow: "auto", height: "calc(100vh - 210px)" }}
                    loading={loadingBlock}
                    dataSource={values}
                    renderItem={this.renderValue}
                  />
                </Checkbox.Group>
              </React.Fragment>
            )}
          </TabPane>
          <TabPane tab="Rubric" key="rubric" style={styles.container}>
            {tab === "rubric" ? this.renderRubric() : null}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
