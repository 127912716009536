import React, {Fragment} from 'react';
import {Col, Row, Modal, message} from "antd";


export default class UploadFiles extends React.Component {
  state = {
    modelAnswerFile: null,
    ZIPAnswerFile: null,
    uploading: false
  };

  onModelAnswerSelect = (e) => {
    this.setState({
      modelAnswerFile: e.target.files && e.target.files[0]
    });
  };

  onZIPAnswerSelect = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const name = file.name;
      const ext = name.split('.').pop().toLowerCase();
      if (ext !== 'zip') {
        message.error('Please upload only ZIP file');
        e.target.value = null;
      } else {
        this.setState({
          ZIPAnswerFile: file
        });
      }
    }
  };

  onZIPGradeSelect = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const name = file.name;
      const ext = name.split('.').pop().toLowerCase();
      if (ext !== 'zip') {
        message.error('Please upload only ZIP file');
        e.target.value = null;
      } else {
        this.setState({
          ZIPGradeFile: file
        });
      }
    }
  };

  onSubmit = () => {
    this.setState({ uploading: true });
    this.props.onSubmit(this.state).then(
      () => {
        message.success('Files uploaded');
        this.props.onClose();
      },
      e => {
        message.error(e.message);
        this.setState({ uploading: false });
      }
    )
  };

  render() {
    return (
      <Modal
        visible
        onCancel={this.props.onClose}
        title="Upload files"
        okText="Upload"
        onOk={this.onSubmit}
        okButtonProps={{
          loading: this.state.uploading
        }}
      >
        <Fragment>
          <Row type="flex" align="middle" style={{ marginBottom: 5 }}>
            <Col span={10}>Add Model Answer File</Col>
            <Col span={14}><input type="file" className="form-control" onChange={this.onModelAnswerSelect} /></Col>
          </Row>
          <Row type="flex" align="middle" style={{ marginBottom: 5 }}>
            <Col span={10}>Add Zip Answer Files</Col>
            <Col span={14}><input type="file" accept=".zip" className="form-control" onChange={this.onZIPAnswerSelect} /></Col>
          </Row>
          <Row type="flex" align="middle" style={{ marginBottom: 5 }}>
            <Col span={10}>Add Zip Graded Files</Col>
            <Col span={14}><input type="file" accept=".zip" className="form-control" onChange={this.onZIPGradeSelect} /></Col>
          </Row>
        </Fragment>
      </Modal>
    )
  }
}
