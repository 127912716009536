import React from "react";
import { message, Alert, Spin } from "antd";
import swal from "sweetalert";
import { connect } from "react-redux";
import assignmnentApi from "../../../api/assignment";
import courseAPI from "../../../api/course";
import moment from "moment-timezone";
import UpgradeModal from "../components/UpgradeModal";

@connect((s) => ({ user: s.session.user }))
class AnswerQuestion extends React.Component {
  state = {
    file: null,
    note: "",
    showModal: false,
    success: false,
    loading: true,
    submitting: false,
    assignment: null,
  };

  componentDidMount() {
    const { user, match } = this.props;
    const { courseId } = match.params;
    courseAPI.enrollCourse(user.userId, courseId).then(
      () => this.init(),
      (e) => {
        console.log(e);
        this.init();
      }
    );
  }

  init = () => {
    this.loadData().then(() => {
      this.setState({
        showModal: true,
        loading: false,
      });
    });
  };

  loadData = async () => {
    const { courseId, assignmentId } = this.props.match.params;
    let assignment;
    try {
      assignment = await assignmnentApi.get(courseId, assignmentId);
    } catch (e) {
      await assignmnentApi.createStudentAssignments(courseId, assignmentId);
      assignment = await assignmnentApi.get(courseId, assignmentId);
    }
    this.setState({
      assignment,
    });
  };

  onFileSelect = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.name.toLowerCase().startsWith("~$")) {
        swal(
          "Invalid File",
          "You are turning a temporary or a swap file, please turn in a file whose name doesnt start with ~$",
          "error"
        );
      }
      else if (!file.name.toLowerCase().endsWith(".xlsx")) {
        swal(
          "Invalid File",
          "You should Turn in only .xlsx file. You should use Office 365 for this course. However, If you are using Apple Numbers, then before turning in, export the .numbers file as .xlsx without the summary sheet",
          "error"
        );
      } else {
        this.setState({
          file,
        });
      }
    }
  };

  onSubmit = async () => {
    const { courseId, assignmentId, questionId } = this.props.match.params;
    const { note, file } = this.state;
    if (!file) return;
    try {
      this.setState({ submitting: true });
      let fileId = null;
      if (file) {
        const fileResp = await assignmnentApi.uploadFile(file);
        fileId = fileResp.fileId;
      }
      await assignmnentApi.submitAnswer(
        Number(courseId),
        Number(assignmentId),
        Number(questionId),
        note,
        fileId
      );
      swal(
        "Good job!",
        "Assignment is successfully submitted.",
        "success"
      );
      this.setState({ success: true });
    } catch (e) {
      swal("Error", e.msg, "error");
      this.setState({ submitting: false });
    }
  };

  onUpgrade = async () => {
    this.setState({ upgrade: true });
  };

  render() {
    const {
      showModal,
      file,
      note,
      assignment,
      success,
      loading,
      submitting,
    } = this.state;
    const { courses } = this.props.user;

    const course = courses.find(
      (c) => c.courseId === Number(this.props.match.params.courseId)
    );

    const trialDaysRemaining = 14 - moment().diff(course.enrollmentDate, "day");
    const isTrial = course.accessCodeType === "TRIAL";
    const isTrialing = trialDaysRemaining >= 0 && isTrial;
    const trialExpired = trialDaysRemaining < 0 && isTrial;

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <Spin spinning={loading}>
            <div className="panel clearfix">
              {success ? (
                <Alert
                  message="Assignment is successfully submitted."
                  type="success"
                />
              ) : (
                showModal &&
                !loading && (
                  <div>
                    <Alert
                      showIcon
                      message={
                        <span
                          style={{
                            fontSize: "18px",
                            color: "#ff0000",
                            fontWeight: "bold",
                          }}
                        >
                          1. This is {assignment.title} Turn in (Submission)
                          Link. Do Not Turn in (Submit) any other Assignment
                          file Here.
                          <br />
                          2. Do Not Turn in (Submit) file that has
                          <ul>
                            <li>Extra Work Sheet (e.g. Summary Sheet)</li>
                            <li>Deleted Instruction Sheet </li>
                            <li>Reordered Work Sheets </li>
                          </ul>
                        </span>
                      }
                      type="warning"
                      style={{ marginBottom: 20 }}
                    />
                    {isTrialing && (
                      <Alert
                        showIcon
                        style={{ marginBottom: 20 }}
                        type="error"
                        message={
                          <div>
                            You are on Trial in {course.title} and your
                            trial ends on January 24, 2023. Please{" "}
                            <a onClick={this.onUpgrade} href="#">
                              upgrade
                            </a>
                          </div>
                        }
                      />
                    )}
                    {trialExpired ? (
                      <div style={{ marginBottom: 20 }}>
                        <Alert
                          showIcon
                          type="error"
                          message={`Your trial access to this course has expired. `}
                          description={
                            <div>
                              Visit{" "}
                              <a
                                target="_blank"
                                href="www.knowledgevarsitypress.com/product-list/"
                              >
                                www.knowledgevarsitypress.com/product-list/
                              </a>{" "}
                              to purchase access code or email to
                              support@knowledgevarsity.com.
                              If you have purchased the access code, then please{" "}
                              <a onClick={this.onUpgrade} href="#">
                                upgrade
                              </a>
                            </div>

                          }
                        />
                      </div>
                    ) : (
                      <>
                        <h5>{assignment.title}</h5>
                        {/*<div className="form-group">*/}
                          {/*<textarea*/}
                            {/*rows={2}*/}
                            {/*placeholder="Add a Note to instructor (If you want an urgent response from the instructor, please contact them directly via email or Canvas)"*/}
                            {/*className="form-control"*/}
                            {/*value={note}*/}
                            {/*onChange={(e) =>*/}
                              {/*this.setState({ note: e.target.value })*/}
                            {/*}*/}
                          {/*/>*/}
                        {/*</div>*/}
                        <div className="form-group">
                          <label>Upload file</label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={this.onFileSelect}
                            accept=".xlsx"
                          />
                        </div>
                        <button
                          disabled={submitting || !file}
                          className="btn btn-primary"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </div>
                )
              )}
            </div>
          </Spin>
        </div>
        {this.state.upgrade && (
          <UpgradeModal
            courseId={this.props.match.params.courseId}
            onClose={() => this.setState({ upgrade: false })}
          />
        )}
      </div>
    );
  }
}

export default AnswerQuestion;
