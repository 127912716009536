import React, { useState } from "react";
import max from "lodash/maxBy";
import { Drawer } from "antd";
import sortBy from "lodash/sortBy";
import moment from 'moment-timezone';

const Submissions = ({ downloadFile, submissions }) => {
  const [showSubmissions, setShowSubmissions] = useState(false);

  const onSubmissionsClick = (student) => (e) => {
    e.preventDefault();
    setShowSubmissions(student);
  };

  const handleDownload = (FileID) => (e) => {
    e.preventDefault();
    downloadFile(FileID);
  };

  return (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Student</th>
            <th>Date</th>
            <th></th>
            <th className="text-right">Score</th>
          </tr>
        </thead>
        <tbody>
          {sortBy(submissions, ["firstName", "lastName"]).map((s) => (
            <tr>
              <td>
                {s.firstName} {s.lastname}
              </td>
              <td>{s.studentAnswers[0] ? moment(s.studentAnswers[0].SubmissionTime).format('L') : null}</td>
              <td>
                <a href="#" onClick={onSubmissionsClick(s)}>
                  submissions
                </a>
              </td>
              <td className="text-right">
                {max(s.studentAnswers, "Marks")?.Marks}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showSubmissions && (
        <Drawer
          title={`${showSubmissions.firstName} ${showSubmissions.lastName}`}
          visible
          width={500}
          onClose={() => setShowSubmissions(false)}
        >
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Date</th>
                <th className="text-right">Marks</th>
                <th>Grader's Comments</th>                
                <th>Submitted file</th>
                <th>Graded file</th>
              </tr>
            </thead>
            <tbody>
              {showSubmissions.studentAnswers.map((s, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{moment(s.SubmissionTime).format('L')}</td>
                  <td className="text-right">{s.Marks}</td>
                  <td>{s.GraderComment}</td>
                  <td>
                    <a href="#" onClick={handleDownload(s.FileID)}>
                      download
                    </a>
                  </td>
                  <td>
                    {s.was_comment_processed && (
                        <a href="#" onClick={handleDownload(s.GradedFileID)}>
                          download
                        </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Drawer>
      )}
    </>
  );
};

export default Submissions;
