import React from 'react';
import { Form, InputNumber, Button } from 'antd';
import sum from 'lodash/sum';


const toAbs = (total, val) => (val * total) / 100;
const toPc = (total, val) => (val * 100) / total;

class RubricForm extends React.Component {
  state = {
    saving: false
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { ExcelBlockID } = this.props.values;
    this.props.form.validateFields((er, values) => {
      if (!er) {
        this.setState({ saving: true });
        this.props.onSubmit({
          ExcelBlockID,
          total_marks: values.total_marks,
          item2: toAbs(values.total_marks, values.item2),
          item3: toAbs(values.total_marks, values.item3),
          item4: toAbs(values.total_marks, values.item4),
          item5: toAbs(values.total_marks, values.item5),
        }, ExcelBlockID).then(() => {
          this.setState({ saving: false });
        });
      }
    })
  };

  render() {
    const { form: { getFieldDecorator, getFieldsValue }, blockCellRange, values } = this.props;
    const formValues = getFieldsValue();
    const initialTotal = (sum([values.item3, values.item4, values.item5]) * 100) / values.total_marks;

    return (
      <Form onSubmit={this.onSubmit}>
        <div>
          <table className="table table-bordered">
            <thead>
            <tr>
              <th>BlockRange:</th>
              <td>{blockCellRange}</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Criteria</td>
              <td>Evaluation</td>
            </tr>
            <tr>
              <td>Total Marks</td>
              <td>
                {getFieldDecorator('total_marks', {
                  initialValue: values.total_marks
                })(<InputNumber />)}
              </td>
            </tr>
            <tr>
              <td>Correct Block range (%)</td>
              <td>
                {getFieldDecorator('item3', {
                  initialValue: toPc(values.total_marks, values.item3)
                })(<InputNumber />)}
              </td>
            </tr>
            <tr>
              <td>Correct Formula (%)</td>
              <td>
                {getFieldDecorator('item4', {
                  initialValue: toPc(values.total_marks, values.item4)
                })(<InputNumber />)}
              </td>
            </tr>
            <tr>
              <td>Correct Value (%)</td>
              <td>
                {getFieldDecorator('item5', {
                  initialValue: toPc(values.total_marks, values.item5)
                })(<InputNumber />)}
              </td>
            </tr>
            <tr>
              <td>Total %</td>
              <td>
                <InputNumber
                  value={sum([formValues.item3, formValues.item4, formValues.item5]) || initialTotal} />
              </td>
            </tr>
            <tr>
              <td>Penalty for hardcoding</td>
              <td>
                {getFieldDecorator('item2', {
                  initialValue: toPc(values.total_marks, values.item2)
                })(<InputNumber />)}
              </td>
            </tr>
            </tbody>
          </table>
          <Button loading={this.state.saving} type="primary" htmlType="submit">Save</Button>
        </div>
      </Form>
    )
  }
}

export default Form.create()(RubricForm);