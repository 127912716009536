import React from "react";
import PropTypes from "prop-types";
import { Button, Input, Select, Radio } from "antd";
import { FormGroup } from "react-bootstrap";
import isObject from "lodash/isObject";

const TextArea = Input.TextArea;
const Option = Select.Option;

export default class Comment extends React.Component {
  static propTypes = {
    comment: PropTypes.object,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    previousComments: PropTypes.array,
  };

  constructor() {
    super();
    this.state = {
      comment: "",
      marks: 0,
      saving: false,
      selectedComment: undefined,
    };
  }

  componentWillMount() {
    const { comment } = this.props;
    if (comment) {
      this.setState({
        comment: comment.CommentText,
        marks: comment.Marks,
        id: comment.commentID,
      });
    }
  }

  onChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onCommentSelect = (val) => {
    const comment = this.props.previousComments.find((c) => c.text === val);
    this.setState({
      selectedComment: val,
      comment: val,
      marks: comment.marks || 0,
    });
  };

  onSave = () => {
    const { comment, marks, id } = this.state;
    this.setState({ saving: true });
    this.props.onSave(id, comment, marks);
  };

  onDelete = () => {
    this.setState({ saving: true });
    this.props.onDelete(this.state.id);
  };

  render() {
    const { comment, marks, id } = this.state;
    const { previousComments = [] } = this.props;
    return (
      <div>
        <FormGroup>
          <Radio.Group onChange={e => this.setState({ marks: Number(e.target.value) })}>
            <Radio value="1">1</Radio>
            <Radio value="2">2</Radio>
            <Radio value="3">3</Radio>
            <Radio value="4">4</Radio>
            <Radio value="5">5</Radio>
            <Radio value="10">10</Radio>
          </Radio.Group>
        </FormGroup>
        <FormGroup>
          <Input
            name="marks"
            type="number"
            placeholder="enter marks"
            className="form-control"
            value={marks}
            onChange={this.onChange}
          />
        </FormGroup>
        <FormGroup>
          <Select
            onChange={this.onCommentSelect}
            value={this.selectedComment}
            style={{ width: "100%" }}
            placeholder="Select comment"
          >
            {previousComments.map((c, i) => {
              return <Option value={c.text} key={i}>{c.text}</Option>;
            })}
          </Select>
        </FormGroup>
        <FormGroup>
          <Radio.Group  onChange={e => this.setState({ comment: e.target.value })}>
            <Radio value="Yes, it is Correct.">Cr</Radio>
            <Radio value="No, it is not Correct.">IC</Radio>
            <Radio value="You have not entered formula in this cell.">NA</Radio>
            <Radio value="Answer is correct, but formula is incorrect.">FI</Radio>
            <Radio value="Don't Hardcode values. Write formula by referring cells instead of values.">HC</Radio>
          </Radio.Group>
        </FormGroup>
        <FormGroup>
          <TextArea
            autoSize={{ minRows: 3, maxRows: 6 }}
            name="comment"
            className="form-control"
            placeholder="Enter comments"
            value={comment}
            onChange={this.onChange}
          />
        </FormGroup>
        <FormGroup>
          <Button
            type="primary"
            loading={this.state.saving}
            onClick={this.onSave}
          >
            Save
          </Button>
          &nbsp;
          {id && (
            <Button
              type="danger"
              loading={this.state.saving}
              onClick={this.onDelete}
            >
              Delete
            </Button>
          )}
        </FormGroup>
      </div>
    );
  }
}
