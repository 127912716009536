import { combineReducers } from 'redux';
import * as types from './types';
import { resolve, reject } from '../../middleware/simple-promise';



const assignmentFolderReducerInitialState = {
  data: [],
  pages: 1,
  page: 0,
  loading: false,
  error: null
};

const assignmentFolderReducer = (state = assignmentFolderReducerInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_ASSIGNMENTS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case reject(types.GET_ASSIGNMENTS):
      return {
        ...state,
        loading: false,
        error: payload
      };
    case resolve(types.GET_ASSIGNMENTS):
      return {
        ...state,
        loading: false,
        ...payload
      };
    case resolve(types.EDIT_ASSIGNMENT):
      return {
        ...state,
        data: state.data.map(d => d.assignmentId === payload.assignmentId ? payload : d)
      };
    default:
      return state;
  }

};


const assignmentQuestionsInitialState = {
  data: [],
  loading: false,
  error: null
};

const assignmentQuestionsReducer = (state = assignmentQuestionsInitialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case types.LOAD_QUESTIONS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case reject(types.LOAD_QUESTIONS):
      return {
        ...state,
        loading: false,
        error: payload
      };
    case resolve(types.LOAD_QUESTIONS):
      return {
        ...state,
        loading: false,
        data: payload
      };
    case resolve(types.EDIT_QUESTIONS):
      return {
        ...state,
        data: state.data.map(d => d.questionId === payload.questionId ? payload : d)
      };
    default:
      return state;
  }
};

const phraseBucketState = {
  question: {},
  assignment: {},
  answers: []
};

const phraseBucketReducer = (state = phraseBucketState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_STUDENT_ANSWERS:
      return {
        ...state,
        loading: true,
        error: null,
        answers: [],
      };
    case resolve(types.GET_STUDENT_ANSWERS):
      return {
        ...state,
        answers: payload
      };
    case types.GET_ASSIGNMENT:
      return {
        ...state,
        assignment: {}
      };
    case resolve(types.GET_ASSIGNMENT):
      return {
        ...state,
        assignment: payload
      };
    case types.GET_QUESTION:
      return {
        ...state,
        question: {}
      };
    case resolve(types.GET_QUESTION):
      return {
        ...state,
        question: payload
      };
    default:
      return state;
  }
};

export default combineReducers({
  assignmentFolder: assignmentFolderReducer,
  assignmentQuestions: assignmentQuestionsReducer,
  managePhraseBucket: phraseBucketReducer
});