import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { push } from 'react-router-redux';
import { autobind } from 'core-decorators';

import Login from '../components/Login';
import { studentActions } from '../../../redux/modules/student';

@connect(state => ({
  session: state.session,
  student: state.student
}))
@autobind
export default class CourseLinkHandler extends React.Component {

  componentWillMount() {
      const { params: {id} } = this.props.match;
      const { dispatch } = this.props;
      dispatch(studentActions.setEnrollmentId(id));
      if(this.props.session.isLoggedIn) {
        dispatch(studentActions.enrollForCourse(id));
      } else {

      }
  }

  componentWillReceiveProps(nextProps) {
    const { enrollment } = nextProps.student;
    if (!this.props.student.enrollment.enrolled && enrollment.enrolled) {
      this.getCourseAssignment(enrollment.courseId);
    }
  }

  onLoginSuccess(authResp) {
    const id = authResp.user.linkCodeCourseId;
    this.getCourseAssignment(id);
  }

  getCourseAssignment(courseId) {
    this.props.dispatch(studentActions.getAssignments(courseId))
      .then(
        assignments => {
          const a = assignments[0];
          if(!a) return alert('Assignment is yet to start, please login after assignment start date');
          this.props.dispatch(studentActions.getQuestions(courseId, a.assignmentId));
          this.props.dispatch(push(`/student/assignments/${a.assignmentId}`));
        }
      )
  }

  render() {
    const { session: { isLoggedIn }, student: { enrollment }, match } = this.props;

    if (!isLoggedIn) {
      return <Login courseId={match.params.id} onLoginSuccess={this.onLoginSuccess} />
    } else if(enrollment.error) {
      return <Alert bsStyle="danger">{enrollment.error}</Alert>
    }

    return (
      <div>Loading...</div>
    )
  }
}