import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { push } from 'react-router-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ToggleSwitch from '@trendmicro/react-toggle-switch';
import ReactPaginate from 'react-paginate';

import Timezone from '../../../components/Timezone';
import { analystActions } from '../../../redux/modules/analist';


@connect(state => ({
  assignmentFolder: state.analyst.assignmentFolder
}), dispatch => bindActionCreators(analystActions, dispatch))
@autobind
export default class AssignmentFolders extends React.Component {
  constructor() {
    super();
    this.state = {
      showAssignmentModal: false
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    this.props.getAssignments(query.get('page') || 0);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      const query = new URLSearchParams(nextProps.location.search);
      this.props.getAssignments(query.get('page'));
    }
  }

  onChangeStatus(checked, assignment) {
    this.props.updateAssignment(assignment.courseId, assignment.assignmentId, {
      state: checked ? 'READY_FOR_GRADING' : 'CREATED'
    });
  }

  handlePageClick = ({ selected }) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?page=${ selected }`
    });
  };

  render() {
    const { loading, data } = this.props.assignmentFolder;
    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <div className="row">
              <div className="col-sm-7">
                <h1 className="panel-caption">
                  Assignment Folders
                </h1>
              </div>
            </div>
            <table className="cd-table table table-striped">
              <thead>
              <tr>
                <th>Assignment Folder Title</th>
                <th>Due on</th>
                <th>Author</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody id="coursesList">
                {data.map(d => (
                  <tr key={d.assignmentId}>
                    <td>
                      <i className="glyphicon glyphicon-folder-close"/>
                      {' '}
                      <Link to={`/analyst/courses/${d.courseId}/assignments/${d.assignmentId}/questions`}>{d.title}</Link>
                    </td>
                    <td>
                      <Timezone timestamp={d.dueDateAndTime} />
                    </td>
                    <td></td>
                    <td>
                      <ToggleSwitch
                        checked={d.state === 'READY_FOR_GRADING'}
                        onChange={(event) => {
                          this.onChangeStatus(!(d.state === 'READY_FOR_GRADING'), d);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate previousLabel={"previous"}
                           nextLabel={"next"}
                           breakLabel={<a href="">...</a>}
                           breakClassName={"break-me"}
                           pageCount={this.props.assignmentFolder.pages}
                           forcePage={this.props.assignmentFolder.page}
                           marginPagesDisplayed={2}
                           pageRangeDisplayed={5}
                           onPageChange={this.handlePageClick}
                           containerClassName={"pagination"}
                           subContainerClassName={"pages pagination"}
                           activeClassName={"active"} />
          </div>
        </div>
      </div>
    )
  }
}