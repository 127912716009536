import * as types from './types';
import Assignment from '../../../api/assignment';
import Question from '../../../api/question';
import Course from '../../../api/course';
import Auth from '../../../api/auth';

export const setEnrollmentId = (id) => ({
  type: types.SET_ENROLLMENT,
  payload: id
});

export const enrollForCourse = (id) => ({
  type: types.ENROLL,
  payload: {
    promise: Course.enroll(id)
  }
});

export const getAssignments = (courseId) => ({
  type: types.GET_ASSIGNMENTS,
  payload: {
    promise: Assignment.list(courseId)
  }
});

export const getQuestions = (courseId, assignmentId) => ({
  type: types.LOAD_QUESTIONS,
  payload: {
    promise: Question.list(courseId, assignmentId)
  }
});

