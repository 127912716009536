import React from "react";
import { autobind } from "core-decorators";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ToggleSwitch from "@trendmicro/react-toggle-switch";
import Course from "../../../api/course";
import TimeZone from "../../../components/Timezone";
import CourseModal from "../components/CourseModal";
import swal from "sweetalert";

@autobind
export default class Courses extends React.Component {
  state = {
    courses: [],
    showModal: false,
  };

  componentWillMount() {
    Course.list(0, 999).then((courses) =>
      this.setState({ courses: courses.content })
    );
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      selected: null,
    });
  };

  onCreateCourse = (data) => {
    if (this.state.selected) {
      return Course.update(this.state.selected.courseId, {
        title: data.title,
        startDate: data.startDateAndTime.format("YYYY-MM-DD HH:mm:s"),
        endDate: data.dueDateAndTime.format("YYYY-MM-DD HH:mm:s"),
      })
        .then((course) => {
          this.setState({
            courses: this.state.courses.map((i) =>
              i === this.state.selected ? course : i
            ),
          });
          this.closeModal();
        })
        .catch((e) => {
          const message = e.errors.map((e) => e.error).join("\r\n");
          swal("Error", message, "error");
        });
    } else {
      return Course.create({
        title: data.title,
        Hidden: false,
        department: "CSE",
        startDate: data.startDateAndTime.format("YYYY-MM-DD HH:mm:s"),
        endDate: data.dueDateAndTime.format("YYYY-MM-DD HH:mm:s"),
      })
        .then((course) => {
          this.setState({
            courses: [course, ...this.state.courses],
          });
          this.closeModal();
        })
        .catch((e) => {
          const message = e.errors.map((e) => e.error).join("\r\n");
          swal("Error", message, "error");
        });
    }
  };

  onStatusChange = (isChecked, course) => {
    Course.update(course.courseId, {
      hidden: isChecked,
    });
    this.setState({
      courses: this.state.courses.map((i) =>
        i === course ? { ...i, hidden: isChecked } : i
      ),
    });
  };

  onDelete = (course) => {
    if (confirm("Are you sure you want to delete this course?")) {
      Course.delete(course.courseId)
        .then(() =>
          this.setState({
            courses: this.state.courses.filter((c) => c !== course),
          })
        )
        .catch((e) => {
          const message = e.errors.map((e) => e.error).join("\r\n");
          swal("Error", message, "error");
        });
    }
  };

  render() {
    const { courses, showModal, selected } = this.state;
    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <div className="row">
              <div className="col-sm-7">
                <h1 className="panel-caption">My Courses</h1>
              </div>
              <div className="col-sm-5 assignment-info">
                <button
                  className="btn btn-primary"
                  onClick={() => this.setState({ showModal: true })}
                >
                  <i className="glyphicon glyphicon-plus" />
                  Add New Course
                </button>
              </div>
            </div>

            <table className="cd-table table table-striped">
              <thead>
                <tr>
                  <th>Course Title</th>
                  <th>Due on</th>
                  <th>Hidden</th>
                  <th></th>
                </tr>
              </thead>
              <tbody id="coursesList">
                {courses.map((d) => (
                  <tr key={d.assignmentId}>
                    <td>
                      <i className="glyphicon glyphicon-folder-open" />
                      {"  "}
                      <Link to={`/teacher/courses/${d.courseId}/assignments`}>
                        {d.title}
                      </Link>
                    </td>
                    <td>
                      <TimeZone timestamp={d.endDate} />
                    </td>
                    <td>
                      <ToggleSwitch
                        checked={d.hidden}
                        onChange={(event) => this.onStatusChange(!d.hidden, d)}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          this.setState({
                            showModal: true,
                            selected: d,
                          })
                        }
                      >
                        <i className="glyphicon glyphicon-pencil" />
                      </button>{" "}
                      <button
                        className="btn btn-danger"
                        onClick={() => this.onDelete(d)}
                      >
                        <i className="glyphicon glyphicon-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {showModal ? (
          <CourseModal
            course={selected}
            onSubmit={this.onCreateCourse}
            onClose={this.closeModal}
            mode={selected && "edit"}
          />
        ) : null}
      </div>
    );
  }
}
