import React from 'react';
import PropType from 'prop-types';

export default class Cell extends React.Component {
  static PropTypes = {
    onClick: PropType.func,
    onCellClick: PropType.func,
    range: PropType.string
  };

  onClick =(e) => {
    const { onClick, onCellClick, range} = this.props;
    onClick && onClick(e);
    onCellClick(range);
  };

  render() {
    return (
      <div onClick={this.onClick} className="clickable">
        {this.props.children}
      </div>
    )
  }
}