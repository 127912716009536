import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MyAssignmentsFolder from './containers/MyAssignmentsFolder';
import AssignmentQuestions from './containers/AssignmentQuestions';
import StudentAnswers from './containers/StudentAnswers';
import ManagePhraseBuckets from "../analyst/containers/ManagePhraseBuckets";
import Courses from './containers/Courses';

export default () =>
  <Switch>
    <Redirect exact path="/teacher" to="/teacher/courses" />
    <Route exact path="/teacher/courses" component={Courses} />
    <Route exact path="/teacher/courses/:id/assignments" component={MyAssignmentsFolder} />
    <Route exact path="/teacher/courses/:courseId/assignments/:assignmentId" component={AssignmentQuestions} />
    <Route exact path="/teacher/courses/:courseId/assignments/:assignmentId/questions/:questionId/answers" component={StudentAnswers} />
    <Route exact path="/teacher/courses/:courseId/assignments/:assignmentId/questions/:questionId/phrases" component={ManagePhraseBuckets} />
  </Switch>