import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Assignment from "../../../api/assignment";
import UpgradeModal from "../components/UpgradeModal";

class Courses extends React.Component {
  state = {
    loading: true,
    assignments: [],
    upgrade: false,
  };

  componentDidMount() {
    Assignment.list(this.props.match.params.id).then(
      (resp) => {
        this.setState({
          assignments: resp,
          loading: false,
        });
      },
      (err) => {
        swal("Oops", err.msg, "error");
      }
    );
  }

  onUpgrade = async () => {
    this.setState({ upgrade: true });
  };

  render() {
    const { courses } = this.props.user;
    const { assignments } = this.state;

    const course = courses.find(
      (c) => c.courseId === Number(this.props.match.params.id)
    );

    const trialDaysRemaining = 14 - moment().diff(course.enrollmentDate, "day");
    const isTrial = course.accessCodeType === "TRIAL";
    const isTrialing = trialDaysRemaining >= 0 && isTrial;
    const trialExpired = trialDaysRemaining < 0 && isTrial;

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <Link to="/student/courses">Back to Courses</Link>
            <h1 className="panel-caption">{course.title}</h1>
            {isTrialing && (
              <Alert showIcon bsStyle="danger">
                You are on Trial in {course.title} and your trial ends on January 24, 2023. Please{" "}
                <a href="#" onClick={this.onUpgrade}>
                  upgrade
                </a>
              </Alert>
            )}
            {trialExpired && (
              <Alert showIcon bsStyle="danger">
                Your Trial in {course.title} has ended, Please{" "}
                <a href="#" onClick={this.onUpgrade}>
                  upgrade
                </a>
              </Alert>
            )}
            <div>
              <table className="cd-table table table-striped">
                <thead>
                  <tr>
                    <th>Assignment Title</th>
                  {/*    <th>Due Date</th>*/}
                    <th>Score</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {assignments.map((c) => (
                    <tr key={c.assignmentId}>
                      <td>{c.title}</td>
                    {/*    <td>{moment(c.dueDateAndTime).format("LLL")}</td>*/}
                      <td>{c.marksScored}</td>
                    {/*  <td>*/}
                    {/*    <Link to={``}>Review</Link>*/}
                    {/*  </td>*/}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.state.upgrade && (
          <UpgradeModal
            courseId={this.props.match.params.id}
            onClose={() => this.setState({ upgrade: false })}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => state.session)(Courses);
