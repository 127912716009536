import React from "react";
import { Popover } from "antd";
import {
  Button,
  Form,
  FormControl,
  ControlLabel,
  FormGroup,
  Col,
} from "react-bootstrap";

export default class Comment extends React.Component {
  state = {
    marks: 0,
    comments: "",
    commentFor: "student",
    show: false,
  };

  componentWillMount() {
    const { comment } = this.props;
    if (comment) {
      this.setState({
        comments: comment.commenttext,
        marks: comment.marks,
      });
    }
  }

  handleClick = (e) => {
    this.setState({ show: !this.state.show });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { comment, answer, phrase } = this.props;
    const { comments, marks, commentFor } = this.state;
    if (!comments) return;
    this.props.onSave(
      marks,
      comments,
      answer,
      phrase,
      comment && comment.commentID,
      commentFor
    );
    this.setState({
      show: false,
      target: null,
    });
  };

  onDelete = () => {
    this.props.onDelete(this.props.comment.commentID);
    this.setState({
      show: false,
      target: null,
    });
  };

  renderContent() {
    const { commentFor } = this.state;
    const { comment, onDelete } = this.props;
    return (
      <Form horizontal onSubmit={this.onSubmit}>
        <div>
          <div>
            <label>Add comment:</label>
          </div>
          <label>
            <input
              type="radio"
              name="commentFor"
              value="student"
              onChange={this.onChange}
              checked={commentFor === "student"}
            />{" "}
            This student
          </label>
          &nbsp;
          <label>
            <input
              type="radio"
              name="commentFor"
              value="all"
              onChange={this.onChange}
              checked={commentFor === "all"}
            />{" "}
            All students
          </label>
        </div>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={2}>
            Marks:
          </Col>
          <Col sm={10}>
            <FormControl
              name="marks"
              type="number"
              placeholder="Marks"
              value={this.state.marks}
              onChange={this.onChange}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={2}>
            Comments:
          </Col>
          <Col sm={10}>
            <FormControl
              name="comments"
              componentClass="textarea"
              placeholder="Comments"
              value={this.state.comments}
              onChange={this.onChange}
            />
          </Col>
        </FormGroup>
        <div>
          <Button bsStyle="primary" type="submit">
            Save
          </Button>{" "}
          <Button
            bsStyle="default"
            type="button"
            onClick={() => this.setState({ show: false })}
          >
            Cancel
          </Button>{" "}
          {comment && (
            <Button bsStyle="danger" type="button" onClick={this.onDelete}>
              Delete
            </Button>
          )}
        </div>
      </Form>
    );
  }

  render() {
    const { comment, children } = this.props;
    let trigger = null;

    if (comment) {
      trigger = (
        <span>
          {children}{" "}
          <i
            className="glyphicon glyphicon-comment"
            onClick={this.handleClick}
          />
        </span>
      );
    } else {
      trigger = (
        <span className="clickable" onClick={this.handleClick}>
          {children}
        </span>
      );
    }

    return (
      <Popover
        content={this.renderContent()}
        trigger="click"
        placement="right"
        visible={this.state.show}
      >
        {trigger}
      </Popover>
    );
  }
}
