import * as types from './types';
import auth from '../../../api/auth';

export const storeSession = user => ({
  type: types.STORE_SESSION,
  payload: user
});

export const login = (email, password) => ({
  type: types.LOGIN,
  payload: {
    promise: auth.login(email, password)
  }
});

export const logout = () => (dispatch) => {
  dispatch({
    type: types.LOGOUT
  });
};

export const getCurrentuser = () => ({
  type: types.GET_CURRENT_USER,
  payload: {
    promise: auth.getCurrentUser()
  }
});