import React from 'react';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import { push } from 'react-router-redux';
import moment from 'moment';
import get from 'lodash/get';

import AnswerModal from '../components/AnswerModal';

import assignmnentApi from '../../../api/assignment';

@connect(state => state.student.assignmentQuestions)
@autobind
export default class Assignment extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedQuestion: null,
      answers: []
    }
  }

  componentDidMount() {
    this.getAnswers();
  }

  getAnswers() {
    const assignment = this.props.assignments[0];
    assignmnentApi.getStudentAnswers(assignment.courseId, assignment.assignmentId).then(
      resp => {
        const answers = get(resp, 'content[0].answers', []);
        this.setState({ answers });
      }
    )
  }

  onQuestionSelect(e, q) {
    e.preventDefault();
    this.setState({
      selectedQuestion: q
    });
  }

  async onSubmitAnswer(q, answer, file) {
    const assignment = this.props.assignments[0];
    try {
      let fileId = null;
      if (file) {
        const fileResp = await assignmnentApi.uploadFile(file);
        fileId = fileResp.fileId;
      }
      await assignmnentApi.submitAnswer(assignment.courseId, assignment.assignmentId, q.questionId, answer, fileId);
      swal("Good job!", "Assignment is successfully submitted", "success");

      this.getAnswers();
      return Promise.resolve();

    } catch (e) {
      return Promise.reject(e);
    }
  }

  onClose() {
    this.setState({
      selectedQuestion: null
    });
  }

  render() {
    const { selectedQuestion, answers } = this.state;
    const { data, assignments, loading } = this.props;
    const assignment = assignments[0];
    const answer = selectedQuestion && answers.find(a => a.questionId === selectedQuestion.questionId);

    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="panel clearfix">
            <div className="row">
              <div className="col-sm-7">
                <h1 className="panel-caption">
                  <i className="glyphicon glyphicon-file"/> {assignment && assignment.title}
                </h1>
              </div>
              <div className="col-sm-5 assignment-info">
                {/*<span className="badge badge-due">Due {assignment && moment(assignment.dueDateAndTime).format('MM/DD/YY | hh:mm a')}</span>*/}

              {/*  <p className="marks-obtained">*/}
              {/*    Marks obtained:*/}
              {/*    <span className="badge badge-marks">*/}
              {/*  — / {assignment && assignment.totalMarks}*/}
              {/*</span>*/}
              {/*  </p>*/}
              </div>
            </div>

            <p className="alert alert-info">Click on a question to view or edit answer</p>

            <table className="cd-table table table-striped">
              <thead>
              <tr>
                <th>Q#</th>
                <th>Maximum Marks</th>
              </tr>
              </thead>
              <tbody>
                {data.map(d => (
                  <tr key={d.questionId}>
                    <td className="wrap"><a href="#" onClick={(e) => this.onQuestionSelect(e, d)}>Q{d.questionSequence} - {d.questionText}</a></td>
                    <td><span className="badge badge-marks">—{d.maxScore}</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        { selectedQuestion ?
          <AnswerModal
            assignment={assignment}
            onClose={this.onClose}
            onSubmit={this.onSubmitAnswer}
            question={selectedQuestion}
            answer={answer}
          /> : null }
      </div>
    )
  }
}
