import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { autobind } from 'core-decorators';
import moment from 'moment-timezone';
import Timezone from '../../../components/Timezone';

@autobind
export default class CourseModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    course: PropTypes.object,
    onSubmit: PropTypes.func,
    mode: PropTypes.string
  };

  constructor(props) {
    super(props);
    const tzName = moment.tz.guess();
    const current = moment.tz(new Date(), tzName).utcOffset();
    this.state = {
      title: '',
      utcOffsetStart: current,
      utcOffsetEnd: current,
      dueDateAndTime: null,
      startDateAndTime: null
    };
  }

  componentWillMount() {
    const { course } = this.props;
    if (course) {
      this.setState({
        title: course.title,
        dueDateAndTime: moment(course.endDate),
        startDateAndTime: moment(course.startDate),
      });
    }
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleTmzChange = (zone, prop) => {
    const current = moment.tz(new Date(), zone).utcOffset();
    this.setState({
      [prop]: current
    });
  };

  onSubmit = () => {
    const { title, utcOffsetStart, utcOffsetEnd, dueDateAndTime, startDateAndTime} = this.state;
    const start = startDateAndTime && startDateAndTime.clone().utcOffset(utcOffsetStart);
    const end = dueDateAndTime && dueDateAndTime.clone().utcOffset(utcOffsetEnd);
    this.props.onSubmit({
      title,
      dueDateAndTime: end,
      startDateAndTime: start,
    });
  };

  render() {
    const { onClose, mode } = this.props;
    const { utcOffsetStart, utcOffsetEnd, dueDateAndTime, startDateAndTime} = this.state;
    const start = startDateAndTime && startDateAndTime.clone().utcOffset(utcOffsetStart);
    const end = dueDateAndTime && dueDateAndTime.clone().utcOffset(utcOffsetEnd);
    return (
      <Modal show onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{mode === 'edit' ? 'Edit Course' : 'Add New Course'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group col-xs-12">
            <label>Course Title</label>
            <input className="form-control" type="text" name="title" value={this.state.title} onChange={this.onChange} />
          </div>
          <div className="form-group col-xs-6">
            <div>
              <label>Start date</label>
            </div>
            <DatePicker
              selected={start}
              onChange={d => this.setState({startDateAndTime: d})}
              utcOffset={utcOffsetStart}
              timeFormat="hh:mm a"
              dateFormat="DD-MMM hh:mm a"
              timeIntervals={60}
              className="form-control"
              minDate={moment()}
              showTimeSelect
              todayButton="Today"
            />
            <Timezone hideDate onChange={(val) => this.handleTmzChange(val, 'utcOffsetStart')} />
          </div>
          <div className="form-group col-xs-6">
            <div>
              <label>Due date</label>
            </div>
            <DatePicker
              selected={end}
              onChange={d => this.setState({dueDateAndTime: d})}
              utcOffset={utcOffsetEnd}
              timeFormat="hh:mm a"
              dateFormat="DD-MMM hh:mm a"
              timeIntervals={60}
              className="form-control"
              minDate={moment()}
              showTimeSelect
              todayButton="Today"
            />
            <Timezone hideDate onChange={(val) => this.handleTmzChange(val, 'utcOffsetEnd')} />
          </div>
          <button className="btn btn-primary" onClick={this.onSubmit}>Save</button>
        </Modal.Body>
      </Modal>
    )
  }
}
